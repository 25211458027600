<template>
  <div class="new-resource-body">
    <button
      @click="newResourceModal"
      class="btn-close delete-cross-resources"
    />
    <div class="modal-header-resources">
      <h5 class="modal-title-resources">
        {{ langFilter("newResource") }}
      </h5>
    </div>
    <div class="modal-body modal-body-new-resource">
      <span class="name-new-resource">{{ langFilter("name") }}*</span>
      <textarea
        @input="updateResourceName($event)"
        :placeholder="langFilter('resourceName')"
        cols="30"
        rows="10"
        class="content-div-resources"
        :value="resourceName"
      />
      <span class="name-new-resource">{{ langFilter("resource") }}*</span>
      <input @change="uploadFile($event)" class="hide" ref="file" type="file" />
      <div v-if="link" class="relative">
        <textarea
          @input="updateResourceLink($event)"
          :placeholder="langFilter('resourceLink')"
          cols="30"
          rows="10"
          class="content-div-resources"
          :value="resourceLink"
        />
        <button @click="closeLink()" class="btn-close btn-close-link" />
      </div>
      <div
        v-else-if="file"
        class="content-div-resources content-div-resources-file"
      >
        {{ resourceFile.name }}
        <img
          v-if="check"
          :src="require('@/assets/img/general/green-check.svg')"
          alt="green check"
        />
        <button v-else @click="removeResource" class="btn-close" />
      </div>
      <div v-else class="content-div-resources">
        <div>
          <hover :content="langFilter('addURL')" :bs="true">
            <div @click="openLink()" class="resource-type-icon link-icon" />
          </hover>
        </div>
        <div>
          <hover :content="langFilter('addFile')" :bs="true">
            <div
              @click="$refs.file.click"
              class="resource-type-icon file-icon"
            />
          </hover>
        </div>
      </div>
    </div>
    <div class="modal-div-footer">
      <button
        @click="addResource"
        :class="[
          { 'modal-div-footer-btn-enabled': completed },
          'modal-div-footer-btn',
        ]"
      >
        {{ langFilter("save") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "editing"],
  data() {
    return {
      link: false,
      file: false,
      resourceName: "",
      resourceLink: "",
      resourceFile: "",
      completed: false,
      check: true,
      editFile: false,
      traducciones: [
        {
          name: "newResource",
          es: "Nuevo Recurso",
          en: "New Resource",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "name",
          es: "Nombre",
          en: "Name",
        },
        {
          name: "resource",
          es: "Recurso",
          en: "Resource",
        },
        {
          name: "resourceName",
          es: "Nombre del recurso",
          en: "Resource name",
        },
        {
          name: "resourceLink",
          es: "Link del recurso",
          en: "Resource link",
        },
        {
          name: "addURL",
          es: "Añadir URL",
          en: "Add URL",
        },
        {
          name: "addFile",
          es: "Añadir archivo",
          en: "Add file",
        },
      ],
    };
  },
  methods: {
    closeLink() {
      this.link = false;
      this.resourceLink = "";
      this.editFile = false;
      this.checkCompleted();
    },
    openLink() {
      this.link = true;
      this.editFile = false;
      this.checkCompleted();
    },
    getEditingData(data) {
      if (data !== null) {
        this.resourceName = data.name;
        if (data.link.includes("_m8y0_")) {
          this.file = true;
          this.check = false;
          const link = data.link.split("_m8y0_")[1];
          this.resourceFile = { name: link };
        } else {
          this.link = true;
          this.resourceLink = data.link;
        }
        this.completed = true;
      }
    },
    removeResource() {
      this.$refs.file.value = null;
      this.file = false;
      this.resourceFile = "";
      this.check = true;
      this.completed = false;
      this.editFile = true;
    },
    newResourceModal() {
      this.$emit("newResourceModal");
    },
    updateResourceName(event) {
      this.resourceName = event.target.value;
      this.checkCompleted();
    },
    updateResourceLink(event) {
      this.resourceLink = event.target.value;
      this.checkCompleted();
    },
    checkCompleted() {
      if (
        this.resourceName &&
        (this.resourceLink != "" || this.resourceFile != "")
      ) {
        this.completed = true;
      } else {
        this.completed = false;
      }
    },
    reset() {
      this.resourceName = "";
      this.resourceLink = "";
      this.resourceFile = "";
      this.completed = false;
      this.link = false;
      this.file = false;
      this.check = true;
    },
    editResource() {
      if (this.completed) {
        const formData = new FormData();
        formData.append("id", this.editing.resource_id);
        formData.append("name", this.resourceName);
        formData.append("newFile", this.editFile);
        if (this.file) {
          formData.append("file", this.resourceFile);
        } else {
          if (
            this.resourceLink.includes("http://") == false &&
            this.resourceLink.includes("https://") == false
          )
            this.resourceLink = "https://" + this.resourceLink;
          formData.append("link", this.resourceLink);
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/resources/updateResource`,
            formData
          )
          .then((res) => {
            if (res.data[1].includes("_m8y0_")) {
              this.$emit("addResource", {
                id: res.data[0],
                name: this.resourceName,
                link: process.env.VUE_APP_API_STORAGE + res.data[1],
              });
            } else {
              this.$emit("addResource", {
                id: res.data[0],
                name: this.resourceName,
                link: res.data[1],
              });
            }
            this.reset();
          });
      }
    },
    addResource() {
      if (this.editing !== null) {
        this.editResource();
        return;
      }
      const file = this.file ? true : false;
      if (this.completed) {
        const formData = new FormData();
        formData.append("name", this.resourceName);
        if (this.file) {
          formData.append("file", this.resourceFile);
        } else {
          if (
            this.resourceLink.includes("http://") == false &&
            this.resourceLink.includes("https://") == false
          )
            this.resourceLink = "https://" + this.resourceLink;
          formData.append("link", this.resourceLink);
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/resources/uploadResource`,
            formData
          )
          .then((res) => {
            if (file) {
              this.$emit("addResource", {
                id: res.data[0],
                name: this.resourceName,
                link: process.env.VUE_APP_API_STORAGE + res.data[1],
              });
            } else {
              this.$emit("addResource", {
                id: res.data[0],
                name: this.resourceName,
                link: res.data[1],
              });
            }
            this.reset();
          });
      }
    },
    uploadFile(event) {
      if (event.target.files[0].size > 10000000) {
        alert("El archivo es demasiado grande");
        return;
      }
      this.file = true;
      this.link = false;
      this.resourceFile = event.target.files[0];
      this.editFile = true;
      setTimeout(() => {
        this.check = false;
      }, 2000);
      this.checkCompleted();
    },
  },
  watch: {
    editing: function (val) {
      console.log("val", val);
      this.getEditingData(val);
    },
  },
  mixins: [translationMixin],
};
</script>
