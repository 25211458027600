<template>
  <div class="newProject-ProjectDataContainer">
    <span class="newProject-ProjectDataCategoryTitle">
      {{ currentStep || "0" }}. &nbsp;&nbsp;&nbsp;{{
        langFilter("choose-assistant")
      }}
    </span>
    <p class="ms-4 my-2" style="color: #301889">
      {{ langFilter("assistant-instructions") }}
    </p>

    <template v-if="!loading">
      <!-- Select para Expertos -->
      <div class="d-flex align-items-center my-5">
        <div
          class="position-relative tooltip-container"
          @mouseover="showTooltip('expert')"
          @mouseleave="hideTooltip"
        >
          <img
            :src="require('@/assets/img/general/info-alert.svg')"
            class="mb-1 col-1"
            alt="Info Icon"
            style="width: 16px; height: 16px"
          />
          <div v-if="tooltipVisible === 'expert'" class="custom-tooltip">
            {{ langFilter("expert-tooltip") }}
          </div>
        </div>
        <strong class="mx-2 col-2">{{ langFilter("expert") }}</strong>

        <select
          v-model="selectedExpert"
          @change="emitChange('expert')"
          class="form-select ps-2"
        >
          <option value="">
            {{ langFilter("select-assistant") }}
          </option>
          <option v-for="expert in experts" :key="expert.id" :value="expert.id">
            {{ expert.name }}
          </option>
        </select>
      </div>
      <p
        v-if="selectedExpert"
        class="ms-4 text-description"
        style="color: #301889"
      >
        <span class="fst-italic fw-bold"
          >{{ langFilter("assistant-description") }}:</span
        >
        {{ getDescription("expert", selectedExpert) }}
      </p>

      <!-- Select para Evaluadores -->
      <div class="d-flex align-items-center my-5">
        <div
          class="position-relative tooltip-container"
          @mouseover="showTooltip('evaluator')"
          @mouseleave="hideTooltip"
        >
          <img
            :src="require('@/assets/img/general/info-alert.svg')"
            class="mb-1 col-1"
            alt="Info Icon"
            style="width: 16px; height: 16px"
          />
          <div v-if="tooltipVisible === 'evaluator'" class="custom-tooltip">
            {{ langFilter("evaluator-tooltip") }}
          </div>
        </div>
        <strong class="mx-2 col-2">{{ langFilter("evaluator") }}</strong>

        <select
          v-model="selectedEvaluator"
          @change="emitChange('evaluator')"
          class="form-select ps-2 rounded-3"
        >
          <option value="">{{ langFilter("select-assistant") }}</option>
          <option
            v-for="evaluator in evaluators"
            :key="evaluator.id"
            :value="evaluator.id"
          >
            {{ evaluator.name }}
          </option>
        </select>
      </div>
      <p
        v-if="selectedEvaluator"
        class="ms-4 text-description"
        style="color: #301889"
      >
        <span class="fst-italic fw-bold"
          >{{ langFilter("assistant-description") }}:</span
        >
        {{ getDescription("evaluator", selectedEvaluator) }}
      </p>

      <div
        v-if="assistantTemplate"
        class="template-info mt-5 px-3 py-4 border rounded-2"
      >
        {{ langFilter("template-info") }}
      </div>
    </template>

    <img
      v-else
      class="loading-rocket"
      style="top: 20px"
      :src="require('@/assets/img/general/loading.gif')"
    />
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "@/mixins/translationMixin";
import translations from "./translate/NewProjectsV2Component.json";

export default {
  name: "NewProjectsAssistantComponent",
  props: [
    "templateExpert",
    "templateEvaluator",
    "assistantTemplate",
    "lang",
    "currentStep",
    "assistantsList",
  ],
  data() {
    return {
      loading: false,
      selectedExpert: "",
      selectedEvaluator: "",
      experts: [],
      evaluators: [],
      tooltipVisible: null,
      traducciones: translations,
    };
  },
  mounted() {
    this.fetchAssistants();
  },
  methods: {
    async fetchAssistants() {
      this.loading = true;
      try {
        let data = this.assistantsList;

        if (!data) {
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/assistants`
          );
          const { data: dataReponse } = response;
          data = dataReponse;
        }

        this.experts = data.filter(
          (assistant) => assistant.assistant_type.id === 1
        );
        this.evaluators = data.filter(
          (assistant) => assistant.assistant_type.id === 2
        );

        if (this.templateExpert) this.selectedExpert = this.templateExpert;
        if (this.templateEvaluator)
          this.selectedEvaluator = this.templateEvaluator;
      } catch (error) {
        console.error("Error fetching assistants:", error);
      } finally {
        this.loading = false;
      }
    },
    emitChange(type) {
      if (type === "expert") {
        this.$emit("update-expert", this.selectedExpert);
      } else if (type === "evaluator") {
        this.$emit("update-evaluator", this.selectedEvaluator);
      }
    },
    getDescription(type, id) {
      const list = type === "expert" ? this.experts : this.evaluators;
      const assistant = list.find((a) => a.id === id);
      return assistant ? assistant.description : "";
    },
    showTooltip(type) {
      this.tooltipVisible = type;
    },
    hideTooltip() {
      this.tooltipVisible = null;
    },
  },
  watch: {
    templateExpert() {
      this.selectedExpert = this.templateExpert;
    },
    templateEvaluator() {
      this.selectedEvaluator = this.templateEvaluator;
    },
  },
  mixins: [translationMixin],
};
</script>

<style>
.tooltip-container {
  display: inline-block;
}

.custom-tooltip {
  width: 300px;
  position: absolute;
  background-color: #fff;
  text-align: justify;
  padding: 10px;
  bottom: 125%;
  transform: translateX(-50%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  z-index: 999;
}

.template-info {
  background-color: #e5e6fa;
  color: #6c7792;
}

.text-description {
  max-height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
}
</style>
