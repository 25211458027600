<script setup>
import ManageSpace from "../components/space/ManageSpaceComponent.vue";
</script>

<template>
  <ManageSpace />
</template>

<script>
export default {
  created() {
    if (process.env.VUE_APP_API_MANAGESPACE_TOKEN != this.$route.params.token) {
      this.$router.replace({ name: "Login" });
    }
  },
};
</script>
