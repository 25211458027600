<template>
  <div
    class="apart-background-itinerary no-selection-itinerary"
    :id="id"
    @mouseover="passmouseapart()"
    @mouseout="outmouseapart()"
  >
    <div class="itinerary-eachRow row-itinerary">
      <div
        v-if="notification"
        :id="'notification' + id"
        class="notification-apart-itinerary"
      ></div>
      <div
        class="first-apart-row-itinerary"
        id="primer_row"
        @click="openQuestion = !openQuestion"
      >
        <router-link
          :to="'/parts/' + project_id + '/' + station_id + '/' + solution_id"
          onclick="event.preventDefault()"
          ><span
            id="click_solution"
            @click="goToSolution()"
            style="color: #2a3b5a; cursor: pointer"
            >{{ name }}</span
          ></router-link
        >
        <img
          :id="'imagen_flecha2' + id"
          style="padding-left: 12px"
          :src="openQuestion ? '/img/itinerario/arrow_down.svg' : '/img/itinerario/arrow_right.svg'"
        />
      </div>
      <div class="apart-row-itinerary text-enseña-datos-tabla-itinerary">
        <p
          class=""
          @click="getResources(solution_id)"
          data-bs-toggle="modal"
          data-bs-target="#recursosModal"
        >
          {{ resources }} {{ langFilter("recursos") }}
        </p>
      </div>
      <div class="apart-row-itinerary text-enseña-datos-tabla-itinerary">
        <div
          class="itinerary-advanced-by"
          :style="
            is_completed
              ? 'background-color: #13C99E'
              : 'background-color: #B4BAC5'
          "
        ></div>
      </div>
    </div>
	<div
	v-if="openQuestion"
	style="margin-left: 10%">
    <itinerary-questiontable
      v-for="(question, questionIndex) in question_id"
      :key="questionIndex"
      :is_completed="question_id[questionIndex].answer"
      :question_id="question_id[questionIndex].question_id"
      :class="'question-class-' + id"
      :id="'question_table_' + questionIndex + '_' + solution_id"
      :name="question_id[questionIndex].name"
      :time="question_id[questionIndex].time"
      :question_type="question_id[questionIndex].question_type"
      :notification="
        user_role == 'Emprendedor'
          ? question_id[questionIndex].tutor === 1 ||
            question_id[questionIndex].notTutor === 1
            ? true
            : false
          : question_id[questionIndex].entrepreneur === 1 ||
            question_id[questionIndex].notEntrepreneur === 1
          ? true
          : false
      "
      :solution_id="solution_id"
      :project_id="project_id"
      :user_role="user_role"
      :lang="lang"
    ></itinerary-questiontable>
</div>
    <div
      id="no_question"
      v-if="is_visible === 1 && question_id[0].question_id === 0"
    >
      No hay preguntas para esta solución
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  props: [
    "solution_id",
    "id",
    "resources",
    "name",
    "is_completed",
    "filter",
    "project_id",
    "station_id",
    "user_role",
    "lang",
    "changeView",
  ],
  name: "ItineraryApartTableComponent",
  data() {
    return {
      web: "",
      question_id: [],
      is_optional: this.optional,
      count_filterSolutions: "",
      notification_tutor: 0,
      notification_entrepreneur: 0,
      is_visible: "",
      notQuestions: [],
	notification: false,
	openQuestion: false,
      traducciones: [
        {
          name: "recursos",
          es: "recursos",
          en: "resources",
        },
        {
          name: "apartados",
          es: "Apartados",
          en: "Parts",
        },
        {
          name: "pendiente",
          es: "Pendiente",
          en: "Pending",
        },
        {
          name: "revision",
          es: "Pendiente Revisión",
          en: "Pending Review",
        },
        {
          name: "modificacion",
          es: "Pendiente Modificación",
          en: "Pending Modification",
        },
        {
          name: "completado",
          es: "Completado",
          en: "Complete",
        },
        {
          name: "si",
          es: "Sí",
          en: "Yes",
        },
      ],
    };
  },
  created() {
	this.onCreated_Solution();
  },
  methods: {
    getResources(solution) {
      this.$parent.getResources(solution);
    },
    passmouseapart() {
      $("#" + this.id).addClass("apart-border-1px");
    },
    outmouseapart() {
      $("#" + this.id).removeClass("apart-border-1px");
    },
    openSolutions(el) {
      if (el.target.id !== "click_solution") {
        if (this.is_visible == 1) {
          $("#imagen_flecha2" + this.id).attr(
            "src",
            "/img/itinerario/arrow_right.svg"
          );
          $(".question-class-" + this.id).hide();
          this.is_visible = 0;
        } else {
          axios
            .post(`${process.env.VUE_APP_API_URL}/itinerary/getQuestion`, {
              project_id: this.project_id,
              solution_id: this.solution_id,
            })
            .then((response) => {
              if (response.data.length > 0) {
                this.question_id = response.data;
                this.$nextTick(() => {
                  $(".question-class-" + this.id).show();
                  $("#imagen_flecha2" + this.id).attr(
                    "src",
                    "/img/itinerario/arrow_down.svg"
                  );
                });
              } else {
                let object = {
                  question_id: 0,
                  name: "No hay preguntas",
                  time: 0,
                  question_type: 0,
                  tutor: 0,
                  entrepreneur: 0,
                  notTutor: 0,
                  notEntrepreneur: 0,
                };
                this.question_id.push(object);
                this.$nextTick(() => {
                  $(".question-class-" + this.id).show();
                  $("#imagen_flecha2" + this.id).attr(
                    "src",
                    "/img/itinerario/arrow_down.svg"
                  );
                });
                this.$nextTick(() => {
                  this.is_visible = 1;
                });
              }
            });
        }
      }
    },
    clickSiNo() {
      if (this.lang == "es") {
        if (this.is_optional == true) {
          $("#si_no" + this.id).attr("src", "/img/itinerario/si-no.svg");
          this.is_optional = false;
        } else {
          $("#si_no" + this.id).attr("src", "/img/itinerario/no-si.svg");
          this.is_optional = true;
        }
      } else {
        if (this.is_optional == true) {
          $("#si_no" + this.id).attr("src", "/img/itinerario/yes-no.svg");
          this.is_optional = false;
        } else {
          $("#si_no" + this.id).attr("src", "/img/itinerario/no-yes.svg");
          this.is_optional = true;
        }
      }
      axios.post("/itinerary/setOptional", {
        project_id: this.project_id,
        station_id: this.station_id,
        solution_id: this.solution_id,
        optional: this.is_optional,
      });
    },
    checkState() {
      switch (this.state) {
        case 1:
          return this.langFilter("pendiente");
        case 2:
          return this.langFilter("revision");
        case 3:
          return this.langFilter("modificacion");
        case 4:
          return this.langFilter("completado");
      }
    },
    filterSolutions(filter) {
      if (this.state == filter || filter == 0) {
        $("#" + this.id).attr(
          "src",
          "margin-right: 43px; margin-left: 10%; padding-top: 8px; padding-bottom: 2px; margin-bottom: 15px; cursor: pointer; display: flex; flex-direction: column"
        );
      } else {
        $("#" + this.id).attr("src", "");
      }
    },
    checkOptional() {
      if (this.lang == "es") {
        if (this.optional == true) {
          return "/img/itinerario/no-si.svg";
        } else {
          return "/img/itinerario/si-no.svg";
        }
      } else {
        if (this.optional == true) {
          return "/img/itinerario/no-yes.svg";
        } else {
          return "/img/itinerario/yes-no.svg";
        }
      }
    },
    showOptionalEntrepreneur() {
      if (this.optional == true) {
        return this.langFilter("si");
      } else {
        return "No";
      }
    },
    goToSolution() {
      this.changeView(105, this.project_id, this.station_id, this.solution_id);
    },
    onCreated_Solution() {
		axios.post(`${process.env.VUE_APP_API_URL}/itinerary/setNotificationSolution`,
			{
				project_id: this.project_id,
				solution_id: this.solution_id,
			}).then(response => {
				let not = '';
				if(this.user_role === 'Emprendedor')
					not = response.data.filter((element) => element.interaction_tutor == 1 || element.ready_correct_tutor == 1);
				else
					not = response.data.filter((element) => element.interaction_entrepreneur == 1 || element.ready_correct_entrepreneur == 1);
				
				if(not.length > 0){
					this.notification = true;
				}
			});
      $("itineraryquestiontable").next().hide();
      this.web = window.location.pathname;

      this.web = this.web.split("/");
      this.is_visible = 0;
      $("#imagen_flecha2" + this.id).attr(
        "src",
        "/img/itinerario/arrow_right.svg"
      );
      $(".question-class-" + this.id).hide();
      if (this.state == this.filter || this.filter == 0) {
        $("#" + this.id).addClass("hide-show-Solution");
        this.count_filterSolutions = 1;
      } else {
        $("#" + this.id).removeClass("hide-show-Solution");
        this.count_filterSolutions = 0;
      }
	if(this.question_id.length === 0){
	axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/getQuestion`, {
          project_id: this.project_id,
          solution_id: this.solution_id,
        })
		.then((response) => {
        if (response.data.length > 0) {
        this.question_id = response.data;
        } else {
    let object = {	
    question_id: 0,
    name: "No hay preguntas",
    time: 0,
    question_type: 0,
    tutor: 0,
    entrepreneur: 0,
    notTutor: 0,
    notEntrepreneur: 0,
    };
    this.question_id.push(object);
		}
		});
	}
	},
  },
  mixins: [translationMixin],
};
</script>

<style scoped></style>
