<template>
  <div @mouseenter="hoverIn()" @mouseleave="hoverOut()">
    <slot />
  </div>
  <div
    v-if="hover"
    class="hoverGeneral-box"
    :class="(solution && 'hoverSolutions') || (bs && 'hoverGeneral2-box')"
  >
    <span>{{ content }}</span>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import "../../assets/css/hover.css";
export default defineComponent({
  name: "HoverGeneral",
  props: ["content", "bs", "solution"],
  data() {
    return {
      hover: false,
    };
  },
  created() {},
  methods: {
    hoverIn() {
      this.hover = true;
    },
    hoverOut() {
      this.hover = false;
    },
  },
  watch: {},
});
</script>
