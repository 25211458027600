<template>
  <div class="relative">
    <div class="home-title-div pointer">
      <router-link to="/my-projects">
        <img
          :src="require('@/assets/img/home/projects.svg')"
          alt="project"
          class="img-project-home"
        />
        <span class="title-home-banner">{{ langFilter("projects") }}</span>
      </router-link>
    </div>
    <div v-if="loading" class="align-center">
      <img class="home-load" :src="require('@/assets/img/general/carga.gif')" />
    </div>
    <div v-show="!loading">
      <div
        v-for="(project, projectIndex) in projects"
        :key="projectIndex"
        class="home-body-div"
      >
        <router-link :to="`/itinerary/${project.project_id}`">
          <div class="project-title-home">
            <span>{{ project.name }}</span>
          </div>
        </router-link>
        <div class="percentage-home-div">
          <span class="percentage-home-number">
            {{ checkPercentage(project.percentage) }}%
          </span>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="
                `width: ${project.percentage}%;` +
                (project.percentage >= 100
                  ? 'background-color:#13C99E !important;'
                  : '')
              "
              :aria-valuenow="project.percentage"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="projects.length == 0" class="no-not-home">
        <span>{{ langFilter("no-projects") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: ["lang"],
  data() {
    return {
      projects: [],
      myStations: [],
      loading: true,
      traducciones: [
        {
          name: "projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "no-projects",
          es: "No tienes proyectos",
          en: "You don't have projects",
        },
      ],
    };
  },
  mounted() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/home/getProjects`)
      .then((response) => {
        this.projects = response.data;
        this.loading = false;
      });
  },
  methods: {
    checkPercentage(percentage) {
      if (percentage >= 100) return 100;
      return percentage;
    },
  },
  mixins: [translationMixin],
};
</script>
