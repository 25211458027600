<template>
  <div class="cdti-show-container">
    <button @click="closeModal" class="cdti-close-x" />
    <div class="cdti-header">
      <h4>{{ langFilter("result") }}</h4>
    </div>
    <div class="cdti-scroll">
      <p>
        <span class="cdti-title">
          {{ langFilter("invertiblity") }}:
          <span class="semi-bold">{{ generalIndex }}</span
          >. </span
        ><br /><br />
        <span class="cdti-probability"> {{ langFilter("probability") }} </span>
        <br /><br />
        <span class="cdti-probability"> {{ langFilter("discover") }} </span>
      </p>
      <div
        v-for="(rec, recomendationIndex) in recomendations"
        :key="recomendationIndex"
        class="relative"
      >
        <button
          @click="openCloseRecomendations(recomendationIndex)"
          :class="
            recomendationsClosed.includes(recomendationIndex)
              ? 'cdti-arrow-right'
              : 'cdti-arrow-down'
          "
        />
        <p class="recomendations-p">
          <span class="cdti-quest-num">{{ rec.question_num }}</span>
          <span v-if="recomendationsClosed.includes(recomendationIndex)">
            <br />
            <span class="">{{ langFilter("changeRes") }}</span>
            <span>{{ rec.question }}</span>
            {{ langFilter("to") }}
            <span>{{ rec.answer }}.</span>
            {{ langFilter("increase") }}
            <span class="semi-bold">{{ rec.invertibility }}</span>
            <br />
          </span>
        </p>
      </div>
      <p>{{ langFilter("otherWays") }}</p>
      <a
        :href="`https://api.acceleralia.com/resources/cdti/cdti_${lang}.pdf`"
        target="_blank"
        class="cdti-modal-container-a"
      >
        <span class="cdti-modal-container-btn">
          {{ langFilter("pdf") }}
        </span>
        <img
          @click="sortUsers('name')"
          class="arrow-down-ap-cdti"
          :src="require('@/assets/img/cdti/arrow-down.svg')"
        />
      </a>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: ["lang", "project_id", "data", "answers"],
  data() {
    return {
      recomendations: [],
      generalIndex: 0,
      recomendationsClosed: [],
      traducciones: [
        {
          name: "pdf",
          es: "Descargar PDF",
          en: "Download PDF",
        },
        {
          name: "result",
          es: "RESULTADO",
          en: "RESULT",
        },
        {
          name: "otherWays",
          es: "Si estás buscando formas de mejorar el índice de invertibilidad de tu negocio y atraer a más inversores, tenemos algunos consejos útiles para compartir contigo. Descarga la guía en PDF  para obtener detalles sobre cómo aplicar estos consejos a tu negocio y aumentar su atractivo para los inversores.",
          en: "If you are looking for ways to improve your business's invertibility index and attract more investors, we have some helpful tips to share with you. Download the PDF guide for details on how to apply these tips to your business and increase its appeal to investors.",
        },
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
        {
          name: "invertiblity",
          es: "El índice de invertibilidad obtenido es ",
          en: "The invertibility index obtained is ",
        },
        {
          name: "probability",
          es: "Recuerda que este valor se encuentra en una escala de 0 a 10, donde 10 indica una mayor confiabilidad percibida por los inversores, aumentando así las posibilidades de inversión en la empresa.",
          en: "Remember that this value is on a scale of 0 to 10, where 10 indicates a higher reliability perceived by investors, thus increasing the chances of investment in the company.",
        },
        {
          name: "discover",
          es: "Descubre cómo mejorar tu índice de invertibilidad con nuestras opciones recomendadas por IA.",
          en: "Discover how to improve your invertibility index with our AI recommended options.",
        },
        {
          name: "changeResp",
          es: "Para aumentar el índice de inversión, una opción sería cambiar su respuesta en la",
          en: "To increase the invertibility index, an option would be to change your response on",
        },
        {
          name: "to",
          es: "a",
          en: "to",
        },
        {
          name: "increase",
          es: "De esta manera, podrías aumentar tu índice de invertibilidad a",
          en: "This way, you could increase your invertibility index to",
        },
        {
          name: "Q1Title",
          es: "Algunas recomendaciones sobre la pregunta 2 en relación al nivel de ventas:",
          en: "Some recommendations on Question 2 regarding Sales Level:",
        },
        {
          name: "Q1Desc",
          es: "Enfoque en la retención de clientes: En general, es más rentable retener a los clientes existentes que adquirir nuevos. Por lo tanto, enfoque en crear programas de fidelización de clientes, excelente servicio al cliente y experiencias personalizadas que mantengan a los clientes regresando. Mejore la eficiencia operativa: Encontrar formas de optimizar los procesos comerciales, reducir el desperdicio y aumentar la productividad puede ayudar a reducir costos y aumentar las ganancias. Busque formas de automatizar tareas rutinarias, externalizar actividades no esenciales y optimizar su cadena de suministro. Aumente los precios: Aumentar los precios puede parecer contraproducente, pero si se hace estratégicamente, puede aumentar las ganancias. Considere implementar una estrategia de precios dinámicos que ajuste los precios según la demanda o una estrategia de precios basada en el valor que refleje el valor percibido de sus productos o servicios. Amplíe la oferta de productos: Ofrecer productos o servicios complementarios puede ayudar a aumentar las ventas y los ingresos. Esto podría implicar la agrupación de productos, la venta cruzada o la venta adicional, o la introducción de nuevos productos que atraigan a su base de clientes existente. Invierta en marketing: El marketing es esencial para impulsar las ventas y crear conciencia de marca. Busque oportunidades para invertir en publicidad dirigida, marketing de contenido y campañas en redes sociales para llegar a nuevos clientes y retener a los existentes. Desarrolle asociaciones estratégicas: Asociarse con otras empresas en industrias complementarias puede ayudar a expandir su alcance y aumentar las ventas. Busque empresas que compartan sus valores y tengan un público objetivo similar, y explore oportunidades para colaborar en campañas de marketing conjuntas o ofertas de productos.",
          en: "Focus on customer retention: It is generally more cost-effective to retain existing customers than to acquire new ones. Therefore, focus on creating customer loyalty programs, excellent customer service, and personalized experiences that keep customers coming back. Improve operational efficiency: Finding ways to streamline your business processes, reduce waste, and increase productivity can help to lower costs and increase profits. Look for ways to automate routine tasks, outsource non-core activities, and optimize your supply chain. Increase pricing: Raising prices may seem counterintuitive, but if done strategically, it can actually increase profits. Consider implementing a dynamic pricing strategy that adjusts prices based on demand or value-based pricing that reflects the perceived value of your products or services. Expand product offerings: Offering complementary products or services can help to increase sales and revenue. This could involve bundling products together, cross-selling or upselling, or introducing new products that appeal to your existing customer base. Invest in marketing: Marketing is essential to drive sales and create brand awareness. Look for opportunities to invest in targeted advertising, content marketing, and social media campaigns to reach new customers and retain existing ones. Develop strategic partnerships: Partnering with other companies in complementary industries can help to expand your reach and increase sales. Look for businesses that share your values and have a similar target audience, and explore opportunities to collaborate on joint marketing campaigns or product offerings.",
        },
        {
          name: "Q2Title",
          es: "Algunas recomendaciones sobre la pregunta 3 en relación a la preparación tecnológica:",
          en: "Some recommendations on Question 3 regarding Technology Readiness:",
        },
        {
          name: "Q2Desc",
          es: "Desarrolle un plan de tecnología: Un plan de tecnología puede ayudar a las empresas a identificar hitos y objetivos clave para el desarrollo de la tecnología. Esto puede ayudar a priorizar las inversiones y garantizar que la tecnología se desarrolle de manera sistemática y eficiente. Invierta en investigación y desarrollo: Invertir en investigación y desarrollo puede ayudar a las empresas a mantenerse a la vanguardia y desarrollar tecnología de vanguardia. Esto puede incluir la construcción de un equipo de desarrolladores internos o la asociación con organizaciones externas de investigación.Fomente una cultura de innovación: Crear una cultura de innovación puede ayudar a las empresas a fomentar y apoyar el desarrollo de la tecnología. Esto puede implicar proporcionar a los empleados el tiempo y los recursos para experimentar y explorar nuevas ideas, así como reconocer y recompensar la innovación.Colabore con socios de la industria: Colaborar con socios de la industria puede ayudar a las empresas a mantenerse actualizadas sobre las últimas tendencias y mejores prácticas tecnológicas. Esto puede incluir asociarse con proveedores, proveedores o otras empresas en la industria para compartir conocimientos y recursos.Mejore continuamente los procesos: Mejorar continuamente los procesos puede ayudar a las empresas a optimizar el desarrollo de la tecnología y mejorar la eficiencia. Esto puede implicar evaluar regularmente los procesos y procedimientos para identificar oportunidades de mejora e implementar cambios según sea necesario.Aproveche la tecnología de código abierto: Aprovechar la tecnología de código abierto puede ayudar a las empresas a reducir los costos de desarrollo y acelerar el tiempo de comercialización. La tecnología de código abierto a menudo está disponible de forma gratuita y se puede personalizar para satisfacer las necesidades específicas de la empresa.",
          en: "Develop a technology roadmap: A technology roadmap can help businesses identify key milestones and goals for technology development. This can help prioritize investments and ensure that the technology is developed in a systematic and efficient way. Invest in research and development: Investing in research and development can help businesses stay ahead of the curve and develop cutting-edge technology. This can include building a team of in-house developers or partnering with outside research organizations. Foster a culture of innovation: Creating a culture of innovation can help businesses encourage and support technology development. This can involve providing employees with the time and resources to experiment and explore new ideas, as well as recognizing and rewarding innovation. Collaborate with industry partners: Collaborating with industry partners can help businesses stay up-to-date on the latest technology trends and best practices. This can include partnering with vendors, suppliers, or other businesses in the industry to share knowledge and resources. Continuously improve processes: Continuously improving processes can help businesses streamline technology development and improve efficiency. This can involve regularly evaluating processes and procedures to identify opportunities for improvement, and implementing changes as needed. Leverage open-source technology: Leveraging open-source technology can help businesses reduce development costs and accelerate time-to-market. Open-source technology is often freely available and can be customized to meet the specific needs of the business.",
        },
        {
          name: "Which of the following sectors are your company working on?",
          es: "¿En qué sector está trabajando su empresa?",
          en: "Which of the following sectors are your company working on?",
        },
        {
          name: "Manufacturing",
          es: "Manufactura",
          en: "Manufacturing",
        },
        {
          name: "Service",
          es: "Servicio",
          en: "Service",
        },
        {
          name: "Digitalization & IOT",
          es: "Digitalización e IOT",
          en: "Digitalization & IOT",
        },
        {
          name: "Construction",
          es: "Construcción",
          en: "Construction",
        },
        {
          name: "Pharma",
          es: "Farmacéutica",
          en: "Pharma",
        },
        {
          name: "Education",
          es: "Educación",
          en: "Education",
        },
        {
          name: "Hospitality",
          es: "Hospitalidad",
          en: "Hospitality",
        },
        {
          name: "Agriculture Forestry And Fishing",
          es: "Agricultura, silvicultura y pesca",
          en: "Agriculture Forestry And Fishing",
        },
        {
          name: "Electricity, Gas, Steam And Air Conditioning Suppl",
          es: "Suministro de electricidad, gas, vapor y aire acondicionado",
          en: "Electricity, Gas, Steam And Air Conditioning Suppl",
        },
        {
          name: "Transportation And Storage",
          es: "Transporte y almacenamiento",
          en: "Transportation And Storage",
        },
        {
          name: "Information And Communication",
          es: "Información y comunicación",
          en: "Information And Communication",
        },
        {
          name: "Financial And Insurance Activities",
          es: "Actividades financieras y de seguros",
          en: "Financial And Insurance Activities",
        },
        {
          name: "Public Administration And Defense, Compulsary Social Security",
          es: "Administración pública y defensa, seguridad social obligatoria",
          en: "Public Administration And Defense, Compulsary Social Security",
        },
        {
          name: "Human Health And Social Work Securities",
          es: "Actividades de salud humana y servicios sociales",
          en: "Human Health And Social Work Securities",
        },
        {
          name: "Other Activities",
          es: "Otras actividades",
          en: "Other Activities",
        },
        {
          name: "Does your benefit maintain with any levels of sales?",
          es: "¿Su beneficio se mantiene con cualquier nivel de ventas?",
          en: "Does your benefit maintain with any levels of sales?",
        },
        {
          name: "Yes, I have the same benefit with any amount of sales",
          es: "No, mi beneficio disminuye con más ventas que tenemos",
          en: "Yes, I have the same benefit with any amount of sales",
        },
        {
          name: "No, my benefit reduces with more sales we have",
          es: "No, mi beneficio aumenta con más ventas que tenemos",
          en: "No, my benefit reduces with more sales we have",
        },
        {
          name: "No, my benefit increases with more sales we have",
          es: "No he empezado a vender",
          en: "No, my benefit increases with more sales we have",
        },
        {
          name: "I have not started to sell",
          es: "No estoy seguro",
          en: "I have not started to sell",
        },
        {
          name: "",
          es: "Sí, tengo el mismo beneficio con cualquier cantidad de ventas",
          en: "",
        },
        {
          name: "I am unsure",
          es: "No estoy seguro",
          en: "I am unsure",
        },
        {
          name: "Technology Readiness Level. Which of the following best describes the current:",
          es: "Nivel de preparación tecnológica. ¿Cuál de los siguientes describe mejor la situación actual?",
          en: "Technology Readiness Level. Which of the following best describes the current:",
        },
        {
          name: "TRL 1 – Basic Principles Observed and Reported",
          es: "TRL 1 - Principios básicos observados y reportados",
          en: "TRL 1 – Basic Principles Observed and Reported",
        },
        {
          name: "TRL 2 – Potential Application Validated",
          es: "TRL 2 - Aplicación potencial validada",
          en: "TRL 2 – Potential Application Validated",
        },
        {
          name: "TRL 3. Experimental proof of concept",
          es: "TRL 3 - Prueba de concepto experimental",
          en: "TRL 3. Experimental proof of concept",
        },
        {
          name: "TRL 4. Component and/or breadboard validation in laboratory environment",
          es: "TRL 4 - Validación de componentes y/o prototipo en un ambiente de laboratorio",
          en: "TRL 4. Component and/or breadboard validation in laboratory environment",
        },
        {
          name: "TRL 5 – Component and/or breadboard validation in relevant environment",
          es: "TRL 5 - Validación de componentes y/o prototipo en un ambiente relevante",
          en: "TRL 5 – Component and/or breadboard validation in relevant environment",
        },
        {
          name: "TRL 6 – System/subsystem model or prototype demonstration in a relevant environment",
          es: "TRL 6 - Demostración de modelo o prototipo de sistema/subsistema en un ambiente relevante",
          en: "TRL 6 – System/subsystem model or prototype demonstration in a relevant environment",
        },
        {
          name: "TRL 7 – System prototype demonstration in an operational environment",
          es: "TRL 7 - Demostración de prototipo de sistema en un ambiente operacional",
          en: "TRL 7 – System prototype demonstration in an operational environment",
        },
        {
          name: "TRL 8 – Actual system completed and qualified through test and demonstration",
          es: "TRL 8 - Sistema real completado y calificado a través de pruebas y demostración",
          en: "TRL 8 – Actual system completed and qualified through test and demonstration",
        },
        {
          name: "TRL 9 – Actual system proven through successful mission operations",
          es: "TRL 9 - Sistema real demostrado a través de operaciones de misión exitosas",
          en: "TRL 9 – Actual system proven through successful mission operations",
        },
        {
          name: "It does not apply to my bussiness",
          es: "No aplica a mi negocio",
          en: "It does not apply to my bussiness",
        },
        {
          name: "How many leads you contact convert into a paying client?",
          es: "¿Cuántos clientes potenciales que contacta convierte en clientes que pagan?",
          en: "How many leads you contact convert into a paying client?",
        },
        {
          name: "Less than 25%",
          es: "Menos del 25%",
          en: "Less than 25%",
        },
        {
          name: "Between 26 - 50%",
          es: "Entre el 26 - 50%",
          en: "Between 26 - 50%",
        },
        {
          name: "Between 51-75%",
          es: "Entre el 51-75%",
          en: "Between 51-75%",
        },
        {
          name: "More than 76%",
          es: "Más del 76%",
          en: "More than 76%",
        },
        {
          name: "I am unsure of the conversion rate",
          es: "No estoy seguro de la tasa de conversión",
          en: "I am unsure of the conversion rate",
        },
        {
          name: "How much have you raised on the first five years of your company/startup (founders funds, FFF Funds, Angel Rounds and Venture Capital)?",
          es: "¿Cuánto ha recaudado en los primeros cinco años de su empresa/startup (fondos de los fundadores, rondas de amigos, familiares y ángeles y capital de riesgo)?",
          en: "How much have you raised on the first five years of your company/startup (founders funds, FFF Funds, Angel Rounds and Venture Capital)?",
        },
        {
          name: "Less than 250.000€",
          es: "Menos de 250.000€",
          en: "Less than 250.000€",
        },
        {
          name: "More than 5.000.000€",
          es: "Más de 5.000.000€",
          en: "More than 5.000.000€",
        },
        {
          name: "How would you best describe your business experience?",
          es: "¿Cómo describiría mejor su experiencia empresarial?",
          en: "How would you best describe your business experience?",
        },
        {
          name: "Experience as a C-Level executive in any sector",
          es: "Experiencia como ejecutivo de alto nivel en cualquier sector",
          en: "Experience as a C-Level executive in any sector",
        },
        {
          name: "Experience in your industry sector",
          es: "Experiencia en su sector industrial",
          en: "Experience in your industry sector",
        },
        {
          name: "Experience in Sales or Technology",
          es: "Experiencia en ventas o tecnología",
          en: "Experience in Sales or Technology",
        },
        {
          name: "No Experience",
          es: "No tengo experiencia",
          en: "No Experience",
        },
        {
          name: "Dedication to the project (Choose only one):",
          es: "Dedicación al proyecto (elija solo uno):",
          en: "Dedication to the project (Choose only one):",
        },
        {
          name: "1 founder dedicated part-time",
          es: "1 fundador dedicado a tiempo parcial",
          en: "1 founder dedicated part-time",
        },
        {
          name: "1 founder dedicated full-time",
          es: "1 fundador dedicado a tiempo completo",
          en: "1 founder dedicated full-time",
        },
        {
          name: "2 founders dedicated part-time",
          es: "2 fundadores dedicados a tiempo parcial",
          en: "2 founders dedicated part-time",
        },
        {
          name: "1 founder full-time and 1 part-time",
          es: "1 fundador a tiempo completo y 1 a tiempo parcial",
          en: "1 founder full-time and 1 part-time",
        },
        {
          name: "2 founders, or more, dedicated full-time",
          es: "2 fundadores o más dedicados a tiempo completo",
          en: "2 founders, or more, dedicated full-time",
        },
        {
          name: "How do you sell to your clients?",
          es: "¿Cómo vende a sus clientes?",
          en: "How do you sell to your clients?",
        },
        {
          name: "What stage is your product at the moment?",
          es: "¿En qué etapa se encuentra su producto en este momento?",
          en: "What stage is your product at the moment?",
        },
        {
          name: "I have developed wireframes/designs/blueprints/models",
          es: "He desarrollado wireframes/diseños/planos/modelos",
          en: "I have developed wireframes/designs/blueprints/models",
        },
        {
          name: "I have developed a Minimum Viable Product",
          es: "He desarrollado un producto mínimo viable (MVP)",
          en: "I have developed a Minimum Viable Product",
        },
        {
          name: "I have launched my MVP have using customers",
          es: "He lanzado mi MVP y tengo clientes usándolo",
          en: "I have launched my MVP have using customers",
        },
        {
          name: "It is just an idea at the moment",
          es: "Solo es una idea en este momento",
          en: "It is just an idea at the moment",
        },
        {
          name: "It does not apply to my bussiness",
          es: "No aplica a mi negocio",
          en: "It does not apply to my bussiness",
        },
        {
          name: "What is the size of target market in total sales?",
          es: "¿Cuál es el tamaño del mercado objetivo en ventas totales?",
          en: "What is the size of target market in total sales?",
        },
        {
          name: "<$50 Million",
          es: "Menos de 50 millones de euros",
          en: "<$50 Million",
        },
        {
          name: "$50 - $100 Million",
          es: "50 - 100 millones de euros",
          en: "$50 - $100 Million",
        },
        {
          name: ">$100 Million",
          es: "Más de 100 millones de euros",
          en: ">$100 Million",
        },
        {
          name: "Looking at the present, How would you rate the success of your company/project?",
          es: "11- Mirando al presente, ¿cómo calificaría el éxito de su empresa/proyecto?",
          en: "Looking at the present, How would you rate the success of your company/project?",
        },
        {
          name: "changeRes",
          es: "Cambiar tu respuesta",
          en: "Change your answer",
        },
      ],
    };
  },
  created() {
    this.generalIndex = this.getIndex();
  },
  methods: {
    openCloseRecomendations(i) {
      if (!this.recomendationsClosed.includes(i)) {
        this.recomendationsClosed.push(i);
      } else {
        this.recomendationsClosed.splice(
          this.recomendationsClosed.indexOf(i),
          1
        );
      }
    },
    closeModal() {
      this.$emit("closeCdtiModal", false);
    },
    getIndex() {
      const index = this.data[0].invertibility_index;
      if (this.data !== undefined) {
        const myAnswers = this.answers[0];
        let allAnswers = [];
        let allQuestions = [];
        let allAnswersAnswer = [];
        myAnswers.forEach(
          function (el2) {
            allAnswers.push(el2.answer_options.split("[;separator;]"));
            allAnswersAnswer.push(el2.answer);
            allQuestions.push(el2.question);
          }.bind(this)
        );
        this.recomendations = [];
        this.data[1].forEach(
          function (el) {
            if (el.new_value !== null) {
              el.new_value = Math.round(el.new_value);
              el.invertibility_index =
                Math.round(el.invertibility_index * 100) / 100;
              const questNum = el.question_id.split("Q")[1];
              const quens = parseInt(questNum);
              axios
                .get(
                  `${process.env.VUE_APP_API_URL}/cdti/getInvertibilityValues/${el.new_value}/${quens}/${this.lang}`
                )
                .then((res) => {
                  const data = res.data;
                  const myAnswer =
                    allAnswersAnswer[quens - 1].split("[;separator;]")[0];
                  if (data !== allAnswersAnswer[quens - 1]) {
                    this.recomendations.push({
                      invertibility: el.invertibility_index,
                      question_num: `${this.langFilter("question")} ${quens}`,
                      question: ` '${this.langFilter(myAnswer)}' `,
                      answer: `'${data}'`,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }.bind(this)
        );
        return index.toFixed(2);
      }
      return 0;
    },
  },
  mixins: [translationMixin],
};
</script>
