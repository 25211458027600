<style>
@import "../../src/assets/css/faqs.css";
</style>

<script setup>
import Faqs from "../components/version/VersionComponent.vue";
</script>

<template>
  <Faqs :lang="lang" ref="faqs" :ruta="ruta"/>
</template>

<script>
export default {
  props: ["lang"],
  data() {
    return {
        ruta:'',
    }
  },
  created(){
    // let page = window.location.pathname;
    if (window.location.pathname == "/version") {
      this.ruta = 1;
    } 
  }
};
</script>