<style>
@import "../../src/assets/css/faqs.css";
</style>

<script setup>
import Faqs from "../components/faqs/FaqsComponent.vue";
</script>

<template>
  <Faqs :lang="lang" ref="faqs" :ruta="ruta"/>
</template>

<script>
export default {
  props: ["lang"],
  data() {
    return {
        ruta:'',
    }
  },
  created(){
    let page = window.location.pathname;
    let pagename = page.split("/");
    if (window.location.pathname == "/faqs") {
      this.ruta = 1;
    } else if (window.location.pathname.includes("/faqs/") && pagename[2]) {
      this.ruta = 2;
    } else if (window.location.pathname == '/feedback/support-great/') {
      this.ruta = 3;
    } else if (window.location.pathname == "/customer-support/") {
      this.ruta = 4;
    } else if (window.location.pathname == '/feedback/support-message-sent/') {
      this.ruta = 5;
        }
  }
};
</script>