<template>
  <div class="new-template-container container">
    <new-template-title :stepNum="stepNum" :lang="lang" />
    <new-template-body
      @setTitle="setTitle($event)"
      @setTemplateAssistant="setTemplateAssistant($event)"
      @setTemplateAssistantName="setTemplateAssistantName($event)"
      @setTemplateImage="setTemplateImage($event)"
      @enableNext="enableNext($event)"
      @setCategories="setCategories($event)"
      @errorFileUpload="errorFileUpload()"
      @setAssistant="setAssistant($event)"
      @setAssistantEvaluator="setAssistantEvaluator($event)"
      :stepNum="stepNum"
      :title="title"
      :template_assistant_id="template_assistant_id"
      :template_assistant_name="template_assistant_name"
      :assistant_id="assistant_id"
      :evaluator_id="assistant_evaluator_id"
      :lang="lang"
      :space="space"
      :draftCategories="categories"
    />
    <new-template-template
      v-if="stepNum < 1"
      @setTitle="setTitle($event)"
      @setTemplateAssistant="setTemplateAssistant($event)"
      @setTemplateAssistantName="setTemplateAssistantName($event)"
      @setAssistant="setAssistant($event)"
      @setAssistantEvaluator="setAssistantEvaluator($event)"
      :stepNum="stepNum"
      :lang="lang"
      :title="title"
      :template_assistant_id="template_assistant_id"
      :template_assistant_name="template_assistant_name"
      :assistant_id="assistant_id"
      :assistant_evaluator_id="assistant_evaluator_id"
      :image="myImage"
      :categories="categories"
    />
  </div>
</template>

<script>
import translationMixin from "../../../mixins/translationMixin.js";
export default {
  props: ["lang", "space", "stepNum", "title", "image", "categories", "template_assistant_id", "template_assistant_name", "assistant_id", "assistant_evaluator_id" ],
  data() {
    return {
      myImage: this.image,
    };
  },
  methods: {
    setTitle(event) {
      this.$emit("setTitle", event);
      this.$emit("enableNext", true);
    },
    setTemplateImage(event) {
      this.myImage = process.env.VUE_APP_API_STORAGE + event;
      this.$emit("enableNext", true);
      this.$emit("setTemplateImage", event);
    },
    setTemplateAssistant(event) {
      this.$emit("setTemplateAssistant", event);
    },
    setTemplateAssistantName(event) {
      this.$emit("setTemplateAssistantName", event);
    },
    setAssistant(event){
      console.log(event);
      this.$emit('setAssistant', event != 0 ? event : null);
    },
    setAssistantEvaluator(event){
      console.log(event);
      this.$emit('setAssistantEvaluator', event != 0 ? event : null);
    },
    enableNext(event) {
      this.$emit("enableNext", event);
    },
    setCategories(event) {
      this.$emit("setCategories", event);
    },
    errorFileUpload() {
      this.$emit("errorFileUpload");
    },
  },
  watch: {
    image: function (newVal) {
      this.myImage = newVal;
    },
  },
  mixins: [translationMixin],
};
</script>
