<style>
@import "../../src/assets/css/newitinerary/newitinerary.css";
</style>

<script setup>
import newItinerary from "../components/newitinerary/newItineraryComponent.vue";
</script>

<template>
  <newItinerary
    :role="role"
    :space="spaces[0]"
    :lang="lang"
    @alertMessage="alertMessage"
  />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
  methods: {
    alertMessage() {
      this.$emit("openAlert", { message: "projectSaved", colour: 2 });
    },
  },
};
</script>
