<template>
  <div class="container">
    <h1>{{ langFilter("link") }}</h1>
    <span>
      {{ langFilter("introduction") }}
      <a
        href="https://help.staging.acceleralia.com/"
        target="_blank"
        class="bolder"
        rel="noopener noreferrer"
        >{{ langFilter("link") }}</a
      >
      {{ langFilter("description") }}
    </span>

    <div class="mt-4">
      <h1>FAQs</h1>
      <p>{{ langFilter("faqsdescription") }}</p>

      <div v-for="category in categories" :key="category.id" class="m-4">
        <h3 class="text-capitalize">{{ category.name }}</h3>
        <div
          class="accordion accordion-flush my-3"
          :id="'accordion' + category.id"
        >
          <div
            v-for="answer in getAnswers(category.id)"
            :key="answer.id"
            class="accordion-item"
          >
            <h2 class="accordion-header" :id="'heading' + answer.id">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + answer.id"
                aria-expanded="false"
                :aria-controls="'collapse' + answer.id"
              >
                {{ answer.question }}
              </button>
            </h2>
            <div
              :id="'collapse' + answer.id"
              class="accordion-collapse collapse"
              :aria-labelledby="'heading' + answer.id"
              :data-bs-parent="'#accordion' + category.id"
            >
              <div class="accordion-body">
                <div v-html="answer.answer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
export default {
  props: ["lang", "categories", "answers"],
  data() {
    return {
      traducciones: [
        {
          name: "introduction",
          es: "Para comenzar con nuestra plataforma, te recomendamos leer la sección de",
          en: "To get started with our platform, we recommend reading the",
        },
        {
          name: "link",
          es: "Primeros pasos",
          en: "Getting Started",
        },
        {
          name: "description",
          es: "Allí encontrarás toda la información que necesitas para empezar.",
          en: "There you will find all the information you need to begin.",
        },
        {
          name: "faqsdescription",
          es: "Aquí puedes encontrar las preguntas frecuentes sobre diversos temas. Explora las distintas categorías para ver las preguntas y respuestas correspondientes.",
          en: "Here you can find frequently asked questions about various topics. Explore the different categories to see the corresponding questions and answers.",
        },
      ],
    };
  },
  methods: {
    getAnswers(categoryId) {
      if (Array.isArray(this.answers)) {
        return this.answers.filter(
          (answer) => answer.category_id === categoryId
        );
      }
      return [];
    },
  },
  mixins: [translationMixin],
};
</script>
