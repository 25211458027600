<style lang="scss">
@import "../../../src/assets/css/modals/smart_printing.css";
</style>
<template>
  <div
    class="modal fade"
    id="smartPrintingModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document" style="width: 500px">
      <div
        style="max-height: auto; height: auto; padding: 0; width: 500px"
        class="modal-container modal-content"
      >
        <div class="modal-body" style="display: flex; align-items: center">
          <div class="sm-close-modal-btn" data-bs-dismiss="modal"></div>
          <div class="smart-printing-modal-body">
            <h3 class="smart-printing-title">Smart Printing</h3>
            <div
              v-for="(smartPrinting, smartPrintingsIndex) in smartPrintings"
              :key="smartPrintingsIndex"
              @mouseenter="addClass"
              @mouseleave="removeClass"
              :id="`-${smartPrintingsIndex}`"
              class="smart-printing-modal-option"
            >
              <table class="no-style-table">
                <tr>
                  <td class="smart-printing-td-name">
                    <span
                      :id="'sp-txt-' + smartPrintingsIndex"
                      class="smart-printing-modal-txt"
                      >{{ smartPrinting.name }}</span
                    >
                  </td>
                  <td class="smart-printing-td-download">
                    <a
                      :href="
                        downloadSmartPrinting(
                          smartPrinting.excel_file_url,
                          smartPrinting.name
                        )
                      "
                      target="_blank"
                    >
                      <button class="btn-no-style">
                        <img
                          :id="'arrow-down-' + smartPrintingsIndex"
                          src="/img/general/arrow_down_grey.svg"
                        />
                        <span
                          :id="'dw-txt-' + smartPrintingsIndex"
                          class="download-txt"
                          >{{ langFilter("download") }}</span
                        >
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            <div style="margin-left: 36px" v-if="smartPrintings.length === 0">
              <p class="no-smart-printing">{{ langFilter("no-data") }}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  props: ["smartPrintings", "project", "lang"],

  data() {
    return {
      // traduccion
      traducciones: [
        {
          name: "download",
          es: "Descargar",
          en: "Download",
        },
        {
          name: "no-data",
          es: "No tienes smart printing asociado a este proyecto",
          en: "You don't have smart printing associated to this project",
        },
      ],
    };
  },
  methods: {
    downloadSmartPrinting() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/myProjects/getProjectLang/${this.project[0]}`
        )
        .then((response) => {
          const lang = response.data;
          return `${process.env.VUE_APP_API_URL}/SmartPrinting/${lang}/${this.project[0]}`;
        })
        .catch(() => {
          return `${process.env.VUE_APP_API_URL}/SmartPrinting/es/${this.project[0]}`;
        });
    },
    addClass: function (e) {
      e.target.classList.add("smart-printing-modal-option-hover");
      $("#sp-txt" + e.target.id + ", #dw-txt" + e.target.id).addClass(
        "smart-printing-modal-txt-hover"
      );
      $("#arrow-down" + e.target.id).attr(
        "src",
        "/img/general/arrow_down_black.svg"
      );
    },
    removeClass: function (e) {
      e.target.classList.remove("smart-printing-modal-option-hover");
      $("#sp-txt" + e.target.id + ", #dw-txt" + e.target.id).removeClass(
        "smart-printing-modal-txt-hover"
      );
      $("#arrow-down" + e.target.id).attr(
        "src",
        "/img/general/arrow_down_grey.svg"
      );
    },
  },
  mixins: [translationMixin],
};
</script>
