<template>
  <div class="new-template-footer-container container">
    <div class="row">
      <div class="col-2">
        <img
          @click="previousStep"
          v-if="stepNum > 0"
          class="pointer"
          :src="require('@/assets/img/general/arrow-right-purple.svg')"
          alt="previous"
        />
      </div>
      <div class="col-8" />
      <div class="col-2">
        <button
          @click="setNextStep"
          :class="[
            { 'template-next-btn-purple': nextStep },
            { 'template-next-btn-gray': !nextStep },
            'template-next-btn',
          ]"
        >
          {{ langFilter("next") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../../mixins/translationMixin.js";
export default {
  props: ["lang", "stepNum", "nextStep"],
  data() {
    return {
      traducciones: [
        {
          name: "next",
          es: "Siguiente",
          en: "Next",
        },
      ],
    };
  },
  methods: {
    previousStep() {
      this.$emit("previousStep");
    },
    setNextStep() {
      if (this.nextStep) {
        this.$emit("setNextStep");
      }
    },
  },
  mixins: [translationMixin],
};
</script>
