<template>
  <button @click="cdti">CDTI</button>
</template>
<script>
import axios from "axios";
export default {
  methods: {
    cdti() {
      axios
        .post("https://acctest123.acceleralia.com:8443/answer/run-inference", {
          q1: "Education",
          q2: "I am unsure",
          q3: "It does not apply to my bussiness",
          q4: "Between 26 - 50%",
          q5: "1.000.000€ - 5.000.000€",
          q6: "Experience in Sales or Technology",
          q7: "1 founder full-time and 1 part-time",
          q8: "B2B2C",
          q9: "I have launched my MVP have using customers",
          q10: ">$100 Million",
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
