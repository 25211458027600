<template>
  <div style="text-align: center">
    <br /><br />
    <select @change="setOption">
      <option :value="1">Create</option>
      <option :value="2">Update</option>
      <option :value="3">Delete</option>
    </select>
    <br /><br />
    <create-space v-if="option == 1" @msg="showMsg($event)" />
    <update-space v-else-if="option == 2" @msg="showMsg($event)" />
    <delete-space v-else-if="option == 3" @msg="showMsg($event)" />
    <p>{{ msg }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option: 1,
      msg: "",
    };
  },
  methods: {
    setOption(event) {
      this.msg = "";
      this.option = event.target.value;
    },
    showMsg(msg) {
      this.msg = msg;
      setTimeout(() => {
        this.msg = "";
      }, 3000);
    },
  },
};
</script>

<style></style>
