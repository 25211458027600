<style lang="scss" scoped>
@import "../../../src/assets/css/modals/generic_modal.css";
</style>

<template>
  <div class="confirmation-backdrop generic-modal">
    <div class="confirmation-modal-fixed modal-content">
      <button @click="closeConfirmation" class="confirmation-close-btn" />
      <div class="confirmation-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["closeConfirmation"],
  methods: {
    closeConfirmation() {
      this.$emit("closeConfirmation");
    },
  },
};
</script>
