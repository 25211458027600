<template>
  <div v-if="false">
    <div v-if="showModal" class="cdti-modal-container">
      <span @click="closeModal" class="cdti-close-x cdti-close-x-it" />
      <p>{{ langFilter("invertibilityIndex") }}</p>
      <div>
        <div>
          <button @click="closeModal">{{ langFilter("noThanks") }}</button>
          <router-link :to="`/invertibility-index/${project_id}`">
            <button>{{ langFilter("letsGo") }}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "project_id"],
  data() {
    return {
      showModal: true,
      traducciones: [
        {
          name: "invertibilityIndex",
          es: "¿Te gustaría comprobar tu índice de invertibilidad de este proyecto?",
          en: "Would you like to check your invertibility index of this project?",
        },
        {
          name: "noThanks",
          es: "No, gracias",
          en: "No, thanks",
        },
        {
          name: "letsGo",
          es: "¡Vamos allá!",
          en: "Let's go!",
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
  mixins: [translationMixin],
};
</script>
