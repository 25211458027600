<template>
  <div id="tongue_modal" class="tongue-modal" style="z-index: 1000">
    <router-link to="/edit-profile">
      <div
        @click="$emit('closeTongue')"
        class="div-notifications-modal"
        style="z-index: 10002"
      >
        <img
          :src="require('@/assets/img/tongue/perfil.svg')"
          alt="my profile"
        />
        <span class="notification-txt-modal">{{
          langFilter("my_profile")
        }}</span>
      </div>
    </router-link>
    <div @click="logout" class="div-notifications-modal">
      <img :src="require('@/assets/img/tongue/logout.svg')" alt="log out" />
      <span class="notification-txt-modal">{{ langFilter("log_out") }}</span>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import Cookies from "js-cookie";
export default {
  props: ["lang", "user"],
  data() {
    return {
      traducciones: [
        {
          name: "my_profile",
          es: "Mi perfil",
          en: "My profile",
        },
        {
          name: "log_out",
          es: "Cerrar sesión",
          en: "Log out",
        },
      ],
    };
  },
  methods: {
    logout() {
      Cookies.remove("token");
      window.location.href = "/";
    },
  },
  mixins: [translationMixin],
};
</script>
