<style>
@import "../../src/assets/css/home/home.css";
</style>

<script setup>
import Home from "../components/home/HomeComponent.vue";
</script>

<template>
  <Home
    :spaces="spaces"
    :role="role"
    :lang="lang"
    :homeNotifications="homeNotifications"
  />
</template>

<script>
export default {
  props: ["spaces", "role", "lang", "homeNotifications"],
};
</script>
