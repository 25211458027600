<template>
  <div>
    <head>
      <link rel="stylesheet" type="text/css" href="/css/faqs.css?v=3" />

      <title>{{ langFilter("title_faq_screen") }} - Acceleralia</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/img/general/favicon.png" />
    </head>
    <header-general
      src="/img/feedback/contacto.svg"
      :title="langFilter('contacto')"
    ></header-general>
    <div class="vistaFaqs">
      <barraContact :lang="lang"></barraContact>
      <div class="consulta" style="margin-bottom: 50px">
        <div class="textoConsulta" v-html="langFilter('text')"></div>
      </div>

      <div class="espacioCategorias">
        <div class="container">
          <div class="row" style="justify-content: center">
            <div
              class="cajaCategoria col-3 m-3"
              v-for="category in categories"
              :key="category.id"
            >
              <a :href="'./faqs/' + category.id">
                <span class="textoCategoria">
                  {{ category.name }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import barraContact from "./BarraContactComponent.vue";
import axios from "axios";

export default {
  components: {
    barraContact,
  },
  props: ["lang"],
  data() {
    return {
      categories: {},
      traducciones: [
        {
          name: "title_faq_screen",
          es: "FAQs",
          en: "FAQs",
        },
        {
          name: "text",
          es: "Danos los detalles y te ofreceremos la mejor solución.<br />Explícanos cuál es tu incidencia y uno de nuestros expertos se pondrá en contacto contigo.<br /><br />Selecciona el tema que mejor se ajusta a tu incidencia.",
          en: "Give us the details and we will offer you the best solution.<br />Tell us what your incident is and one of our experts will contact you.<br /><br />Select the topic that best fits your issue.",
        },
        {
          name: "contacto",
          es: "Contacto",
          en: "Contact",
        },
      ],
    };
  },
  methods: {
    getCategories() {
      axios
        .get("/listCategories", {
          params: {
            lang: this.lang,
          },
        })
        .then((response) => {
          this.categories = response.data.categories;
        });
    },
  },
  created() {
    this.getCategories();
  },
  mounted() {
    this.$parent.loading = false;
  },
  mixins: [translationMixin],
};
</script>
