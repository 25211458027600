<template>
  <div class="container-track-it">
    <div v-if="resources.length != 0 || resourcesStat.length != 0">
      <div
        class="track-rec-div"
        v-for="(resourceStat, resourcesStatIndex) in resourcesStat"
        :key="resourcesStatIndex"
      >
        <a
          :href="openResource(resourceStat.link)"
          target="_blank"
          class="track-rec-div-link"
        >
          <img
            :class="recTrackIcon(resourceStat.link)"
            :src="getResourceIcon(resourceStat.link)"
            alt="rec_icon"
          />
          {{ resourceStat.resource }}
        </a>
      </div>
      <div
        class="track-rec-div"
        v-for="(resource, resourcesIndex) in resources"
        :key="resourcesIndex"
      >
        <a
          :href="openResource(resource.link)"
          target="_blank"
          class="track-rec-div-link"
        >
          <img
            :class="recTrackIcon(resource.link)"
            :src="getResourceIcon(resource.link)"
            alt="rec_icon"
          />
          {{ resource.resource }}
        </a>
      </div>
    </div>
    <div style="padding-left: 44px" class="data-track-name" v-else>
      {{ langFilter("noRec") }}
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import generalMethodsMixin from "../../mixins/generalMethodsMixin.js";
export default {
  props: ["lang", "resources", "resourcesStat"],
  data() {
    return {
      // traduccion
      traducciones: [
        {
          name: "select",
          es: "Seleccionar Track",
          en: "Select Track",
        },
        {
          name: "noRec",
          es: "No hay recursos asociados",
          en: "No resources associated",
        },
      ],
    };
  },
  methods: {
    openResource(link) {
      const endpoint = process.env.VUE_APP_API_STORAGE;
      if (link.includes("/resources/")) {
        if (link.includes("api.acceleralia")) return link;
        return endpoint + link;
      }
      return this.getValidUrl(link);
    },
    recTrackIcon(link) {
      let icon = "rec-track-icon";
      if (link.includes("youtu")) {
        icon = "rec-link-icon";
      }
      return icon;
    },
    getResourceIcon(link) {
      let icon = "/img/resources/file_icon.svg";
      if (link.includes("youtu")) {
        icon = "/img/resources/link_icon.svg";
      } else if (link.includes("mp4")) {
        icon = "/img/resources/video_icon.svg";
      }
      return icon;
    },
  },
  mixins: [translationMixin, generalMethodsMixin],
};
</script>
