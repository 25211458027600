<template>
  <div class="relative">
    <div class="home-noti-div pointer">
      <router-link to="/notifications">
        <img
          :src="require('@/assets/img/home/notifications.svg')"
          alt="project"
          class="img-notification-home"
        />
        <span class="title-home-banner">{{ langFilter("notifications") }}</span>
      </router-link>
    </div>
    <div v-if="loading" class="align-center">
      <img class="home-load" :src="require('@/assets/img/general/carga.gif')" />
    </div>
    <div v-show="!loading">
      <div
        v-for="(notification, notificationIndex) in notifications"
        :key="notificationIndex"
        class="home-not-div pointer"
        @click="goToNotification(notification.url)"
      >
        <a
          @click="markAsRead(notification)"
          :href="this.getUrl(notification.url)"
        >
          <img
            v-if="checkNotificationNew(notification.new, notification.id)"
            :src="require('@/assets/img/home/point_purple.svg')"
            alt="point"
            class="notification-home-point"
          />
          <div class="title-home-notifications">
            <span v-html="notification.message"></span>
          </div>
          <div class="home-body-notifications">
            <span class="time-home-notifications">
              {{ notification.notification_date }}
            </span>
          </div>
        </a>
      </div>
      <div v-if="notifications.length == 0" class="no-not-home">
        <span>{{ langFilter("no-notification") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import notificationsMixin from "../../mixins/notificationsMixin.js";
import axios from "axios";
export default {
  props: ["web", "user", "lang", "space_id", "homeNotifications"],
  data() {
    return {
      notifications: [],
      userDoAction: [],
      objectName: [],
      loading: true,
      traducciones: [
        {
          name: "notifications",
          es: "Notificaciones",
          en: "Notifications",
        },
        {
          name: "no-notification",
          es: "No tienes notificaciones",
          en: "You don't have notifications",
        },
      ],
    };
  },
  created() {
    this.loadNotifications();
  },
  methods: {
    loadNotifications() {
      this.getNotifications(0, 5);
      axios
        .post(`${process.env.VUE_APP_API_URL}/notifications/count`)
        .then((response) => {
          if (response.data == 0) {
            this.initialLoad = false;
          }
        });
    },
    markAsRead(notification) {
      event.preventDefault();
      this.$emit("closeNotifications");
      axios
        .patch(`${process.env.VUE_APP_API_URL}/home/changeNotificationNew`, {
          notification_id: notification.id,
        })
        .then(() => {
          window.location.href = this.getUrl(notification.url);
        });
    },
    checkNotificationNew(isNew) {
      if (isNew != 0) {
        return true;
      }
      return false;
    },
    getUrl(url) {
      if (url.includes(process.env.VUE_APP_FRONTEND_URL)) {
        url = url.replace(process.env.VUE_APP_FRONTEND_URL, "");
      }
      return url;
    },
  },

  watch: {
    homeNotifications: function () {
      this.loading = true;
      this.loadNotifications();
    },
  },
  mixins: [translationMixin, notificationsMixin],
};
</script>
