<template>
  <div class="row">
    <div class="col-4" style="position: relative">
      <input
        @input="updateSearch($event)"
        @click="openSearchedUsers"
        :value="search"
        :placeholder="langFilter('search-by-user')"
        :style="search !== '' ? 'border: 2px solid #c5c1e4;' : ''"
        id="ap-search-users-input"
        class="input-search-ap"
        type="text"
      />
      <img
        v-if="search === ''"
        class="lupa-ap"
        :src="require('@/assets/img/general/lupa-gris.svg')"
        alt="search"
      />
      <img
        v-else
        class="lupa-ap"
        :src="require('@/assets/img/general/lupa-selected.svg')"
        alt="search"
      />
      <div
        v-show="searching"
        v-outside="closeSearchedUsers"
        id="ap-search-users-container"
        class="searched-users-ap"
      >
        <div
          v-for="(user, userIndex) in users"
          :key="userIndex"
          @click="selectUser(user)"
          class="users-searched-ap"
        >
          {{ user.name }}
        </div>
      </div>
    </div>
    <div class="col-5 padding-margin-0">
      <input
        @input="updateSearch2($event)"
        :value="search2"
        :placeholder="langFilter('invite-user')"
        class="input-search-ap2"
        :class="{
          'error-email-ap': errorEmail,
        }"
        type="email"
      />
    </div>
    <div class="col-1 padding-margin-0">
      <select
        @change="updateUserType($event)"
        name="sel_user_type"
        id="sel_user_type"
        class="select-type-ap"
      >
        <option
          v-for="(role, roleIndex) in roles"
          :key="roleIndex"
          :value="role.id"
        >
          {{ langFilter(role.name) }}
        </option>
      </select>
    </div>
    <div class="col-2 padding-margin-0">
      <button
        @click="inviteEmailAP"
        :class="{ 'invite-btn-ap-click': invite }"
        class="invite-btn-ap"
      >
        {{ langFilter("invite") }}
      </button>
    </div>
    <alertMessage :lang="lang" ref="alerts"></alertMessage>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: ["roles", "space", "allUsers", "lang"],
  data() {
    return {
      search: "",
      search2: "",
      searching: false,
      user_type: 1,
      users: [],
      errorEmail: false,
      invite: false,
      errors: [],
      succesful: [],
      traducciones: [
        {
          name: "search-by-user",
          es: "Buscar por usuario o email...",
          en: "Search by user or email...",
        },
        {
          name: "invite-user",
          es: "Invitar vía email...",
          en: "Invite via email...",
        },
        {
          name: "admin",
          es: "Admin",
          en: "Admin",
        },
        {
          name: "member",
          es: "Miembro",
          en: "Member",
        },
        {
          name: "invite",
          es: "INVITAR",
          en: "INVITE",
        },
      ],
    };
  },
  created() {
    this.users = this.allUsers;
  },
  watch: {
    allUsers() {
      this.filterUsers();
    },
  },
  methods: {
    openSearchedUsers() {
      if (this.search !== "") {
        this.searching = true;
      }
    },
    closeSearchedUsers(el) {
      if (
        el.target.id !== "ap-search-users-container" &&
        el.target.id !== "ap-search-users-input"
      ) {
        this.searching = false;
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    updateSearch(event) {
      const name = event.target.value;
      this.search = name;
      this.filterUsers();
      this.search === "" || this.users.length === 0
        ? (this.searching = false)
        : (this.searching = true);
    },
    filterUsers() {
      this.users = this.$parent.allUsers.filter(
        (i) =>
          i.name.toLowerCase().includes(this.search.toLowerCase()) ||
          i.email.toLowerCase().includes(this.search.toLowerCase())
      );
      this.$parent.users = this.users;
    },
    updateSearch2(event) {
      this.search2 = event.target.value;
      if (this.search2 !== "") {
        this.errorEmail = false;
      }
    },
    updateUserType(event) {
      this.user_type = parseInt(event.target.value);
    },
    selectUser(user) {
      this.search = user.name;
      this.$parent.users = [user];
      this.searching = false;
    },
    inviteEmailAP() {
      if (this.search2 == "") {
        this.errorEmail = true;
        this.$refs.alerts.title = "Incompleted field!";
        this.$refs.alerts.text =
          "To INVITE a new user you have to insert an email.";
        this.$refs.alerts.succesful = false;
        this.openErrorsAlert();
      } else if (
        String(this.search2)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
        this.search2 !== ""
      ) {
        for (let i = 0; i < this.allUsers.length; i++) {
          if (this.allUsers[i].email === this.search2) {
            this.errorEmail = true;
            this.$refs.alerts.title = "Incorrect field!";
            this.$refs.alerts.text = "The user is already in the space";
            this.$refs.alerts.succesful = false;
            this.openErrorsAlert();
            return;
          }
        }
        this.invite = true;
        setTimeout(
          function () {
            this.invite = false;
          }.bind(this),
          150
        );
        axios
          .post(`${process.env.VUE_APP_API_URL}/adminpanel/sendUserEmail`, {
            email: this.search2,
            user_type: this.user_type,
            lang: this.lang,
            space: this.space.name,
            space_id: this.space.id,
          })
          .then(() => {
            this.search2 = "";
            this.$refs.alerts.title = "Email sent!";
            this.$refs.alerts.text = "An invitation email has been sent";
            this.$refs.alerts.succesful = true;
            this.openErrorsAlert();
          });
      } else {
        this.errorEmail = true;
        this.$refs.alerts.title = "Incorrect field!";
        this.$refs.alerts.text = "Please insert a valid email";
        this.$refs.alerts.succesful = false;
        this.openErrorsAlert();
      }
    },
  },
  mixins: [translationMixin],
};
</script>
