<template>
  <div class="right-sidebar">
    <button
      @click="showView(i)"
      v-for="(button, i) in filteredButtons"
      :key="i"
      :class="[
        view === i ? 'sidebar-btn-bg-selected' : 'sidebar-btn-bg',
        { 'disabled-btn': i === 4 && !taskManager }
      ]"
      :disabled="i === 4 && !taskManager"
    >
      <hover :content="langFilter(button)">
        <img
          :class="(i === 3 || i === 4) && 'sidebar-btn-img'"
          :src="
            i === 3 || i === 4
              ? require(`@/assets/img/right-sidebar/${button}.webp`)
              : require(`@/assets/img/right-sidebar/${button}.svg`)
          "
          :alt="button"
        />
      </hover>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["buttons", "view", "project_id", "lang"],
  data() {
    return {
      taskManager: "",
      traducciones: [
      {
          name: "itinerary",
          es: "Itinerario",
          en: "Itinerary",
        },
        {
          name: "users",
          es: "Usuarios",
          en: "Users",
        },
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "taskManager",
          es: "Gestor de tareas",
          en: "Task Manager",
        },
        {
          name: "smartPrinting",
          es: "Smart Printing",
          en: "Smart Printing",
        },
      ],
    };
  },
  computed: {
    filteredButtons() {
      return this.buttons;
    },
  },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/myProjects/getProjectTaskManager/${this.project_id}`
      )
      .then((response) => {
        if (response.data && response.data.task_manager_url) {
          this.taskManager = response.data.task_manager_url;
        } else {
          this.taskManager = null;
        }
      })
      .catch((error) => {
        console.error(error);
        this.taskManager = null;
      });
  },

  methods: {
    showView(i) {
      if (i === 4 && this.taskManager) {
        window.open(this.taskManager, "_blank");
      } else {
        this.$emit("showView", i);
      }
    },
  },
  mixins: [translationMixin],
};
</script>