<style>
@import "../../src/assets/css/components/pagination.css";
</style>
<template>
  <div>
    <div class="pagination-div">
      <img
        id="full_back_arrow"
        @click="paginate(0, 1)"
        class="pagination-arrow"
        :src="require('@/assets/img/pagination/all_left.svg')"
      />
      <img
        id="back_arrow"
        @click="paginate(1, 1)"
        class="pagination-arrow"
        :src="require('@/assets/img/pagination/left.svg')"
      />
      <span
        v-for="(value, valuesIndex) in paginatedMax"
        :key="valuesIndex"
        @click="paginate(value - 1, 0)"
        v-bind:class="{ 'num-selec': position == value - 1 }"
        class="pagination-num"
      >
        {{ value }}
      </span>
      <img
        id="next_arrow"
        @click="paginate(2, 1)"
        class="pagination-arrow"
        :src="require('@/assets/img/pagination/right.svg')"
      />
      <img
        id="full_next_arrow"
        @click="paginate(3, 1)"
        class="pagination-arrow"
        :src="require('@/assets/img/pagination/all_right.svg')"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: ["countLength"],
  data() {
    return {
      token: "",
      lang: "en",
      position: 0,
      maxPosc: 0,
      minPosc: 0,
      paginatedMax: 0,
      valuesArray: [],
      valuesArrayOld: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
        75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
        93, 94, 95, 96, 97, 98, 99, 100,
      ],
    };
  },
  watch: {
    countLength: function (val) {
      this.val = this.$parent.myProjectsPagination.length;
      if (this.val != 0) {
        this.oldMaxPosc = this.maxPosc;
        if (val >= 25) {
          this.maxPosc = Math.ceil(val / 25);
        } else {
          this.maxPosc = 1;
        }
      } else {
        this.maxPosc = this.oldMaxPosc;
      }
      this.paginationInit();
      this.paginate(0, 1);
    },
  },
  created() {
    this.paginationInit();
  },

  methods: {
    paginationInit() {
      this.maxPosc = Math.ceil(this.$parent.myProjectsPagination.length / 25);
      this.valuesArray = this.valuesArrayOld;
      this.valuesArray = this.valuesArrayOld.slice(0, this.maxPosc);
      this.maxPosc -= 1;
      this.paginatedMax = this.valuesArray.slice(0, 5);
      if (this.$parent.currentPage > this.maxPosc) {
        this.$parent.currentPage -= 1;
        this.$parent.paginated(this.$parent.currentPage);
      }
      this.position = this.$parent.currentPage;
    },
    paginate(posc, arrow) {
      $(".carga").show();
      $(".myProjects-MainContainer").hide();
      setTimeout(function () {
        $(".carga").hide();
        $(".myProjects-MainContainer").show();
      }, 500);
      $("html, body").animate({ scrollTop: 0 }, "slow");
      if (arrow == 1) {
        if (posc == 1 && this.position != 0) {
          this.position -= 1;
        } else if (posc == 2 && this.position != this.maxPosc) {
          this.position += 1;
        } else if (posc == 0) {
          this.position = 0;
        } else if (posc == 3) {
          this.position = this.maxPosc;
        }
      } else {
        this.position = posc;
      }

      if (this.position == 0) {
        this.minPosc = 0;
        this.paginatedMax = this.valuesArray.slice(0, 5);
      } else {
        if (this.position + 5 < this.maxPosc) {
          if (this.position != 0) {
            this.minPosc = this.position - 1;
          } else {
            this.minPosc = 0;
          }
        } else {
          if (this.position == this.maxPosc) {
            this.minPosc = this.position - 4;
          } else if (this.position + 1 >= this.maxPosc) {
            this.minPosc = this.position - 3;
          } else if (this.position + 2 >= this.maxPosc) {
            this.minPosc = this.position - 2;
          } else {
            this.minPosc = this.position - 1;
          }
        }
      }

      if (
        this.position > 2 &&
        this.position < this.maxPosc - 1 &&
        this.position !== this.maxPosc
      ) {
        // if (this.maxPosc == this.position){
        //     this.paginatedMax = this.valuesArray.slice(this.position-4, this.position+2);
        // }else{
        //     this.paginatedMax = this.valuesArray.slice(this.position-3, this.position+2);
        // }
        this.paginatedMax = this.valuesArray.slice(
          this.position - 2,
          this.position + 3
        );
      } else if (this.position <= 2) {
        // if (1 == this.position){
        //     this.paginatedMax = this.valuesArray.slice(0, 5);
        // }else{
        //     this.paginatedMax = this.valuesArray.slice(this.position-1, this.position+4);
        // }
        this.paginatedMax = this.valuesArray.slice(0, 5);
      } else if (this.position == this.maxPosc) {
        this.paginatedMax = this.valuesArray.slice(
          this.maxPosc - 4,
          this.maxPosc + 1
        );
      }

      this.$emit("update:option", this.position);
      this.$parent.currentPage = this.position;
    },
  },
};
</script>
