export const useDebugMode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const debugParam = urlParams.get("debug");

  if (debugParam !== null) {
    localStorage.setItem("debug", debugParam);
  }

  const debugData = localStorage.getItem("debug");
  const isDebugMode = ["1", "true"].includes(debugData);
  return { isDebugMode };
};
