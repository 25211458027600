<style>
@import "../../src/assets/css/track/track.css";
@import "../../src/assets/css/track/new_track.css";
</style>

<script setup>
import Tracks from "../components/track/TrackRootComponent.vue";
</script>

<template>
  <Tracks :lang="lang" :role="role" :spaces="spaces" />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
};
</script>
