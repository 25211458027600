<template>
                    <div class="modal-input-row" style="padding-bottom:0px;">
                        <div class="modal-input-col" >
                                <div class="modal-button-confirm" @click="$parent.$parent.submit_modal(button_id)">
                                    <p>{{button}}</p>
                                </div>
                        </div>
                        
                         
                        
                    </div>
</template>

<script>

import translationMixin from '../../mixins/translationMixin.js';



    export default{
 
    props:['lang','button','button_id'],    

   
        data(){
            
            return {
                
               
                //Traducción
                traducciones: [
                   
                ],
                
            
                
                
            }
            
        },
        created(){
            
            
        },
        mounted(){
           
        },
        computed:{
           
        },
        methods:{
           
            

        },
            mixins: [translationMixin], 

            
            

            };

</script>


