<template>
  <div>
    <router-link :to="getUrl(optionIndex)">
      <div class="sidebar-closed-option-content">
        <div>
          <img
            v-show="optionSelected"
            :src="require(`@/assets/img/sidebar/hovers/${option.img}.svg`)"
            class="sidebar-option-image"
          />
          <img
            v-show="!optionSelected"
            :src="require(`@/assets/img/sidebar/logos/${option.img}.svg`)"
            class="sidebar-option-image"
          />
        </div>

        <div class="sidebar-option-popup">
          <p>{{ langFilter(option.name) }}</p>
        </div>
        <!-- <p
                class="sidebar-option-text"
                :class="{
                    'sidebar-option-selected': optionSelected,
                }"
            >
                {{ langFilter(option.name) }}
            </p> -->
      </div>
      <!-- <div
            v-if="optionIndex !== $parent.page"
            :id="`sidebar-mouse-over-${optionIndex}`"
            class="sidebar-mouse-over"
        ></div> -->
    </router-link>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  data() {
    return {
      traducciones: [
        {
          name: "Home",
          es: "Home",
          en: "Home",
        },
        {
          name: "Projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "Programs",
          es: "Programas",
          en: "Programs",
        },
        {
          name: "Itinerary",
          es: "Plantillas",
          en: "Templates",
        },
        {
          name: "Forum",
          es: "Foro",
          en: "Forum",
        },
        {
          name: "Admin Panel",
          es: "Admin Panel",
          en: "Admin Panel",
        },
        {
          name: "Assistants",
          es: "Asistentes",
          en: "Assistants",
        },
      ],
    };
  },
  methods: {
    getUrl(optionIndex) {
      switch (optionIndex) {
        case 0:
          return "/home";
        case 1:
          return "/programs";
        case 2:
          return "/my-projects";
        case 3:
          return "/tracks";
        case 4:
          return "/forum";
        case 5:
          return "/adminpanel";
        case 6:
          return "/assistants";
      }
    },
  },
  props: ["option", "optionIndex", "lang", "optionSelected"],
  mixins: [translationMixin],
};
</script>
