<style>
@import "../../src/assets/css/track/track.css";
@import "../../src/assets/css/track/new_track.css";
@import "../../src/assets/css/confirmation.css";
@import "../../src/assets/css/track/templates.css";
</style>

<script setup>
import Template from "../components/track/TemplateComponent.vue";
</script>

<template>
  <Template
    @openAlert="openAlert($event)"
    :lang="lang"
    :role="role"
    :space="spaces[0]"
  />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
  methods: {
    openAlert(message) {
      this.$emit("openAlert", message);
    },
  },
};
</script>
