<template>
  <div class="padding-y-28" v-if="selectedTab === 1 || selectedTab === 2">
        <div
            class="chat-info-hover"
            v-show="showMessageHover"
        >
            {{ messageInfoHover }}
        </div>
        <span style="cursor: pointer;" @mouseenter="setMessageHover(true)"
            @mouseleave="setMessageHover(false)"
            ><span v-html="getMessage()"></span> <a style="display: inline-block;" @click="$emit('headerAction')">{{ langFilter("linkConfigAssistant") }}</a>
        </span>
</div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import DOMPurify from "dompurify";

export default {
    props: ["lang", "messageHeader", "linkHeader", "messageInfoHover", "selectedTab", "projectId"],
    emits: ["headerAction"],
    data() {
        return {
            showMessageHover: false,
            traducciones: [
                {
                    name: "linkConfigAssistant",
                    es: "haz clic aquí",
                    en: "click here",
                },
            ],
        }
    },
    created() {},
    methods: {
        setMessageHover(value) {
            this.showMessageHover = value;
        },
        getMessage() {
            return DOMPurify.sanitize(this.messageHeader);
        }
    },
    mixins: [translationMixin],
}
</script>