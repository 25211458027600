<template>
  <div class="part">
    <div class="row">
      <div class="col-2 newTemplate-add-div relative">
        <button
          @click="show = !show"
          :class="[
            { 'new-template-separator-opened': show },
            { 'new-template-separator-closed': !show },
            'new-templeate-close',
          ]"
        />
        <hover :content="langFilter('addPart')">
          <button
            @mouseover="showCard"
            @mouseout="hideCard"
            @click="addApart"
            class="new-btn-purple-add"
          />
        </hover>
      </div>
      <div class="col-9 new-template-data">
        <span class="new-template-nowrap"
          >{{ langFilter("apart") }} {{ apartIndex + 1 }}.*</span
        >
        <input
          @input="updateApart($event)"
          :class="[
            { 'new-template-error-input': checkedError && apart.title === '' },
            'new-template-input-part',
          ]"
          :placeholder="langFilter('sectionName') + '...'"
          type="text"
          :value="apart.title"
        />
      </div>
      <new-template-creation-options
        @openModalConfirmation="openModalConfirmation($event)"
        :index="apartIndex"
        :type="2"
        :lang="lang"
      />
    </div>

    <div v-show="show">
      <draggable
        v-model="
          this.$parent.$parent.$parent.$parent.themes[themeIndex].aparts[
            apartIndex
          ].questions
        "
        draggable=".task"
        handle=".dragg-me-task"
        animation="500"
        ghostClass="ghost"
        chosenClass="chosen"
        :options="{
          animation: 500,
          handle: '.dragg-me-task',
          ghostClass: 'ghost',
          chosenClass: 'chosen',
          group: 'task',
          itemKey: 'id',
        }"
      >
        <new-template-creation-question
          v-for="(question, questionIndex) in apart.questions"
          :key="questionIndex"
          @updateQuestion="updateQuestion($event)"
          @openModalConfirmation="openModalConfirmation($event)"
          @addQuestion="addQuestion($event)"
          @checkError="checkError($event)"
          :question="question"
          :questionIndex="questionIndex"
          :apartIndex="apartIndex"
          :themeIndex="themeIndex"
          :totalQuestions="apart.questions.length"
          :lang="lang"
          :checkedError="checkedError"
        />
      </draggable>
      <div class="col-12 newtemplate-container-div margin-plus-btn">
        <hover
          v-if="apart.questions.length === 0"
          :content="langFilter('addQuestion')"
        >
          <button
            @click="addQuestion([themeIndex, apartIndex, 0])"
            class="new-btn-purple-add"
          />
        </hover>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../../mixins/translationMixin.js";
import { VueDraggableNext } from "vue-draggable-next";
export default {
  props: ["apart", "themeIndex", "apartIndex", "lang", "checkedError"],
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      show: true,
      traducciones: [
        {
          name: "apart",
          es: "Apartado",
          en: "Part",
        },
        {
          name: "sectionName",
          es: "Escribir nombre del Apartado...",
          en: "Write the name of the Section...",
        },
        {
          name: "addPart",
          es: "Añadir Apartado",
          en: "Add Part",
        },
        {
          name: "addQuestion",
          es: "Añadir Pregunta",
          en: "Add Question",
        },
      ],
    };
  },
  methods: {
    showCard() {
      // follow the mouse
      const card = document.querySelector(".new-itinerary-card");
      card.style.display = "block";
    },
    checkError(err) {
      this.$emit("checkError", err);
    },
    hideCard() {
      const card = document.querySelector(".new-itinerary-card");
      card.style.display = "none";
    },
    addApart() {
      this.$emit("addApart");
    },
    updateApart(event) {
      this.checkError(false);
      this.$emit("updateApart", [event.target.value, this.apartIndex]);
    },
    addQuestion(event) {
      this.$emit("addQuestion", event);
    },
    updateQuestion(event) {
      event.push(this.apartIndex);
      this.$emit("updateQuestion", event);
    },
    openModalConfirmation(data) {
      data.push(this.apartIndex);
      this.$emit("openModalConfirmation", data);
    },
  },
  mixins: [translationMixin],
};
</script>
