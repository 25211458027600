<template>
  <div id="notification_previous_title">
    <span class="notifications-new-title">{{ langFilter("previous") }}</span>
    <a
      v-if="banner"
      id="viewallprevious"
      :href="getNotificationURL1()"
      style="margin-right: 27px; color: rgb(128, 92, 240); float: right"
      >{{ visibleview() }}</a
    >
    <notifications-table-notification
      @openModal="openModal($event)"
      @locateModal="locateModal($event)"
      :notifications="notifications"
      :lang="lang"
      :banner="banner"
      class="not-div-prev"
      :newer="false"
    ></notifications-table-notification>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  props: ["notifications", "lang", "banner"],
  data() {
    return {
      traducciones: [
        {
          name: "previous",
          es: "Anteriores",
          en: "Previous",
        },
        {
          name: "view",
          es: "Ver todo",
          en: "View all",
        },
      ],
    };
  },
  methods: {
    openModal(event) {
      let arr = [];
      arr.push(event);
      arr.push(0);
      this.$emit("openModal", arr);
    },
    locateModal(event) {
      this.$emit("locateModal", event);
    },
    getNotificationURL1() {
      let page = window.location.pathname;
      let pagename = page.split("/");
      return pagename[0] + "/notifications";
    },
    visibleview() {
      this.$nextTick(() => {
        if ($("#viewallnew").is(":visible")) {
          return null;
        } else {
          return this.langFilter("view");
        }
      });
    },
  },
  mixins: [translationMixin],
};
</script>
