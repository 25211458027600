<script setup>
import Register from "../components/auth/SignupComponent.vue";
</script>

<style>
@import "../../src/assets/css/auth/signup.css";
@import "../../src/assets/css/auth/login.css";
</style>

<template>
  <Register />
</template>

<script>
import Cookies from "js-cookie";
export default {
  created() {
    Cookies.remove("token");
  },
};
</script>
