<template>
  <div v-if="isVisibleExtensionModal" class="invalid-extension-modal">
    <generic-modal @close-confirmation="handleExtensionModal">
      <template #body>
        <div class="confirmation-modal-chat">
          <h4>Extensión no suportada</h4>
          <p class="generic-modal-content">
            {{ langFilter("extensionInvalid") }}
          </p>
          <div>
            <button @click="handleExtensionModal">
              {{ langFilter("buttonAccept") }}
            </button>
          </div>
        </div>
      </template>
    </generic-modal>
  </div>
  <div class="gpt-project-chat-container">
    <div v-if="loadingData" class="loading-data-container">
      <img :src="require('@/assets/img/general/carga.gif')" />
    </div>
    <div
      v-if="showConfirmationModal"
      class="confirmation-modal-chat-fade"
      @click="showConfirmationModal = false"
    />
    <div v-if="showConfirmationModal" class="confirmation-modal-chat">
      <h4>¿Estás seguro que quieres eliminar el contenido del chat?</h4>
      <h5>Esta acción no se puede deshacer</h5>
      <div>
        <button @click="showConfirmationModal = false">Cancelar</button>
        <button @click="confirmRemoveChat()">Confirmar</button>
      </div>
    </div>
    <div
      v-if="status !== 'not-assigned'"
      :class="[
        isDeletedStatus ? 'gpt-project-chat-disabled' : 'gpt-project-chat',
      ]"
    >
      <div ref="scrollContainerChat" class="gpt-help-div-content">
        <img
          v-if="!isCurrentQuestionTab && !hasMessagesDisabled"
          class="gpt-reload-logo"
          :src="Reload"
          alt="ReloadGpt"
          @click="showConfirmationModal = true"
        />

        <chat-bot-project-chat-messages
          v-if="hasMessagesDisabled && !isCurrentQuestionTab"
          :messages="messagesDisabled"
          :disabled="true"
          :question="question"
          :show-project-chat="showProjectChat"
          :already-asked="alreadyAsked"
          :show-question-chat="showQuestionChat"
          :assistant-project="assistantProject"
          :loading="loading"
          :status="status"
          @reload-action="openConfirmModal"
        />
        <chat-bot-project-chat-messages
          v-if="!isDeletedStatus"
          :messages="messagesEnabled"
          :disabled="false"
          :question="question"
          :show-project-chat="showProjectChat"
          :already-asked="alreadyAsked"
          :show-question-chat="showQuestionChat"
          :assistant-project="assistantProject"
          :loading="loading"
        />

        <div v-if="isLoadingOrAsking" class="bot-message-div row">
          <div class="loading-gpt">
            <img
              :src="require('@/assets/img/general/carga.gif')"
              alt="loading"
            />
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div v-for="(file, fileI) in files" :key="fileI" class="col">
          <div class="file-uploaded-gpt-div">
            <img :src="FileGptIcon" alt="FileGptIcon" />
            <a :href="file.url" target="_blank">
              <p>{{ file.title }}</p>
            </a>
            <h5 @click="removeFile(fileI)">x</h5>
          </div>
        </div>
      </div>
      -->
      <div v-if="!isDeletedStatus">
        <input
          ref="fileInput"
          style="display: none"
          type="file"
          multiple
          :accept="getFileExtensions()"
          @change="uploadFIle($event)"
        />
        <form class="gpt-project-input row" @submit.prevent="">
          <chat-bot-project-chat-attachment
            :lang="lang"
            :files="files"
            :can-change="true"
            @handle-convert-flag="handleConvertFlagInFile($event)"
            @remove-file="removeFile($event)"
          />
          <div class="row py-10">
            <div
              class="col-1 open-file-button"
              :class="{ disabled: isLoadingOrAsking }"
            >
              <img alt="file" :src="FileIcon" @click="openFile" />
            </div>
            <div class="col-10">
              <textarea
                v-model="prompt"
                :disabled="isLoadingOrAsking"
                type="text"
                placeholder="Escribe tu mensaje..."
                maxlength="4096"
                @keydown="handleKeyDown"
                @input="handleInput"
              />
            </div>
            <div class="col-1">
              <img
                v-if="loading"
                :src="Stop"
                alt="stop"
                @click="stopGpt($event)"
              />
              <img
                v-else
                class="send-gpt-button"
                :class="{ disabled: askingGpt }"
                :src="Send"
                alt="send"
                @click="sendGpt($event)"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DOMPurify from "dompurify";
import translationMixin from "../../mixins/translationMixin.js";
import Reload from "../solution/images/reload.png";
import Send from "./images/send.png";
import Copy from "./images/copy.png";
import Stop from "./images/stop.webp";
import FileGptIcon from "./images/file-gpt-icon.png";
import FileIcon from "./images/file.svg";
import { fileType } from "./model/fileType.js";

export default {
  mixins: [translationMixin],
  inject: ["scrollToBottomProject"],
  props: [
    "lang",
    "showQuestionChat",
    "showProjectChat",
    "assistantProject",
    "assistantMessages",
    "selectedTab",
    "preButtonSelected",
    "project_id",
    "status",
    "askingGpt",
    "question_id",
  ],
  emits: ["onShowProjectChat", "onGetProjectGptChat", "onGetQuestionGptChat"],
  data() {
    return {
      showConfirmationModal: false,
      Reload: Reload,
      Send: Send,
      FileGptIcon: FileGptIcon,
      FileIcon: FileIcon,
      Copy: Copy,
      Stop: Stop,
      files: [],
      question: null,
      expPrompt: "",
      expFiles: [],
      evalPrompt: "",
      evalFiles: [],
      loading: false,
      alreadyAsked: false,
      buttonSelected: 2,
      sendingMessage: "",
      messagesEnabled: null,
      messagesDisabled: null,
      hasMessagesDisabled: null,
      isVisibleExtensionModal: false,
      loadingData: false,
      prompt: "",
      traducciones: [
        {
          name: "extensionInvalid",
          es: "Tipo de archivo invalido. Solo se permite las extensiones .c, .cs, .cpp, .csv, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .sh, .ts",
          en: "Invalid file type. Only the extensions .c, .cs, .cpp, .csv, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, are allowed. .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .sh, .ts",
        },
        {
          name: "buttonAccept",
          es: "Aceptar",
          en: "Acept",
        },
      ],
    };
  },
  computed: {
    processedResponse() {
      return (response) => {
        if (!response) return "";
        const sanitizedContent = DOMPurify.sanitize(response);
        const regex = /<\/?(h1|h2|h3|h4|h5)>/gi;
        return sanitizedContent.replace(regex, (match) => {
          return match.replace(/h1|h2|h3|h4|h5/, "h6");
        });
      };
    },
    isDeletedStatus() {
      return this.status === "deleted";
    },
    isLoadingOrAsking() {
      return this.loading || this.askingGpt;
    },
    isCurrentQuestionTab() {
      return this.preButtonSelected === 1;
    },
  },
  watch: {
    selectedTab() {
      this.messagesDisabled =
        this.assistantMessages?.filter(
          (message) => message.id !== this.assistantProject?.id,
        ) ?? this.assistantMessages;
      this.hasMessagesDisabled = this.messagesDisabled.length > 0;
      this.messagesEnabled =
        this.assistantMessages?.filter(
          (message) => message.id === this.assistantProject?.id,
        ) ?? [];
      if (this.selectedTab == 2) {
        this.expFiles = this.files;
        this.files = this.evalFiles;

        this.expPrompt = this.prompt ?? "";
        this.prompt = this.evalPrompt;
      } else {
        this.evalFiles = this.files;
        this.files = this.expFiles;

        this.evalPrompt = this.prompt ?? "";
        this.prompt = this.expPrompt;
      }
    },
    assistantMessages() {
      this.$nextTick(() => {
        this.messagesDisabled =
          this.assistantMessages?.filter(
            (message) => message.id !== this.assistantProject?.id,
          ) ?? this.assistantMessages;
        this.hasMessagesDisabled = this.messagesDisabled.length > 0;
        this.messagesEnabled =
          this.assistantMessages?.filter(
            (message) => message.id === this.assistantProject?.id,
          ) ?? [];
      });

      this.scrollToBottom();
    },
  },
  mounted() {
    if (this.preButtonSelected === 1) {
      this.$emit("onGetProjectGptChat");
    }
    this.$nextTick(() => {
      this.messagesDisabled =
        this.assistantMessages?.filter(
          (message) => message.id !== this.assistantProject?.id,
        ) ?? this.assistantMessages;
      this.hasMessagesDisabled = this.messagesDisabled?.length > 0;
      this.messagesEnabled =
        this.assistantMessages?.filter(
          (message) => message.id === this.assistantProject?.id,
        ) ?? [];
    });

    this.scrollToBottom();
  },
  methods: {
    handleExtensionModal() {
      this.isVisibleExtensionModal = false;
    },
    openConfirmModal() {
      this.showConfirmationModal = true;
    },
    confirmRemoveChat() {
      this.showConfirmationModal = false;
      this.removeChat();
    },
    removeChat() {
      let type = this.preButtonSelected
        ? this.preButtonSelected
        : this.selectedTab > 0
          ? this.selectedTab
          : 1;

      // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
      if (this.question_id) {
        type = 1;
      }

      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/removeProjectChatGpt/${this.project_id}/${type}`,
        )
        .then(() => {
          this.messagesEnabled = [];
        })
        .catch((error) => {
          console.error(error);
          alert("Error eliminando el chat del Asistente");
        });
    },
    getFilePath(file_name, file_path) {
      return `${process.env.VUE_APP_API_STORAGE}/storage/${file_path}`;
    },
    openFile() {
      this.$refs.fileInput.click();
    },
    sendGpt(e) {
      e.preventDefault();
      const maxFiles = 5;
      const maxSize = 8388608;
      let totalSize = 0;

      this.files.forEach((file) => {
        totalSize += file.file.size;
      });

      if (this.files.length > maxFiles) {
        alert(`You can only upload a maximum of ${maxFiles} files.`);
        return;
      }

      if (totalSize > maxSize) {
        alert(
          `Total file size should not exceed ${maxSize / (1024 * 1024)} MB.`,
        );
        return;
      }

      if (this.files.length === 0 && !this.prompt.trim()) return;

      if (this.files.length > 0) {
        const forbiddenTypes = [
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];

        for (let file of this.files) {
          if (forbiddenTypes.includes(file.type)) {
            alert("PowerPoint files are not allowed");
            return;
          }
        }
      }

      this.loading = true;
      const prompt = this.prompt;
      this.sendingMessage = prompt;
      this.prompt = "";
      this.scrollToBottom();

      let formData = new FormData();
      formData.append(
        "project_id",
        this.project_id === undefined ? null : this.project_id,
      );

      this.files.forEach((file, index) => {
        formData.append("files[" + index + "]", file.file);
        formData.append(`filesToConvert[${index}]`, !!file.convert);
      });

      let type = this.preButtonSelected
        ? this.preButtonSelected
        : this.selectedTab > 0
          ? this.selectedTab
          : 1;

      if (this.question_id) {
        type = 1;
      }

      formData.append("assistant_type", type);

      formData.append("message", prompt);
      formData.append(
        "question_id",
        this.question_id === undefined ? null : this.question_id,
      );
      formData.append(
        "message_id",
        this.message_id === 0 ? null : this.message_id,
      );

      if (this.files.length > 0) {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/assistant/uploadFile`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then(async (res) => {
            this.$refs.fileInput.value = "";
            if (this.question) {
              this.previousUserMessages.push(prompt);
              const response = res.data.message;
              this.previousBotMessages.push(response);
            } else {
              this.$emit("onGetProjectGptChat");
            }
            this.scrollToBottom();
          })
          .catch((error) => {
            alert("Error enviando mensaje al Asistente");
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        let type = this.preButtonSelected
          ? this.preButtonSelected
          : this.selectedTab > 0
            ? this.selectedTab
            : 1;

        // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
        let question_id = this.question_id ? this.question_id : null;
        if (this.question_id) {
          if (type == 2) {
            question_id = null;
          }
          type = 1;
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
            {
              project_id: this.project_id,
              message: prompt,
              question_id: question_id,
              assistant_type: type,
            },
          )
          .then(async () => {
            this.$emit("onGetProjectGptChat");
            this.loading = false;
            this.scrollToBottom();
          })
          .catch((error) => {
            alert("Error enviando mensaje al Asistente");
            console.error(error);
            this.loading = false;
          });
      }
      this.files = [];
    },
    stopGpt(e) {
      e.preventDefault();
      this.loading = false;
      this.sendingMessage = "";
      this.$emit("onShowProjectChat", true);
      this.files = [];
      this.prompt = "";
      this.scrollToBottom();
    },
    handleKeyDown(event) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          // Increase the size of the textarea
          event.target.style.height = `${event.target.scrollHeight + 20}px`;
        } else {
          // Prevent the default action (inserting a newline)
          event.preventDefault();
          // Call your function to send the form
          event.target.style.height = "25px";
          this.sendGpt(event);
        }
      }
    },
    updateFiles(newFiles = []) {
      const existingFilesNames = new Set(
        this.files.map(({ file_name: name }) => name),
      );

      const updatedFiles = newFiles.filter(
        ({ file_name: name }) => !existingFilesNames.has(name),
      );

      this.files = [...this.files, ...updatedFiles];
    },
    handleConvertFlagInFile({ index, checked }) {
      this.files[index].convert = checked;
    },
    uploadFIle(e) {
      const files = Array.from(e.target.files);

      const hasInvalidExtension = files.some(
        ({ name }) => !fileType.isValidExtension(fileType.getExtension(name)),
      );

      if (hasInvalidExtension) {
        this.isVisibleExtensionModal = true;
        return;
      }

      const newFiles = files.map((file) => ({
        file_name: file.name,
        file_path: undefined,
        file_type: file.type,
        file,
        deleted: 0,
        convert: false,
      }));

      this.updateFiles(newFiles);
      this.$refs.fileInput.value = "";
    },
    removeFile(index) {
      this.files = this.files.filter((_, i) => i !== index);
    },
    scrollToBottom() {
      this.loadingData = true;
      if (!this.$refs.scrollContainerChat) {
        return;
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scrollContainerChat.scrollTop =
            this.$refs.scrollContainerChat?.scrollHeight;

          this.loadingData = false;
        }, 500);
      });
    },
    getFileExtensions() {
      return fileType.extensions;
    },
  },
};
</script>

<style scoped>
.open-file-button.disabled {
  cursor: default;
  pointer-events: none;
}

.send-gpt-button.disabled {
  cursor: default;
  pointer-events: none;
}
</style>
