<template>
  <div class="contenidoFeedback">
    <div class="content">
      <div class="contactImage">
        <img src="/img/feedback/estupendo.svg" />
      </div>
      <h1 class="contactTitle faqs-h-bold">
        {{
          langFilter(type === "feedback" ? "feedback-title" : "title-estupendo")
        }}
      </h1>
      <div class="contactText">
        <p v-if="type === 'feedback'">
          {{ langFilter("feedback-message") }}
        </p>
        <p>
          {{
            langFilter(
              type === "feedback" ? "feedback-response" : "thanks-for-getting"
            )
          }}<br />
          {{ langFilter("you-will-receive") }}
        </p>
        <p>
          {{ langFilter("other-message") }}<br />
          {{ langFilter("go-to-the") }}
          <span @click="returnToForm" class="faqs-redirect"
            >{{ langFilter("help-center") }}.</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";

export default {
  props: ["lang", "type"],
  data() {
    return {
      traducciones: [
        {
          name: "title-estupendo",
          es: "¡Estupendo!",
          en: "Wonderful!",
        },
        {
          name: "thanks-for-getting",
          es: "Hemos recibido tu mensaje y lo revisaremos pronto.",
          en: "We have received your message and will review it soon.",
        },
        {
          name: "you-will-receive",
          es: "Nuestro equipo se pondrá en contacto contigo a la brevedad.",
          en: "Our team will get in touch with you shortly.",
        },
        {
          name: "other-message",
          es: "Si lo deseas, puedes",
          en: "If you wish, you can",
        },
        {
          name: "go-to-the",
          es: "volver a",
          en: "go back to",
        },
        {
          name: "help-center",
          es: "enviar otro mensaje",
          en: "send another message",
        },
        // Mensajes para feedback
        {
          name: "feedback-title",
          es: "¡Gracias por tu feedback!",
          en: "Thank you for your feedback!",
        },
        {
          name: "feedback-message",
          es: "Agradecemos tu tiempo y esfuerzo en mejorar nuestro servicio.",
          en: "We appreciate your time and effort in improving our service.",
        },
        {
          name: "feedback-response",
          es: "Tu feedback ha sido registrado y nos ayudará a ofrecer un mejor servicio.",
          en: "Your feedback has been recorded and will help us provide better service.",
        },
      ],
    };
  },

  methods: {
    returnToForm() {
      this.$emit("return-to-form");
    },
  },

  mixins: [translationMixin],
};
</script>
