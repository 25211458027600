<template>
  <div
    class="mb-4 me-5 assistant-card"
    :class="{ disabled: !canAccessToAssistant }"
  >
    <div class="d-flex flex-column mx-4 my-3">
      <div v-if="canAccessToAssistant" class="dropdown ms-auto">
        <img
          :id="'dropdownMenuButton-' + assistant.id"
          class="pointer dropdown-toggle py-2 px-2"
          :src="require('@/assets/img/itinerario/options.svg')"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <ul
          class="dropdown-menu"
          :aria-labelledby="'dropdownMenuButton-' + assistant.id"
        >
          <li>
            <a class="dropdown-item" @click="editAssistant">
              <img
                class="me-2"
                :src="require('@/assets/img/projects/edit.svg')"
              />
              {{ langFilter("configure") }}
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              :data-bs-toggle="'modal'"
              :data-bs-target="'#deleteModal-' + assistant.id"
            >
              <img
                class="me-2"
                :src="require('@/assets/img/projects/delete.svg')"
              />
              {{ langFilter("delete") }}
            </a>
          </li>
        </ul>
      </div>
      <div class="pointer d-flex flex-column" @click="viewDetails()">
        <div class="d-flex align-items-center">
          <img
            class="me-4"
            width="30"
            src="/img/icons/icon-robot-wink.svg"
            alt="Robot Wink"
          />
          <div class="fs-5 fw-bold text-truncate" style="color: #2a3b5a">
            {{ assistant.name }}
          </div>
        </div>

        <p class="description">{{ assistant.description }}</p>

        <hr />
        <div>
          <div>
            <span>&#8226;</span> {{ langFilter("projects") }}:
            <strong>{{ assistant.projects_count }}</strong>
            <br />
          </div>
          <div>
            <span>&#8226;</span> {{ langFilter("templates") }}:
            <strong>{{ assistant.templates_count }}</strong>
            <br />
          </div>
          <div v-if="!isGeneralAssistant">
            <span>&#8226;</span> {{ langFilter("files") }}:
            <strong>{{ assistant.files_count }}</strong>
          </div>
        </div>
        <div v-if="canAccessToAssistant" class="ms-auto text-muted pointer">
          {{ langFilter("see_more") }}
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para eliminar -->
  <div
    :id="'deleteModal-' + assistant.id"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="'deleteModalLabel-' + assistant.id"
    aria-hidden="true"
  >
    <ModalAssistant
      :assistant="assistant"
      :lang="lang"
      :title="getModalTextsTranslated.title"
      :body="getModalTextsTranslated.body"
      :loading="loading"
      :confirm-text="langFilter('confirm_delete')"
      @confirm="confirmDeleteAssistant"
    />
  </div>
</template>

<script>
import axios from "axios";
import { defineAsyncComponent } from "vue";
import translationMixin from "@/mixins/translationMixin";
import translations from "./translate/AssistantTranslate.json";

export default {
  name: "AssistantCard",
  components: {
    ModalAssistant: defineAsyncComponent(
      () => import("@/components/modal/ModalAssistant.vue"),
    ),
  },
  mixins: [translationMixin],
  props: ["assistant", "lang"],
  data() {
    return {
      loading: false,
      traducciones: translations,
    };
  },
  computed: {
    isGeneralAssistant() {
      return this.assistant.general;
    },
    canAccessToAssistant() {
      const { owner_info: { is_current_user: isOwnerCurrentUser } = {} } =
        this.assistant;

      return isOwnerCurrentUser && !this.isGeneralAssistant;
    },
    getModalTextsTranslated() {
      const title = this.langFilter("confirm_delete_assistant");
      const body = this.langFilter("delete_assistant_warning");
      return { title, body };
    },
  },
  methods: {
    editAssistant() {
      this.$router.push({
        name: "EditAssistant",
        params: { id: this.assistant.id },
      });
    },
    confirmDeleteAssistant() {
      this.loading = true;

      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/assistants/${this.assistant.id}`,
        )
        .then((response) => {
          console.log("Asistente eliminado:", response.data);
          window.location.reload();
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al eliminar el asistente:", error);
        });
    },
    viewDetails(tab) {
      if (!this.canAccessToAssistant) return;

      const query = tab ? { tab } : {};
      this.$router.push({
        name: "AssistantDetails",
        params: { id: this.assistant.id },
        query: query,
      });
    },
  },
};
</script>
