<template>
  <ul class="list-group mt-3 mx-3">
    <li
      v-for="(file, index) in files"
      :key="index"
      :class="[
        'd-flex justify-content-between align-items-center px-5 py-2 my-1 ',
        {
          'bg-hover-file shadow-sm': hoverFile === index,
        },
      ]"
      @mouseover="hoverFile = index"
      @mouseleave="hoverFile = null"
    >
      <div>
        <img src="../../assets/img/track/file-assistant.svg" class="pb-1" />
        {{ file.file_name }}
      </div>
      <img
        width="20"
        @click="downloadFile(index)"
        class="pointer"
        src="/img/itinerario/download.svg"
      />
    </li>
  </ul>
</template>

<script>
import axios from "axios";

export default {
  name: "AssitantFilesList",
  props: ["files", "lang"],
  data() {
    return {
      hoverFile: null,
    };
  },
  methods: {
    async downloadFile(index) {
      try {
        const file = this.files[index];
        const fileUrl = `${process.env.VUE_APP_API_URL}/assistants/${file.gpt_assistant_id}/files/${file.id}`;

        const response = await axios.get(fileUrl, {
          responseType: "blob",
        });

        const fileName = file.file_name;

        const url = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },
  },
};
</script>
