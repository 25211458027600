<template>
  <div v-if="itinerary[0] != undefined">
    <div v-if="resources.length != 0 || resourcesStat.length != 0">
      <div
        class="template-body-modal"
        v-for="(stat, statIndex) in itinerariesWithResources"
        :key="statIndex"
      >
        <div
          @click="openCloseSol(stat[0].station_id)"
          class="track-it-div-template-chapter"
        >
          <img
            :id="`arrow-it-res-${stat[0].station_id}`"
            class="arrow-modal-template"
            :src="require('@/assets/img/track/template-arrow-right-modal.svg')"
            alt="arrow"
          />
          <span class="tema-modal-track"
            >{{ langFilter("chapter") }} {{ statIndex + 1 }}.
          </span>
          <b>{{ stat[0].station }}</b>
        </div>
        <div
          :class="`template-rec-div res-stations-${stat[0].station_id}`"
          v-for="(resource, resourcesIndex) in resources"
          :key="resourcesIndex"
        >
          <div
            v-if="
              resource.station_id == stat[0].station_id &&
              (resource.solution_id == undefined ||
                resource.solution_id == null)
            "
            @click="openResource(resource.link)"
            :class="`hide track-rec-div-template res-stations-${stat[0].station_id}`"
          >
            <img
              :class="recTrackIcon(resource.link)"
              :src="getResourceIcon(resource.link)"
              alt="rec_icon"
            />
            {{ resource.resource }}
          </div>
        </div>
        <div
          :class="`hide track-it-apt-div res-stations-from-${stat[0].station_id}`"
          v-for="(sol, solIndex) in itinerary[statIndex]"
          :key="solIndex"
        >
          <div
            class="track-it-apt-div-hover-template"
            @click="openCloseResources(sol.solution_id)"
          >
            <img
              :id="`arrow-sol-res-${sol.solution_id}`"
              class="arrow-modal-template"
              :src="
                require('@/assets/img/track/template-arrow-right-modal.svg')
              "
              alt="arrow"
              v-if="checkIfSolutionHasResources(sol.solution_id)"
            />
            <span class="tema-modal-track"
              >{{ langFilter("part") }} {{ solIndex + 1 }}.
            </span>
            <b>{{ sol.solution }}</b>
          </div>
          <div
            :class="`template-rec-div resources-from-${sol.solution_id}`"
            v-for="(resource, resourcesIndex) in resources"
            :key="resourcesIndex"
          >
            <div
              v-if="resource.solution_id == sol.solution_id"
              @click="openResource(resource.link)"
              :class="`hide track-rec-div-template res-resources-from-${sol.solution_id}`"
            >
              <img
                :class="recTrackIcon(resource.link)"
                :src="getResourceIcon(resource.link)"
                alt="rec_icon"
              />
              {{ resource.resource }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-left: 44px" class="data-track-name" v-else>
      {{ langFilter("noRec") }}
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import generalMethodsMixin from "../../mixins/generalMethodsMixin.js";
import $ from "jquery";
import arrowRight from "../../assets/img/track/template-arrow-right-modal.svg";
import arrowDown from "../..//assets/img/track/template-arrow-down-modal.svg";
export default {
  props: [
    "lang",
    "resources",
    "resourcesStat",
    "trackStatSol",
    "track",
    "itinerary",
    "questions",
  ],
  data() {
    return {
      itinerariesWithResources: [],
      itinerariesFilered: [],
      solutionsDef: [],
      showOthers: false,
      arrowRight: arrowRight,
      arrowDown: arrowDown,
      // traduccion
      traducciones: [
        {
          name: "select",
          es: "Seleccionar Track",
          en: "Select Track",
        },
        {
          name: "noRec",
          es: "No hay recursos asociados",
          en: "No resources associated",
        },
        {
          name: "chapter",
          es: "Tema",
          en: "Chapter",
        },
        {
          name: "part",
          es: "Apartado",
          en: "Part",
        },
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
        {
          name: "others",
          es: "Otros recursos",
          en: "Other resources",
        },
      ],
    };
  },
  created() {
    this.itineraries = this.trackStatSol.filter(
      (item) => item.track_id == this.track.id
    );
  },
  watch: {
    resources: function () {
      this.solutionsDef = [];
      let mySolutionsDef = [];
      // si las partes tienen recursos, se añade la propiedad partHasResources a true, y lo mismo con los chapters
      this.itinerariesWithResources = this.itinerary;
      // add property hasResources to each itinerary if the station has resources
      this.itinerariesWithResources.forEach((itinerary) => {
        itinerary.chapterHasResources = false;
        itinerary.forEach((element) => {
          element.partHasResources = false;
          this.resources.forEach((resource) => {
            if (element.solution_id == resource.solution_id) {
              mySolutionsDef.push(element.solution_id);
              element.partHasResources = true;
              itinerary.chapterHasResources = true;
            }
          });
        });
      });
      this.solutionsDef = mySolutionsDef;
    },
  },
  methods: {
    checkIfSolutionHasResources(id) {
      return this.solutionsDef.includes(id);
    },
    // abre el recurso en una nueva pestaña
    openResource(link) {
      //   if (link.includes("/resources/")) {
      //     window.open(process.env.VUE_APP_API_STORAGE + link, "_blank");
      //   } else {
      //     const valid = this.getValidUrl(link);
      //     window.open(valid, "_blank");
      //   }
      window.open(link, "_blank");
    },

    //añade una clase a los recursos que son de youtube
    recTrackIcon(link) {
      let icon = "rec-track-icon-template";
      if (link.includes("youtu")) {
        icon = "rec-link-icon-template";
      }
      return icon;
    },

    // añade un icono u otro dependiendo del tipo de recurso
    getResourceIcon(link) {
      let icon = "/img/resources/file_icon.svg";
      if (link.includes("youtu")) {
        icon = "/img/resources/link_icon.svg";
      } else if (link.includes("mp4")) {
        icon = "/img/resources/video_icon.svg";
      }
      return icon;
    },

    // abre o cierra los recursos de una estación
    openCloseSol(id) {
      if ($(".res-stations-from-" + id).is(":visible")) {
        $(".res-stations-" + id).hide();
        $(".res-stations-from-" + id).hide();
        $("#arrow-it-res-" + id).attr(
          "src",
          "/img/track/template-arrow-right-modal.svg"
        );
      } else {
        $(".res-stations-from-" + id).show();
        $(".res-stations-" + id).show();
        $("#arrow-it-res-" + id).attr(
          "src",
          "/img/track/template-arrow-down-modal.svg"
        );
      }
    },

    // abre o cierra los recursos de una parte
    openCloseResources(id) {
      if ($(".res-resources-from-" + id).is(":visible")) {
        $(".res-resources-from-" + id).hide();
        $("#arrow-sol-res-" + id).attr(
          "src",
          "/img/track/template-arrow-right-modal.svg"
        );
      } else {
        $(".res-resources-from-" + id).show();
        $("#arrow-sol-res-" + id).attr(
          "src",
          "/img/track/template-arrow-down-modal.svg"
        );
      }
    },
  },
  mixins: [translationMixin, generalMethodsMixin],
};
</script>
