<template>
  <div id="notifications_div_header" class="notifications-div-header">
    <img
      v-if="notifications.length != 0"
      @click="
        locateModal($event, banner);
        openModalNotifications(0);
      "
      id="notImg"
      class="notifications-points padding-dot-notifications"
      :src="require('@/assets/img/notifications/points.svg')"
      alt="Options"
    />
    <span class="notifications-title">{{ langFilter("notifications") }}</span>
    <br />
    <button
      @click="showNotification(0)"
      style="margin-right: 5px"
      class="notifications-btn notifications-btn-new"
      :class="{
        'all-btn': this.$parent.changeNotification == 0,
        'unread-btn': this.$parent.changeNotification == 1,
      }"
    >
      {{ langFilter("all") }}
    </button>
    <button
      @click="showNotification(1)"
      class="notifications-btn notification-btn-unread"
      :class="{
        'all-btn': this.$parent.changeNotification == 1,
        'unread-btn': this.$parent.changeNotification == 0,
        'hover-not-allowed': this.newNotifications.length == 0,
      }"
    >
      {{ langFilter("unread") }}
    </button>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import notificationsMixin from "../../mixins/notificationsMixin.js";
export default {
  props: ["newNotifications", "lang", "_token", "banner", "space_id"],
  data() {
    return {
      changeNotification: 0,
      notifications: [],
      traducciones: [
        {
          name: "notifications",
          es: "Notificaciones",
          en: "Notifications",
        },
        {
          name: "all",
          es: "Todo",
          en: "All",
        },
        {
          name: "unread",
          es: "No leídos",
          en: "Unread",
        },
      ],
    };
  },
  methods: {
    showNotification(type) {
      if (this.newNotifications.length > 0) {
        this.$emit("changeNot", type);
      }
    },
  },
  created() {
    this.getNotifications(0, 1);
  },
  mixins: [translationMixin, notificationsMixin],
};
</script>
