<template>
  <div class="container">
    <template v-if="formSent">
      <MessageSentComponent
        :lang="lang"
        type="support"
        @return-to-form="handleReturnToForm"
      />
    </template>
    <template v-else>
      <p>
        {{ langFilter("textSupport") }}
        <br />
      </p>
      {{ langFilter("textSupport2") }}
      <p>
        <a href="mailto:support@acceleralia.com">support@acceleralia.com</a>
      </p>
      <p>
        {{ langFilter("formOption") }}
      </p>
      <FormSupport
        @submit="handleFormSubmit"
        :lang="lang"
        :email="email"
        :name="name"
      />
    </template>
  </div>
</template>

<script>
import FormSupport from "./FormSupportComponent.vue";
import translationMixin from "@/mixins/translationMixin";
import MessageSentComponent from "./MessageSentComponent.vue";
import axios from "axios";

export default {
  components: {
    FormSupport,
    MessageSentComponent,
  },
  props: {
    lang: String,
    email: String,
    name: String,
  },
  data() {
    return {
      formSent: false,
      traducciones: [
        {
          name: "textSupport",
          es: "Nuestro equipo de soporte está listo para ayudarte y resolver cualquier inconveniente lo antes posible.",
          en: "Our support team is ready to help you and resolve any issues as quickly as possible.",
        },
        {
          name: "textSupport2",
          es: "Si experimentas algún problema o incidencia técnica, contáctanos a: ",
          en: "If you experience any technical issues or incidents, contact us at: ",
        },
        {
          name: "formOption",
          es: "Otra opción que te proponemos es que completes el formulario de contacto:",
          en: "Another option we suggest is to complete the contact form:",
        },
      ],
    };
  },
  methods: {
    handleFormSubmit({ message }) {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/customer-support-mail/send-email`,
          {
            message: message,
            email: this.email,
            name: this.name,
            type: "support",
          }
        )
        .then(() => {
          console.log("Email enviado con exito");
          this.formSent = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleReturnToForm() {
      this.formSent = false;
    },
  },
  mixins: [translationMixin],
};
</script>
