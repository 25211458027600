<template>
  <div v-if="error" class="error-margin-top">
    <Error
      title="permiso-title"
      body="permiso-body"
      link="link-redirect"
      image="`<img class='cohete-espacio' id='cohete' src='/img/errors/no_autorizado.svg'>`"
      vector="/img/errors/vector_158.svg"
      vamos="permiso-volver"
      header="true"
      :lang="lang"
    />
  </div>
  <div v-if="loading">
    <img
      class="loading-rocket"
      :src="require('@/assets/img/general/loading.gif')"
    />
  </div>
  <div v-if="assistant" class="mx-5 px-5 my-4">
    <div class="d-flex">
      <div class="cursor-pointer" @click="goBack">
        <img :src="require('@/assets/img/general/new_back.svg')" alt="Back" />
      </div>
      <header-general
        :title="`${assistant?.name} - ${langFilter(
          assistant?.assistant_type.name,
        )}`"
        src="/img/icons/icon-robot-wink.svg"
        class="ms-3"
      />
    </div>
    <p>{{ assistant?.description }}</p>

    <!-- Tabs -->
    <div class="d-flex mt-5">
      <div
        class="px-3 mx-2 py-2 pointer"
        :class="{ 'active-tab': activeTab === 'projects' }"
        @click="activeTab = 'projects'"
      >
        {{ langFilter("projects") }}
      </div>

      <svg
        width="2"
        viewBox="0 0 2 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="viewSelector-separator"
      >
        <path d="M1 32V1" stroke="#6C7792" stroke-linecap="round"></path>
      </svg>

      <div
        class="px-3 mx-2 py-2 pointer"
        :class="{ 'active-tab': activeTab === 'templates' }"
        @click="activeTab = 'templates'"
      >
        {{ langFilter("templates") }}
      </div>

      <svg
        width="2"
        viewBox="0 0 2 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="viewSelector-separator"
      >
        <path d="M1 32V1" stroke="#6C7792" stroke-linecap="round"></path>
      </svg>

      <div
        class="px-3 mx-2 py-2 pointer"
        :class="{ 'active-tab': activeTab === 'files' }"
        @click="activeTab = 'files'"
      >
        {{ langFilter("files") }}
      </div>
    </div>

    <!-- Tabs content -->
    <div v-if="activeTab === 'projects'" class="mt-5">
      <AssistantProjectsList
        v-if="assistant?.projects?.length > 0"
        :lang="lang"
        :projectsList="assistant.projects"
      />
      <p v-else class="mt-5">
        {{ langFilter("assistant_not_in_any_project") }}
      </p>
    </div>

    <div v-if="activeTab === 'templates'">
      <AssistantTemplatesList
        v-if="assistant?.templates?.length > 0"
        :lang="lang"
        :listTemplates="assistant.templates"
      />
      <p v-else class="mt-5">
        {{ langFilter("assistant_not_in_any_template") }}
      </p>
    </div>

    <div v-if="activeTab === 'files'" class="mt-5">
      <AssistantFilesList
        v-if="assistant?.files?.length > 0"
        :lang="lang"
        :files="assistant.files"
      />
      <p v-else class="mt-5">
        {{ langFilter("assistant_no_files") }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AssistantProjectsList from "./AssistantProjectsList.vue";
import AssistantTemplatesList from "./AssistantTemplatesList.vue";
import AssistantFilesList from "./AssistantFilesList.vue";
import translationMixin from "@/mixins/translationMixin";
import translations from "./translate/AssistantTranslate.json";

export default {
  name: "AssistantDetails",
  components: {
    AssistantProjectsList,
    AssistantTemplatesList,
    AssistantFilesList,
  },
  mixins: [translationMixin],
  props: ["lang", "homeNotifications", "spaces", "role"],
  emits: ["openAlert"],
  data() {
    return {
      loading: false,
      assistant: null,
      activeTab: this.$route.query.tab || "projects",
      traducciones: translations,
      error: false,
    };
  },
  mounted() {
    this.fetchAssistantDetails();
  },
  methods: {
    fetchAssistantDetails() {
      this.loading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/assistants/${this.$route.params.id}`,
        )
        .then((response) => {
          this.assistant = response.data;
        })
        .catch((error) => {
          this.error = true;
          console.error("Error al obtener detalles del asistente:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/assistants/assistants.css";
</style>
