<template>
  <div>
    <div class="opened-sidebar" v-show="!$parent.closed">
      <router-link to="/home">
        <sidebar-space
          @space="changeSpace($event)"
          v-for="(space, spaceIndex) in spaces"
          :key="spaceIndex"
          :space="space"
          :spaceSelecteds="spaceSelected"
          :spaceIndex="spaceIndex"
        />
      </router-link>
    </div>
    <div class="closed-sidebar" v-show="$parent.closed">
      <sidebar-space
        @space="changeSpace($event)"
        :space="spaces[0]"
        :spaceSelecteds="spaceSelected"
        :spaceIndex="0"
      />

      <sidebar-closed-option
        v-for="(option, optionIndex) in $parent.options"
        :key="optionIndex"
        :option="option"
        :optionIndex="optionIndex"
        :lang="lang"
        :optionSelected="getOptionSelected(optionIndex)"
        :class="[
          {
            'sidebar-background-selected': getOptionSelected(optionIndex),
          },
          'sidebar-closed-container-options',
        ]"
      />

      <div v-if="spaceSelecteds.role == 1">
        <sidebar-closed-option
          v-for="option in $parent.optionsAdmin"
          :key="option.section_number"
          :option="option"
          :optionIndex="option.section_number"
          :lang="lang"
          :optionSelected="getOptionSelected(option.section_number)"
          :class="[
            {
              'sidebar-background-selected': getOptionSelected(
                option.section_number
              ),
            },
            'sidebar-closed-container-options',
          ]"
        />
      </div>

      <div class="sidebar-footer" style="width: 0px">
        <div class="sidebar-background-container">
          <router-link to="/faqs">
            <div
              @click="handleClick(50)"
              class="closed-sidebar-background-selected-footer"
              :class="{
                'sidebar-background-selected': 50 == page,
              }"
            >
              <div
                v-show="50 == page"
                class="sidebar-option-content"
                style="width: auto"
              >
                <img
                  :src="require('@/assets/img/sidebar/hovers/icon-help.svg')"
                  class="sidebar-option-image"
                />
              </div>

              <div
                v-show="50 !== page"
                class="sidebar-option-content"
                style="width: auto"
              >
                <img
                  :src="require('@/assets/img/sidebar/logos/icon-help.svg')"
                  class="sidebar-option-image"
                />

                <div
                  id="sidebar-mouse-over-closed-5"
                  style="right: -5px"
                  class="sidebar-mouse-over"
                ></div>
              </div>

              <div class="sidebar-option-popup">
                <p>{{ langFilter("help") }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: ["spaces", "spaceSelecteds", "page", "lang"],
  data() {
    return {
      spaceSelected: this.spaceSelecteds,
      traducciones: [
        {
          name: "help",
          es: "¿Ayuda?",
          en: "Need help?",
        },
      ],
    };
  },
  methods: {
    handleClick(id) {
      this.showAdminPanel = false;
      this.$emit("section_number", id);
    },

    changeSpace(i) {
      this.spaces.unshift(this.spaces.splice(i, 1)[0]);
      this.spaceSelected = this.spaces[0];
      this.$parent.spaceSelected = this.spaces[0];
      axios.patch(`${process.env.VUE_APP_API_URL}/space/select`, {
        space: this.spaces[0].id,
      });
      this.$emit("space", this.spaces[0].id);
      //reload router to update the sidebar
      this.$router.push({ path: "/home" });
    },
    getOptionSelected(optionIndex) {
      if (
        optionIndex == 2 &&
        (this.page == 102 || this.page == 101 || this.page == 105)
      ) {
        return true;
      }
      return optionIndex == this.page;
    },
  },
  mixins: [translationMixin],
};
</script>
