<style lang="scss">
@import "../../../src/assets/css/notification/notification.css";
</style>
<template>
  <div>
    <div v-if="!banner">
      <header-general
        :title="langFilter('notifications')"
        :src="require('@/assets/img/home/home.svg')"
      />
    </div>
    <notifications-modal
      @deleteNotification="deleteNotification($event)"
      @markAsRead="markAsRead($event)"
      @markAllAsRead="markAllAsRead($event)"
      :showModal="showModal"
      :lang="lang"
      :banner="banner"
      :locatedModal="locatedModal"
    />
    <notifications-table
      ref="notTable"
      @openModal="openModal($event)"
      @locateModal="locateModal($event)"
      :deleteNotifications="deleteNotifications"
      :markAsReads="markAsReads"
      :markAllAsReads="markAllAsReads"
      :notifications="notifications"
      :banner="banner"
      :lang="lang"
      :space_id="space"
    />
    <div
      v-if="showModal"
      id="invisible_modal"
      style="position: fixed; width: 100%; height: 100%; z-index: 998; top: 0"
      @click="hidemodals()"
    />
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "banner", "space"],
  data() {
    return {
      newNotifications: "",
      previousEvent: "",
      selectedNotificationId: 999,
      deleteNotifications: 0,
      markAsReads: 0,
      markAllAsReads: 0,
      notifications: [],
      headerSeted: 0,
      showModal: false,
      locatedModal: [0, 0, false],
      traducciones: [
        {
          name: "notifications",
          es: "Notificaciones",
          en: "Notifications",
        },
      ],
    };
  },
  methods: {
    openModal(event) {
      if (event.length === 2) {
        if (this.previousEvent === event[0]) {
          this.previousEvent = "";
          this.showModal = false;
          this.selectedNotificationId = "";
        } else {
          this.previousEvent = event[0];
          this.showModal = true;
          this.selectedNotificationId = event[0];
        }
      } else {
        if (this.previousEvent === event) {
          this.previousEvent = "";
          this.showModal = false;
          this.selectedNotificationId = "";
        } else {
          this.previousEvent = event;
          this.selectedNotificationId = event;
          this.showModal = true;
        }
      }
    },
    locateModal(event) {
      this.locatedModal = event;
    },
    deleteNotification(event) {
      this.selectedNotificationId = 0;
      this.deleteNotifications = event;
      this.headerSeted = 0;
    },
    markAsRead(event) {
      this.selectedNotificationId = 0;
      this.markAsReads = event;
      this.headerSeted = 0;
      this.$emit("reloadHome");
    },
    markAllAsRead(event) {
      this.markAllAsReads = event;
      this.headerSeted = 0;
      this.$emit("reloadHome");
    },
    notificationCount() {
      setTimeout(
        function () {
          this.newNotifications = this.$refs.notTable.newNotifications.length;
          this.$parent.countNotifications = this.newNotifications;
          this.showModal = false;
          this.locatedModal = [0, 0];
          this.previousEvent = "";
        }.bind(this),
        1
      );
    },
    hidemodals() {
      this.previousEvent = "";
      this.showModal = false;
      this.selectedNotificationId = "";
    },
  },
  mixins: [translationMixin],
};
</script>
