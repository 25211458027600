<template>
  <div v-if="tracks != ''">
    <span class="tracks-title"
      >{{ langFilter("resultados") }} <strong>{{ query }}</strong
      >...
    </span>
    <div class="container searchTrackContainer">
      <div class="row">
        <div
          @click="onTrackClick(track)"
          v-for="(track, tracksIndex) in tracks"
          :key="tracksIndex"
          class="col-12 col-md-6 col-lg-4 col-xl-3 searchTrack"
        >
          <tracks :track="track" :type="1" :searched="true"></tracks>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="no-result-track align-center">
    <span class="tracks-title no-result-track-txt"
      >{{ langFilter("sin_resultados") }}<strong>{{ query }}</strong
      >.
    </span>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "query", "allTracks"],
  data() {
    return {
      tracks: [],
      // traduccion
      traducciones: [
        {
          name: "resultados",
          es: "Resultados para la búsqueda de ",
          en: "Results for the search of ",
        },
        {
          name: "sin_resultados",
          es: "Sin resultados para la búsqueda de ",
          en: "No results for the search of ",
        },
      ],
    };
  },
  methods: {
    onTrackClick(track) {
      this.$emit("trackClick", track);
    },
  },
  watch: {
    query: function (val) {
      this.tracks = this.allTracks.filter(
        (item) => item.name.toLowerCase().indexOf(val.toLowerCase()) > -1
      );
    },
  },
  mixins: [translationMixin],
};
</script>
