<template>
                <div>
                    <div class="modal-input-row" v-for="(value, index) in values" :key="index">
                        <div class="modal-input-col">
                                <label class="modal-NumberInput">{{value.number}}</label><label class="modal-TextInput">{{value.title}}</label><p class="modal-input-info">{{value.info}}</p>
                                <modal-type-input :lang="lang" :type="value.type" :name="value.name" :placeholder="value.placeholder" :options="value.options"></modal-type-input>
                        </div>
                        
                         
                        
                    </div>
                </div>
</template>

<script>

import translationMixin from '../../mixins/translationMixin.js';



    export default{
 
    props:['lang','values'],    

   
        data(){
            
            return {
                
               
                //Traducción
                traducciones: [
                   
                ],
                
            
                
                
            }
            
        },
        created(){
            
            
        },
        mounted(){
           
        },
        computed:{
           
        },
        methods:{
           
            

        },
            mixins: [translationMixin], 

            
            

            };

</script>
