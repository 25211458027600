<script setup>
import Notification from "../components/notifications/NotificationsComponent.vue";
</script>

<template>
  <Notification :lang="lang" :space="spaces[0]" />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
};
</script>
