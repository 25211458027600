<template>
  <div class="col-md-3 col-sm-4 justify-right">
    <open-btn @setOpened="opened = !opened" :opened="opened" />
    <button v-if="editing" @click="addPart(i)" class="itinerary-add-btn">
      +
    </button>
    <div class="itinerary-part-content">
      <span>{{ langFilter("part") }} {{ i + 1 }}.</span>
    </div>
  </div>
  <div class="col-md-7 col-sm-6 itinerary-part-content">
    <input
      v-if="editing"
      @input="editName(i, $event.target.value)"
      :class="`itinerary-input-theme ${
        showError && part.title === '' ? 'itinerary-user-error-input' : ''
      }`"
      :value="part.title"
      type="text"
    />
    <h5 v-else>{{ part.title }}</h5>
  </div>
  <div :class="`col-2 ${editing ? 'itinerary-options' : 'justify-center'}`">
    <img
      v-if="editing"
      @click="removePart(i)"
      :src="require(`@/assets/img/newitinerary/x.svg`)"
      alt="delete"
    />
    <button v-else @click="goToAnswers()" class="itinerary-see-btn">
      {{ langFilter("see") }}
    </button>
  </div>
  <template v-if="opened">
    <itinerary-question
      v-for="(question, i) in part.questions"
      @removeQuestion="removeQuestion($event)"
      @editQuestionName="editQuestionName($event)"
      @editQuestion="editQuestion($event)"
      @addQuestion="addQuestion($event)"
      :key="i"
      :i="i"
      :lang="lang"
      :editing="editing"
      :question="question"
      :showError="showError"
    />
    <div
      v-if="part.questions.length === 0 && editing"
      class="col-12 justify-center"
    >
      <button
        @click="addQuestion(0)"
        class="itinerary-add-btn itinerary-add-btn-margin"
      >
        +
      </button>
    </div>
  </template>
</template>

<script>
// import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  emits: [
    "addPart",
    "removePart",
    "editPartName",
    "removeQuestion",
    "addQuestion",
    "editQuestionName",
    "editQuestion",
  ],
  props: [
    "lang",
    "i",
    "editing",
    "part",
    "showError",
    "theme_id",
    "project_id",
  ],
  data() {
    return {
      opened: true,
      traducciones: [
        {
          name: "part",
          es: "Apartado",
          en: "Part",
        },
        {
          name: "see",
          es: "Ver",
          en: "See",
        },
      ],
    };
  },
  methods: {
    addPart(i) {
      this.$emit("addPart", i);
    },
    removePart(i) {
      this.$emit("removePart", i);
    },
    editName(i, name) {
      this.$emit("editPartName", { i, name });
    },
    removeQuestion(i) {
      this.$emit("removeQuestion", { questionIndex: i, partIndex: this.i });
    },
    addQuestion(i) {
      this.$emit("addQuestion", { questionIndex: i, partIndex: this.i });
    },
    editQuestionName({ i, name }) {
      this.$emit("editQuestionName", {
        questionIndex: i,
        partIndex: this.i,
        name,
      });
    },
    goToAnswers() {
      this.$router.push({
        path: `/parts/${this.project_id}/${this.theme_id}/${this.part.id}`,
      });
    },
    editQuestion(question) {
      this.$emit("editQuestion", {
        questionIndex: question,
        partIndex: this.i,
      });
    },
  },
  mixins: [translationMixin],
};
</script>
