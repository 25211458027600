<template>
  <div>
    <header-general :login="login" title="Home" src="/img/home-icon.svg" />
    <trackModal
      :lang="lang"
      :track="trackSelected"
      :categories="trackCategories"
    />
    <div class="home-module-container">
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <!-- <div class="col-md-12 col-lg-6 col-xl-4"> -->
          <project-module
            @view="$parent.changeView($event)"
            @project="changeProject($event)"
            :lang="lang"
            :user="user"
            class="home-module project-home-module"
          />
        </div>
        <!--
                <div class="col-md-12 col-lg-6 col-xl-4">
                     <bulletin-module
                        :lang="lang"
                        :user="user"
                        class="home-module"
                    ></bulletin-module>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-4">
                    -->
        <div class="col-sm-12 col-lg-6">
          <notification-module
            @view="$parent.changeView($event)"
            :lang="lang"
            :user="user"
            :space_id="spaces[0].id"
            :homeNotifications="homeNotifications"
            class="home-module project-home-module"
          />
        </div>
      </div>
    </div>
    <trackBanner
      @deleteTrack="deleteTrack($event)"
      v-on:trackClick="onTrackClick"
      @view="$parent.changeView($event)"
      :tracks="allTracks"
      :lang="lang"
      :user="user"
      title="home"
      type="3"
      catSelected="0"
      :isAdmin="isAdmin"
      :space="spaces[0]"
    />
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import generalMethodsMixin from "../../mixins/generalMethodsMixin.js";
import $ from "jquery";
export default {
  props: ["login", "user", "lang", "spaces", "role", "homeNotifications"],
  data() {
    return {
      allTracks: [],
      trackCategories: [],
      trackSelected: "",
      selectorModal: 0,
      loading: true,
      isAdmin: false,

      traducciones: [
        {
          name: "Cancelar",
          es: "Cancelar",
          en: "Cancel",
        },
      ],
    };
  },
  created() {
    this.isAdmin = this.role == 1;
  },
  mounted() {
    this.getAllTracksCategories();
    this.getAllTracks();
  },
  methods: {
    deleteTrack(track) {
      this.allTracks = this.allTracks.filter((t) => t.template_id != track);
    },
    onTrackClick(value) {
      this.trackSelected = value;
      this.selectorModal = 0;
      $("#select-modal-track").val(0);
    },
    changeProject(id) {
      history.replaceState(null, null, `/itinerary/${id}`);
    },
  },
  mixins: [translationMixin, generalMethodsMixin],
};
</script>
