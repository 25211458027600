<style>
@import "../../src/assets/css/recursos.css";
</style>
<template>
  <div>
    <div class="modal fade" id="recursosModal">
      <div class="modal-dialog" role="document">
        <div
          class="modal-content modal-recursos center-modal"
          style="width: 954px; height: 507px"
        >
          <div class="new-project-modal-header-container">
            <!-- Nuevo recurso -->
            <span
              v-if="$parent.lockedTrack == 0"
              class="new-rec"
              data-bs-toggle="modal"
              data-bs-target="#recursosModal"
              @click="$parent.modalResourcesOpen(parentChapter, parentPart)"
              >{{ langFilter("newRec") }}</span
            >

            <p
              @click="(templates = false), (selectedCategories = [])"
              :class="templates ? '' : 'selected-option-p'"
            >
              {{ langFilter("recursos") }}
            </p>
            <div class="new-project-modal-separator"></div>
            <p
              @click="(templates = true), (selectedCategories = [])"
              :class="templates ? 'selected-option-p' : ''"
            >
              {{ langFilter("plantillas") }}
            </p>
          </div>
          <div class="modal-projects-row">
            <div class="new-project-modal-left-container">
              <p>{{ langFilter("filtrar") }}</p>
              <div class="modal-container-categories">
                <div
                  v-for="(category, index) in filter_filtered_categories"
                  :key="index"
                  class="category-container"
                  :style="
                    selectedCategories.includes(category)
                      ? 'background-color:#E0DDF1;'
                      : ''
                  "
                  @click="add_or_remove_category(category)"
                >
                  <div class="new-project-modal-checkbox">
                    <input
                      v-model="selectedCategories"
                      :value="category"
                      id="cb"
                      type="checkbox"
                      style="
                        cursor: pointer;
                        display: inline-block;
                        margin: 5px 5px 0px 0px;
                      "
                    />
                  </div>
                  <div class="new-project-modal-text">
                    <p>{{ category.category }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="new-project-modal-rigth-container">
              <div
                v-for="(category, index_category) in filtered_categories"
                :key="index_category"
              >
                <div
                  v-if="index_category !== 0"
                  class="new-project-modal-category-container"
                ></div>
                <h1 class="category-title">{{ category.resource_category }}</h1>

                <div
                  v-for="(resource, index) in filtered_resources(category)"
                  :key="index"
                  class="new-project-modal-resources-container"
                >
                  <svg
                    v-if="return_icon_file(resource) == 2"
                    width="36"
                    height="26"
                    viewBox="0 0 36 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="pointer-events: none"
                  >
                    <path
                      d="M0.699219 3.5884C0.699219 1.93153 2.04237 0.588379 3.69924 0.588379H31.6994C33.3563 0.588379 34.6994 1.93153 34.6994 3.5884V15.7885C34.6994 17.4453 33.3563 18.7885 31.6994 18.7885H3.69924C2.04237 18.7885 0.699219 17.4453 0.699219 15.7885V3.5884Z"
                      fill="white"
                    />
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.101562 3.60002C0.101562 1.61178 1.71335 0 3.70158 0H31.7018C33.69 0 35.3018 1.61178 35.3018 3.60002V15.8001C35.3018 17.7883 33.69 19.4001 31.7018 19.4001H3.70159C1.71335 19.4001 0.101562 17.7883 0.101562 15.8001V3.60002ZM3.70158 1.20001C2.37609 1.20001 1.30157 2.27453 1.30157 3.60002V15.8001C1.30157 17.1256 2.37609 18.2001 3.70159 18.2001H31.7018C33.0272 18.2001 34.1018 17.1256 34.1018 15.8001V3.60002C34.1018 2.27453 33.0272 1.20001 31.7018 1.20001H3.70158Z"
                      fill="#A098C6"
                    />
                    <path
                      class="icone-fill"
                      d="M22.0992 8.57026C22.8992 9.03215 22.8992 10.1869 22.0992 10.6487L15.4992 14.4593C14.6992 14.9212 13.6992 14.3438 13.6992 13.42L13.6992 5.79896C13.6992 4.8752 14.6992 4.29785 15.4992 4.75973L22.0992 8.57026Z"
                      fill="#A098C6"
                    />
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 22.7898C0 22.4032 0.313403 22.0898 0.700004 22.0898L34.7002 22.0898C35.0868 22.0898 35.4002 22.4032 35.4002 22.7898C35.4002 23.1764 35.0868 23.4899 34.7002 23.4899L0.700004 23.4899C0.313403 23.4899 0 23.1764 0 22.7898Z"
                      fill="#A098C6"
                    />
                    <path
                      d="M10.7008 22.8006C10.7008 24.0156 9.71583 25.0006 8.50079 25.0006C7.28576 25.0006 6.30078 24.0156 6.30078 22.8006C6.30078 21.5856 7.28576 20.6006 8.50079 20.6006C9.71583 20.6006 10.7008 21.5856 10.7008 22.8006Z"
                      fill="white"
                    />
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.50079 24.0006C9.16354 24.0006 9.7008 23.4633 9.7008 22.8006C9.7008 22.1379 9.16354 21.6006 8.50079 21.6006C7.83805 21.6006 7.30079 22.1379 7.30079 22.8006C7.30079 23.4633 7.83805 24.0006 8.50079 24.0006ZM8.50079 25.0006C9.71583 25.0006 10.7008 24.0156 10.7008 22.8006C10.7008 21.5856 9.71583 20.6006 8.50079 20.6006C7.28576 20.6006 6.30078 21.5856 6.30078 22.8006C6.30078 24.0156 7.28576 25.0006 8.50079 25.0006Z"
                      fill="#A098C6"
                    />
                  </svg>
                  <svg
                    v-if="return_icon_file(resource) == 1"
                    width="25"
                    height="19"
                    viewBox="0 0 25 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      style="fill: none"
                      d="M10.4567 9.20225C10.6124 9.83937 10.9033 10.4356 11.3096 10.9504C11.716 11.4652 12.2283 11.8866 12.8119 12.186C13.3954 12.4854 14.0365 12.6558 14.6917 12.6856C15.3469 12.7154 16.0009 12.604 16.6092 12.3589L20.2093 10.9091C21.3138 10.4418 22.1906 9.55938 22.6509 8.45191C23.1111 7.34443 23.1179 6.1005 22.6699 4.98803C22.2219 3.87555 21.3549 2.98355 20.2556 2.50413C19.1562 2.02472 17.9126 1.99626 16.7925 2.42487L14.7321 3.24769"
                      stroke="#A098C6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      style="fill: none"
                      d="M14.5408 8.95128C14.3851 8.31416 14.0942 7.71798 13.6878 7.20317C13.2814 6.68835 12.7691 6.26696 12.1856 5.96756C11.602 5.66817 10.9609 5.49777 10.3057 5.46793C9.65052 5.4381 8.99658 5.54952 8.38824 5.79464L4.78815 7.24447C3.68363 7.71175 2.80683 8.59415 2.34659 9.70163C1.88636 10.8091 1.87951 12.053 2.32752 13.1655C2.77554 14.278 3.64257 15.17 4.74188 15.6494C5.84119 16.1288 7.08482 16.1573 8.20491 15.7287L10.257 14.9023"
                      stroke="#A098C6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    v-if="return_icon_file(resource) == 3"
                    width="36"
                    height="24"
                    viewBox="0 0 36 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.5 1C2.567 1 1 2.567 1 4.5V19.5C1 21.433 2.567 23 4.5 23H31.5C33.433 23 35 21.433 35 19.5V7.5C35 7.22386 35.2239 7 35.5 7C35.7761 7 36 7.22386 36 7.5V19.5C36 21.9853 33.9853 24 31.5 24H4.5C2.01472 24 0 21.9853 0 19.5V4.5C0 2.01472 2.01472 0 4.5 0H28.5C28.7761 0 29 0.223858 29 0.5C29 0.776142 28.7761 1 28.5 1H4.5Z"
                      fill="#A098C6"
                    />
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M28.5 0C28.7761 0 29 0.223858 29 0.5V5.5C29 6.32843 29.6716 7 30.5 7H35.5C35.7761 7 36 7.22386 36 7.5C36 7.77614 35.7761 8 35.5 8H30.5C29.1193 8 28 6.88071 28 5.5V0.5C28 0.223858 28.2239 0 28.5 0Z"
                      fill="#A098C6"
                    />
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M28.1464 0.146447C28.3417 -0.0488155 28.6583 -0.0488155 28.8536 0.146447L35.8536 7.14645C36.0488 7.34171 36.0488 7.65829 35.8536 7.85355C35.6583 8.04882 35.3417 8.04882 35.1464 7.85355L28.1464 0.853553C27.9512 0.658291 27.9512 0.341709 28.1464 0.146447Z"
                      fill="#A098C6"
                    />
                    <path
                      d="M10.8809 9.07184H8.10946L7.75541 10.1337C7.68247 10.3525 7.47774 10.5 7.24714 10.5L-nan -nanL7.24714 10.5C6.88033 10.5 6.62199 10.1397 6.73969 9.79231L8.66968 4.09534C8.79024 3.73946 9.1242 3.5 9.49994 3.5L-nan -nanL9.49994 3.5C9.87568 3.5 10.2096 3.73946 10.3302 4.09534L12.2582 9.78636C12.3769 10.1367 12.1163 10.5 11.7465 10.5L-nan -nanL11.7465 10.5C11.5139 10.5 11.3075 10.3512 11.2339 10.1306L10.8809 9.07184ZM10.5761 8.13649L9.49994 4.88793L8.41422 8.13649H10.5761Z"
                      fill="#A098C6"
                    />
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.75 8.5C13.75 8.08579 14.0858 7.75 14.5 7.75H25C25.4142 7.75 25.75 8.08579 25.75 8.5C25.75 8.91421 25.4142 9.25 25 9.25H14.5C14.0858 9.25 13.75 8.91421 13.75 8.5Z"
                      fill="#A098C6"
                    />
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.75 13.5C5.75 13.0858 6.08579 12.75 6.5 12.75L30.5 12.75C30.9142 12.75 31.25 13.0858 31.25 13.5C31.25 13.9142 30.9142 14.25 30.5 14.25L6.5 14.25C6.08579 14.25 5.75 13.9142 5.75 13.5Z"
                      fill="#A098C6"
                    />
                    <path
                      class="icone-fill"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.75 18.5C5.75 18.0858 6.08579 17.75 6.5 17.75L26.5 17.75C26.9142 17.75 27.25 18.0858 27.25 18.5C27.25 18.9142 26.9142 19.25 26.5 19.25L6.5 19.25C6.08579 19.25 5.75 18.9142 5.75 18.5Z"
                      fill="#A098C6"
                    />
                  </svg>

                  <p>{{ resource.title }}</p>
                  <span
                    v-if="$parent.lockedTrack == 0"
                    class="newProject-CloseIconContainer"
                    style="margin-top: 5px"
                    ><img
                      :src="require('@/assets/img/projects/modal_X.png')"
                      class="new-project-modal-CloseIcon"
                      @click="minusResource(resource)"
                  /></span>
                </div>
              </div>
              <div v-if="filtered_categories.length == 0">
                <p style="font-size: 22px" v-if="!templates">
                  {{ langFilter("noRec") }}
                </p>
                <p style="font-size: 22px" v-if="templates">
                  {{ langFilter("noTemp") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../mixins/translationMixin.js";
export default {
  props: ["categories", "lang"],
  data() {
    return {
      selectedCategories: [],
      parentChapter: 0,
      parentPart: -1,
      lockedTrack: 0,
      templates: false,
      resources_list: [],
      //Traducción
      traducciones: [
        {
          name: "filtrar",
          es: "Filtrar por:",
          en: "Filter by:",
        },
        {
          name: "recursos",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "plantillas",
          es: "Plantillas",
          en: "Templates",
        },

        {
          name: "newRec",
          es: "+Añadir nuevo recurso",
          en: "+Add new resource",
        },
        {
          name: "noCat",
          es: "Sin categoría",
          en: "No category",
        },
        {
          name: "noRec",
          es: "No hay recursos asociados",
          en: "There are not resources associated",
        },
        {
          name: "noTemp",
          es: "No hay plantillas asociadas",
          en: "There are not templates associated",
        },
      ],
    };
  },
  created() {},
  computed: {
    filter_filtered_categories() {
      return this.categories.filter((category) => {
        if (
          this.resources_list.filter(
            (e) => e.category === category.id && e.template == this.templates
          ).length > 0
        ) {
          return category;
        }
      });
    },
    filtered_categories() {
      return this.categories.filter((category) => {
        if (this.selectedCategories.length !== 0) {
          if (this.selectedCategories.includes(category)) {
            if (
              this.resources_list.filter(
                (e) =>
                  e.category === category.id && e.template == this.templates
              ).length > 0
            ) {
              return category;
            }
          }
        } else {
          if (
            this.resources_list.filter(
              (e) => e.category === category.id && e.template == this.templates
            ).length > 0
          ) {
            return category;
          }
        }
      });
    },
  },

  methods: {
    return_icon_file(resource) {
      var format = resource.userFileName.substr(
        resource.userFileName.length - 4
      );
      if (!resource.file && !resource.preResource) {
        return 1;
      } else if (resource.preResource) {
        if (
          format == ".mp4" ||
          format == ".avi" ||
          format == ".mkv" ||
          format == ".flv" ||
          format == ".mov"
        ) {
          return 2;
        } else if (
          format == ".doc" ||
          format == "docx" ||
          format == ".txt" ||
          format == "xlsx" ||
          format == "pptx" ||
          format == ".pdf"
        ) {
          return 3;
        } else {
          return 1;
        }
      } else {
        if (
          format == ".mp4" ||
          format == ".avi" ||
          format == ".mkv" ||
          format == ".flv" ||
          format == ".mov"
        ) {
          return 2;
        } else {
          return 3;
        }
      }
    },
    add_or_remove_category(category) {
      if (this.selectedCategories.includes(category)) {
        this.selectedCategories.splice(
          this.selectedCategories.indexOf(category),
          1
        );
      } else {
        this.selectedCategories.push(category);
      }
    },
    filtered_resources(category) {
      return this.resources_list.filter((resource) => {
        if (category.id == resource.category)
          if (this.templates) {
            return resource.template;
          } else {
            return !resource.template;
          }
      });
    },
    minusResource(resource) {
      this.resources_list.splice(this.resources_list.indexOf(resource), 1);
      if (this.parentPart == -1) {
        if (!resource.preResource) {
          this.$parent.chapters[this.parentChapter].files.splice(
            resource.index,
            1
          );
        } else {
          this.$parent.chapters[this.parentChapter].preResources.splice(
            resource.index -
              this.$parent.chapters[this.parentChapter].files.length,
            1
          );
        }
      } else {
        if (!resource.preResource) {
          this.$parent.chapters[this.parentChapter].parts[
            this.parentPart
          ].files.splice(resource.index, 1);
        } else {
          this.$parent.chapters[this.parentChapter].parts[
            this.parentPart
          ].preResources.splice(
            resource.index -
              this.$parent.chapters[this.parentChapter].parts[this.parentPart]
                .files.length,
            1
          );
        }
      }
    },
  },
  mixins: [translationMixin],
};
</script>

<style>
.chosen {
  background-color: #ffffff;
  border: 2px solid#301889 !important;
  box-sizing: border-box;
  box-shadow: -1px 2px 4px 2px rgba(48, 24, 137, 0.2);
  border-radius: 12px;
}
.ghost {
  opacity: 1;
  border: 2px solid#301889;
  box-sizing: border-box;
  box-shadow: -1px 2px 4px 2px rgba(48, 24, 137, 0.2);
}
.no-margin-row {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
