import axios from "axios";

export default {
  methods: {
    getValidUrl(url) {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");

      if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
      }
      return newUrl;
    },

    //Tracks
    getAllTracksCategories() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTracksCategory`, {
          lang: this.lang,
        })
        .then((response) => {
          this.categories = response.data;
        });
    },
    getAllTracks() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTracks`, {
          lang: this.lang,
          space_id: this.spaces[0].id,
        })
        .then((response) => {
          this.bestTracks = response.data
            .sort((a, b) => b.rating - a.rating)
            .slice(0, 10);
          this.allTracks = response.data.sort((a, b) => a.id - b.id);
        });
    },
  },
};
