<template>
  <div
    v-for="(myProject, myProjectsIndex) in projectsList"
    :key="myProjectsIndex"
    class="my-3"
    :id="'project-card-' + myProjectsIndex"
  >
    <router-link :to="`/itinerary/${myProject.id}`">
      <div class="myProjects-ProjectCardNew">
        <img
          class="myProjects-ProjectCardLogo"
          :src="require('@/assets/img/flow/Flow_roadmap_Icon-20.png')"
        />

        <p class="myProjects-ProjectCardTitle">
          {{ myProject.name }}
        </p>
        <div class="myProjects-ProjectCardSeparator"></div>

        <ul
          class="myProjects-ProjectCardInfo"
          :id="'project-info-' + myProjectsIndex"
        >
          <li :id="'project-resources-' + myProjectsIndex">
            {{ langFilter("resources") }}:
            <b> {{ myProject.station_resources.length }}</b>
          </li>
          <li :id="'project-complete-' + myProjectsIndex">
            {{ langFilter("progress") }}:
            <b class="hide"> {{ myProject.percentage }}%</b>
            <b> {{ myProject.percentage }}%</b>
          </li>
        </ul>
        <div
          class="myProjects-ProjectCardUsers"
          :id="'project-users-' + myProjectsIndex"
        >
          {{ langFilter("users") }}: <b>{{ myProject.users.length }}</b
          ><br />
          <div
            class="myProjects-ProjectCardUsersImageRow"
            v-for="(users, usersWithImageIndex) in myProject.users.slice(0, 7)"
            :key="usersWithImageIndex"
          >
            <img class="avatar_user_card" :src="getAvatar(users)" />
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import translations from "./translate/AssistantTranslate.json";

export default {
  name: "AssistantProjectsList",
  props: ["projectsList", "lang"],
  data() {
    return {
      traducciones: translations,
    };
  },
  updated() {
    console.log("Projects List in updated:", this.projectsList);
  },
  methods: {
    getNumResources(id) {
      const resources = this.resourcesProjectCount.filter(
        (project) => project.project_id === id
      );
      return resources.length > 0 ? resources[0].count : 0;
    },
    getAvatar(avatar) {
      if (avatar != undefined && avatar != "" && avatar != "undefined") {
        if (avatar.includes("http")) {
          return avatar;
        }
        return process.env.VUE_APP_API_STORAGE + avatar;
      } else {
        return "/img/login_register/Avatar4.png";
      }
    },
  },
  mixins: [translationMixin],
};
</script>
