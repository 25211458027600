<script setup>
import AdminPanel from "../components/admin_panel/AdminPanelComponent.vue";
</script>

<template>
  <AdminPanel :space="spaces[0]" :lang="lang" />
</template>

<script>
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      default: "",
    },
    lang: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
@import "../../src/assets/css/admin_panel/admin_panel.css";
</style>
