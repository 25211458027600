<style>
@import "../../src/assets/css/cdti.css";
</style>

<script setup>
import CdtiModal from "../components/cdti/CdtiModalComponent.vue";
</script>

<template>
  <CdtiModal :lang="lang" />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
};
</script>
