<template>
  <div v-if="loadingData" class="loading-data-container">
    <img :src="require('@/assets/img/general/carga.gif')" />
  </div>
  <div v-else>
    <div v-if="assistantStatus === 'not-assigned'">
      <div v-if="isTooltipVisible" class="solution-tooltip custom-tooltip">
        {{ tooltipTextTranlated }}
      </div>
      <div
        v-if="!tabHasAssistant && !isMainTab"
        class="missing-assistant-alert"
      >
        {{ dynamicTranslations.stillDoNotHave }}

        <span
          class="assistant-type-text"
          @mouseover="isTooltipVisible = true"
          @mouseleave="isTooltipVisible = false"
        >
          {{ dynamicTranslations.assistantType }}
        </span>

        {{ dynamicTranslations.configured }}.
        {{ dynamicTranslations.configureInstruction }}

        <span class="link-text" @click="goToConfig">
          {{ dynamicTranslations.clickHere }}.
        </span>
      </div>
    </div>
    <div>
      <div
        v-if="assistantStatus !== 'not-assigned'"
        class="chat-project-header-container"
      >
        <chat-bot-project-header
          :lang="lang"
          :message-header="messageHeader"
          :message-info-hover="messageInfoHover"
          :selected-tab="selectedTab"
          @header-action="goToConfig"
        />
      </div>
      <div
        v-if="
          selectedTab !== 1 &&
          openedOptions &&
          !question_id &&
          assistantStatus === 'changed'
        "
        class="selector-gpt"
      >
        <button :disabled="askingGpt" @click="evaluateProject">
          {{ langFilter("evaluateProject") }}
        </button>
        <button
          :class="buttonSelected === 2 && 'gpt-type-selected'"
          :disabled="askingGpt"
          @click="selectButton(2)"
        >
          {{ langFilter("evaluatorChat") }}
        </button>
      </div>
      <div v-if="isUpdatePromptTab">
        <div style="width: 100%" class="gpt-project-chat">
          <div ref="scrollContainer">
            <div
              v-if="!loadingUpdatingPrompt"
              class="user-message-div row update-prompt"
            >
              <div class="col-11">
                <textarea
                  class="solution-user-msg-textarea"
                  :value="updatedPrompt"
                  maxlength="4096"
                  @change="changeUpdatedPrompt($event)"
                />
              </div>
              <div class="col-1 sol-alignd">
                <button
                  :disabled="!updatedPrompt"
                  @click="sendQuestionPrompt()"
                >
                  <img :src="Send" />
                </button>
              </div>
            </div>
            <div v-else>
              <div class="loading-prompt-question">
                <img
                  :src="require('@/assets/img/general/carga.gif')"
                  alt="loading"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="assistantStatus !== 'not-assigned' && !isUpdatePromptTab">
        <chat-bot-project-chat
          ref="chatRef"
          :lang="lang"
          :showProjectChat="showProjectChat"
          :selected-tab="selectedTab"
          :assistant-project="assistantProject"
          :assistant-messages="assistantMessages"
          :project_id="project_id"
          :question_id="question_id"
          :status="assistantStatus"
          :askingGpt="askingGpt"
          :preButtonSelected="preButtonSelected"
          @on-get-question-gpt-chat="getCurrentQuestionGptChat"
          @on-get-project-gpt-chat="getProjectGptChat"
          @on-show-project-chat="setShowProjectChat"
        />
      </template>
    </div>
  </div>

  <alertMessage ref="alerts" :lang="lang"></alertMessage>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import Send from "./images/send.png";
import Stop from "./images/stop.webp";
import MarkdownIt from "markdown-it";
import axios from "axios";
import ChatbotLogo from "./images/chatbot.svg";
import { assistantStatusTypes } from "./shared/enums.js";

export default {
  mixins: [translationMixin],
  provide() {
    return {
      scrollToBottomProject: this.scrollToBottom,
    };
  },
  props: [
    "lang",
    "space_id",
    "project_id",
    "question_id",
    "showAllChat",
    "selectedTab",
    "preButtonSelected",
    "assistantsProject",
  ],
  emits: [
    "reloadGpt",
    "askingGpt",
    "reloadEditPrompt",
    "setEditedPrompt",
    "editPrompt",
    "copyContent",
    "reloadEditPrompt",
  ],
  data() {
    return {
      md: new MarkdownIt(),
      ChatbotLogo: ChatbotLogo,
      Send: Send,
      prompt: "",
      showProjectChat: true,
      loading: false,
      files: [],
      editing: -1,
      updatedPrompt: "",
      uploadedFiles: [],
      messages: [],
      sendingMessage: "",
      Stop: Stop,
      alreadyAsked: false,
      indexAsked: -1,
      message_id: 0,
      old_message_id: 0,
      buttonSelected: 2,
      openedOptions: true,
      loadingUpdatingPrompt: false,
      alreadyEvaluated: false,
      messageHeader: "",
      messageInfoHover: "",
      showHeader: true,
      askingGpt: false,
      sendEvaluatorPrompt: false,
      errors: [],
      succesful: [],
      assistantExpert: "",
      assistantEvaluator: "",
      assistant: "",
      assistantName: "",
      assistantProject: null,
      assistantMessages: null,
      hasMessages: false,
      countAssistants: 0,
      assistantStatus: null,
      isTooltipVisible: false,
      scrollPosition: 0,
      loadingData: false,
      traducciones: [
        {
          name: "evaluateProject",
          es: "Evaluar Proyecto",
          en: "Evaluate Project",
        },
        {
          name: "currentQuestion",
          es: "Pregunta Actual",
          en: "Current Question",
        },
        {
          name: "evaluatorChat",
          es: "Chat del Evaluador",
          en: "Evaluator Chat",
        },
        {
          name: "expertAssistant",
          es: "Asistente experto",
          en: "Expert assistant",
        },
        {
          name: "evaluatorAssistant",
          es: "Asistente evaluador",
          en: "Evaluator assistant",
        },
        {
          name: "assistantExpertDeleted",
          es: "Se ha eliminado el Experto <b>[assistantName]</b>, para configurar un nuevo asistente experto",
          en: "Expert Assistant [assistantName] has been removed, please set up a new expert assistant",
        },
        {
          name: "assistantEvaluatorDeleted",
          es: "Se ha eliminado el Evaluador <b>[assistantName]</b>, para configurar un nuevo asistente evaluador",
          en: "Expert Evaluator [assistantName] has been removed, please set up a new evaluator assistant",
        },
        {
          name: "assistantExpertInfoMessage",
          es: "El asistente experto es quien ayudará a desarrollar el proyecto",
          en: "The expert assistant is the one who will help develop the project",
        },
        {
          name: "assistantEvaluatorInfoMessage",
          es: "El asistente evaluador es quien ayudará a evaluar el proyecto",
          en: "The evaluator assistant is the one who will help evaluate the project",
        },
        {
          name: "assistantExpertChange",
          es: "<b>[assistantName]</b>. Puedes configurar tu asistente experto cuantas veces quieras para ayudarte a desarrollar tu proyecto",
          en: "<b>[assistantName]</b>. You can set up your expert assistant as many times as you want to help you develop your project.",
        },
        {
          name: "assistantEvaluatorChange",
          es: "<b>[assistantName]</b>. Puedes configurar tu asistente evaluador cuantas veces quieras para ayudarte a evaluar tu proyecto",
          en: "<b>[assistantName]</b>. You can set up your evaluator assistant as many times as you want to help you evaluate your project.",
        },
        {
          name: "stillDoNotHave",
          es: "Aún no tienes un",
          en: "You still don't have",
        },
        {
          name: "configured",
          es: "Configurado",
          en: "Configured",
        },
        {
          name: "configureInstruction",
          es: "Si deseas configurar uno",
          en: "If you want to set one",
        },
        {
          name: "clickHere",
          es: "Haz click aquí",
          en: "Click here",
        },
        {
          name: "expertAssistantTooltip",
          es: "El asistente experto es quien ayudará a desarrollar el proyecto",
          en: "The expert assistant is the one who will help develop the project.",
        },
        {
          name: "evaluatorAssistantTooltip",
          es: "El asistente evaluador es quien ayudará a evaluar el proyecto y proponer mejoras.",
          en: "The evaluator assistant is the one who will help evaluate the project and suggest improvements.",
        },
      ],
    };
  },
  computed: {
    isFromItinerary() {
      return this.preButtonSelected !== undefined;
    },
    isUpdatePromptTab() {
      return this.preButtonSelected === 0;
    },
    selectedType() {
      return this.isFromItinerary ? 1 : this.selectedTab;
    },
    isMainTab() {
      return this.selectedTab === 0;
    },
    existAssistant() {
      return (
        this.isFromItinerary ||
        this.assistantsProject.some(
          (assistant) => assistant.assistant_type === this.selectedTab,
        )
      );
    },
    hasMessagesChat() {
      return this.messages.length > 0;
    },
    getAssistantTypeStatus() {
      if (this.existAssistant) {
        return assistantStatusTypes.changed;
      } else {
        return this.hasMessagesChat
          ? assistantStatusTypes.deleted
          : assistantStatusTypes.notAssigned;
      }
    },
    tabHasAssistant() {
      const matchAssistant = this.assistantsProject.some(
        ({ assistant_type }) => assistant_type === this.selectedTab,
      );

      return matchAssistant;
    },
    isExpertType() {
      return this.selectedTab === 1;
    },
    getAssistentType() {
      return this.isExpertType ? "expertAssistant" : "evaluatorAssistant";
    },
    dynamicTranslations() {
      return {
        stillDoNotHave: this.langFilter("stillDoNotHave"),
        assistantType: this.langFilter(this.getAssistentType).toLowerCase(),
        configured: this.langFilter("configured").toLowerCase(),
        configureInstruction: this.langFilter("configureInstruction"),
        clickHere: this.langFilter("clickHere").toLowerCase(),
      };
    },
    tooltipTextTranlated() {
      const selectedText = this.isExpertType
        ? "expertAssistantTooltip"
        : "evaluatorAssistantTooltip";

      return this.langFilter(selectedText);
    },
  },
  watch: {
    selectedTab(newVal) {
      this.getProjectGptChat(newVal);
    },
    assistantsProject: {
      async handler() {
        await this.loadData();
      },
      immediate: true,
    },
    showAllChat(newVal) {
      this.loading = true;
      this.messages = [];
      if (newVal) {
        this.old_message_id = this.message_id;
        this.message_id = 0;
        this.showProjectChat = true;
      }
      this.getProjectGptChat();
      this.loading = false;
    },
    showQuestionChat(newVal) {
      this.loading = true;
      this.messages = [];
      if (newVal) {
        this.showProjectChat = false;
        this.getCurrentQuestionGptChat();
      }
      this.loading = false;
    },
  },
  async created() {
    await this.getProjectGptChat();
    if (this.question_id) {
      this.showProjectChat = false;
    }
  },
  async mounted() {
    if (this.isUpdatePromptTab) {
      await this.getQuestionGptChat();
    }

    document.addEventListener("copy", this.handleCopy);
    this.getAssistantStatus();
    this.getMessageHeader(this.selectedTab);
  },
  beforeUnmount() {
    document.removeEventListener("copy", this.handleCopy);
  },
  methods: {
    async evaluateProject() {
      this.sendEvaluatorPrompt = true;
      this.loading = true;
      this.alreadyEvaluated = true;
      this.askingGpt = true;
      this.$emit("askingGpt", true);
      this.scrollToBottom();

      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/assistant/evaluate`,
        );

        if (data.error) {
          this.$refs.alerts.title = "Ups... Something went wrong!";
          this.$refs.alerts.text = "Error evaluating project";
          this.openErrorsAlert();
          return;
        }

        this.getProjectGptChat();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.evaluatorPrompt = "evaluateProject";
        this.askingGpt = false;
        this.sendEvaluatorPrompt = false;
        this.$emit("askingGpt", false);
      }
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },
    getFilePath(file_name, file_path) {
      return `${process.env.VUE_APP_API_STORAGE}/storage/${file_path}`;
    },
    setShowProjectChat(value) {
      this.showProjectChat = value;
    },
    selectButton(button) {
      if (!this.askingGpt) {
        this.buttonSelected = button;
        this.alreadyEvaluated = false;
        this.scrollToBottom();
      }
    },
    getAssistantStatus() {
      this.checkMessages();
      if (this.existAssistant) {
        this.assistantStatus = assistantStatusTypes.changed;
      } else {
        this.assistantStatus = this.hasMessages
          ? assistantStatusTypes.deleted
          : assistantStatusTypes.notAssigned;
      }
    },
    checkMessages() {
      this.hasMessages = this.messages.length > 0;
    },
    changeUpdatedPrompt(e) {
      this.updatedPrompt = e.target.value;
    },
    handleCopy(event) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const divs = document.querySelectorAll(
          ".user-message-div, .bot-message-div",
        );
        for (let div of divs) {
          if (range.intersectsNode(div)) {
            event.preventDefault();
            const parser = new DOMParser();
            const doc = parser.parseFromString(
              range.cloneContents().textContent,
              "text/html",
            );
            let text = doc.body.textContent || "";

            // Remove extra spaces while preserving necessary spaces and newlines
            text = text
              .split("\n")
              .map((line) => line.trim().replace(/\s+/g, " ")) // Trim lines and replace multiple spaces with a single space
              .filter((line) => line.length > 0) // Remove empty lines
              .join("\n"); // Join lines with a newline character

            event.clipboardData.setData("text/plain", text);
            break;
          }
        }
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (!this.$refs.scrollContainer) {
          return;
        }
        setTimeout(() => {
          if (!this.$refs.scrollContainer) return;
          this.$refs.scrollContainer.scrollTop =
            this.$refs.scrollContainer?.scrollHeight;
          //console.log(this.$refs);
        }, 2000);
      });
    },
    async getProjectGptChat() {
      try {
        const type = this.preButtonSelected
          ? this.preButtonSelected
          : this.selectedTab > 0
            ? this.selectedTab
            : 1;
        this.askingGpt = true;
        this.$emit("askingGpt", true);
        let res;
        if (this.question_id) {
          res = await axios.get(
            `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}/${this.question_id}`,
          );
        } else {
          res = await axios.get(
            `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
          );
        }

        this.askingGpt = false;
        this.$emit("askingGpt", false);

        this.updatedPrompt = res.data.question_prompt || this.question || "";
        this.messages = res.data.messages;
        this.checkMessages();
        this.getAssistantStatus();
        this.getMessageHeader(this.selectedTab);
        this.sendingMessage = "";
        this.scrollToBottom();
        if (this.alreadyAsked) {
          this.$emit("reloadEditPrompt");
        }
      } catch (error) {
        console.error(error);
        alert("Error obteniendo el chat del Asistente");
      }
    },
    async getQuestionGptChat() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getProjectUserChatGpt/${this.project_id}/${this.question_id}`,
        )
        .then((res) => {
          if (res.data) {
            this.alreadyAsked = true;
            if (this.message_id === 0) this.message_id = res.data;
            this.getCurrentQuestionGptChat();
          } else {
            axios
              .get(
                `${process.env.VUE_APP_API_URL}/getQuestionPrompt/${this.question_id}`,
              )
              .then((res) => {
                this.updatedPrompt = res.data.gpt_prompt || "";
                return res.data.gpt_prompt;
              })
              .catch((error) => {
                console.error(error);
                alert("Error getting the question prompt");
                return null;
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getCurrentQuestionGptChat() {
      try {
        this.askingGpt = true;
        this.$emit("askingGpt", true);
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/1/${this.question_id}`,
        );
        this.messages = res.data.messages;
        this.sendingMessage = "";
        this.scrollToBottom();
        this.askingGpt = false;
        this.$emit("askingGpt", false);
      } catch (error) {
        console.error(error);
        alert("Error obteniendo el chat del Asistente");
        this.askingGpt = false;
        this.$emit("askingGpt", false);
      }
    },
    async sendQuestionPrompt() {
      if (!this.updatedPrompt) return;

      this.loadingUpdatingPrompt = true;
      const prompt =
        this.updatedPrompt !== "" ? this.updatedPrompt : this.question;
      this.loading = true;

      let type = this.preButtonSelected
        ? this.preButtonSelected
        : this.selectedTab > 0
          ? this.selectedTab
          : 1;
      // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
      if (this.question_id) {
        type = 1;
      }
      this.askingGpt = true;
      this.$emit("askingGpt", true);
      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
          {
            project_id: this.project_id,
            assistant_type: this.selectedType,
            message: prompt,
            question_id: this.question_id,
          },
        )
        .then(async () => {
          await axios
            .post(`${process.env.VUE_APP_API_URL}/ProjectUserGptMessage`, {
              project_id: this.project_id,
              question_id: this.question_id,
            })
            .then((res) => {
              this.message_id = res.data;
            });
          await this.getCurrentQuestionGptChat();
          this.loading = false;
          this.alreadyAsked = true;
          this.$emit("reloadEditPrompt");
          this.$emit("setEditedPrompt");
          this.scrollToBottom();
        })
        .catch((error) => {
          console.error(error);
          alert("Error enviando mensaje al Asistente");
        })
        .finally(() => {
          this.updatedPrompt = "";
          this.loadingUpdatingPrompt = false;
          this.askingGpt = false;
          this.$emit("askingGpt", false);
        });
    },
    findAssistantProject() {
      return (
        this.assistantsProject.find(
          (assistant) => assistant.assistant_type === this.selectedType,
        ) || null
      );
    },
    findAssistantMessages() {
      return this.messages;
    },
    getAssistant() {
      const assistantProject = this.findAssistantProject();
      const { id, name } = assistantProject || {};

      this.assistantProject = {
        id,
        name,
      };

      const assistantMessages = this.findAssistantMessages();
      if (assistantMessages) {
        this.assistantMessages = assistantMessages.map((message) => ({
          id: message.assistantId,
          name: message.assistantName,
          message: message.message,
          response: message.response,
          files: message.files,
        }));
      }
    },
    getMessageHeader(assistantType) {
      this.getAssistant();
      if (
        this.assistantStatus === "deleted" &&
        this.assistantMessages !== undefined
      ) {
        const ultimoMensaje =
          this.assistantMessages?.[this.assistantMessages.length - 1];
        switch (assistantType) {
          case 1:
            this.messageHeader = this.setAttributeMessage(
              this.langFilter("assistantExpertDeleted"),
              "[assistantName]",
              ultimoMensaje.name,
            );
            this.messageInfoHover = this.langFilter(
              "assistantExpertInfoMessage",
            );
            break;

          case 2:
            this.messageHeader = this.setAttributeMessage(
              this.langFilter("assistantEvaluatorDeleted"),
              "[assistantName]",
              ultimoMensaje.name,
            );
            this.messageInfoHover = this.langFilter(
              "assistantEvaluatorInfoMessage",
            );
            break;
        }
      } else if (
        this.assistantStatus === "changed" &&
        this.assistantMessages !== undefined
      ) {
        switch (assistantType) {
          case 1:
            this.messageHeader = this.setAttributeMessage(
              this.langFilter("assistantExpertChange"),
              "[assistantName]",
              this.assistantProject.name,
            );
            this.messageInfoHover = this.langFilter(
              "assistantExpertInfoMessage",
            );
            break;

          case 2:
            this.messageHeader = this.setAttributeMessage(
              this.langFilter("assistantEvaluatorChange"),
              "[assistantName]",
              this.assistantProject.name,
            );
            this.messageInfoHover = this.langFilter(
              "assistantEvaluatorInfoMessage",
            );
            break;
        }
      }
    },
    setAttributeMessage(message, attribute, replaceString) {
      return (this.messageHeader = message.replace(attribute, replaceString));
    },
    goToConfig() {
      this.$router.replace({
        name: "ConfigProject",
        params: { project_id: this.project_id },
      });
    },
    async loadData() {
      this.loadingData = true;
      try {
        await this.getProjectGptChat();
        this.getAssistant();
        this.getMessageHeader(this.selectedTab);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        this.loadingData = false; // Ensure loading is false even if error occurs
      }
    },
  },
};
</script>

<style scoped>
.open-file-button.disabled {
  cursor: default;
  pointer-events: none;
}
</style>
