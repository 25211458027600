import axios from "axios";

export default {
  data() {
    return {
      uploadedFilesCount: 0,
    };
  },
  methods: {
    handleError(action, error) {
      console.error(`Error ${action}`, error);
    },
    createFormData(fields) {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      return formData;
    },
    async uploadFilesSequentially({ assistantId, files }) {
      if (!assistantId) return;

      for (const file of Array.from(files)) {
        const convertToText = file.convert;
        delete file.convert;
        await this.uploadSingleFile(assistantId, file, convertToText);
      }
    },
    async uploadSingleFile(assistantId, file, convertToText = false) {
      const fileFormData = this.createFormData({
        file,
        convertToText,
      });

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/assistants/${assistantId}/files`,
          fileFormData,
        );

        this.uploadedFilesCount += 1;
      } catch (error) {
        this.openErrorsAlert("Error uploading file", file.name);
        this.handleError(`uploading file: ${file.name}`, error);
      }
    },
  },
};
