<style>
@import "../../src/assets/css/users/edit-profile.css";
</style>

<script setup>
import EditProfile from "../components/users/EditProfileComponent.vue";
</script>

<template>
  <EditProfile :lang="lang" />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
};
</script>
