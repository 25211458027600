<template>
  <div class="acc-modal itinerary-users-modal">
    <div class="acc-modal-content">
      <h5>{{ langFilter("inviteUsers") }}</h5>
      <form
        @submit="addUser($event)"
        :class="error ? 'itinerary-user-error-form' : ''"
      >
        <input
          @input="filterUsers($event.target.value)"
          type="text"
          :placeholder="langFilter('searchUser')"
          :value="searchUser"
        />
        <span v-if="error">{{ langFilter("notValidEmail") }}</span>
      </form>
      <div class="itinerary-users-to-select-container">
        <itinerary-users-modal-user
          v-for="(user, i) in usersInvited"
          @selectUser="selectUser($event)"
          :key="i"
          :user="user"
          :usersSelected="usersSelected"
          :usersInvited="usersInvited"
          :lang="lang"
        />
        <itinerary-users-modal-user
          v-for="(user, i) in usersAdded"
          @selectUser="selectUser($event)"
          :key="i"
          :user="user"
          :usersSelected="usersSelected"
          :lang="lang"
        />
        <itinerary-users-modal-user
          v-for="(user, i) in usersToAdd"
          @selectUser="selectUser($event)"
          :key="i"
          :user="user"
          :usersSelected="usersSelected"
          :lang="lang"
        />
        <itinerary-users-modal-user
          v-if="usersToAdd.length === 0"
          @selectUser="selectUser($event)"
          :key="i"
          :user="newUser"
          :usersSelected="usersSelected"
          :lang="lang"
        />
      </div>

      <div class="itinerary-users-modal-btn">
        <button @click="invite()">{{ langFilter("invite") }}</button>
      </div>
    </div>
  </div>

  <div @click="closeModal()" class="acc-modal-backdrop"></div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  emits: ["closeModal", "addUsers", "addProjectUsers"],
  props: ["lang", "space", "project_id", "addingUsers"],
  data() {
    return {
      usersSelected: [],
      usersSelectedEmails: [],
      users: [],
      usersToAdd: [],
      usersAdded: [],
      usersInvited: [],
      usersInvitedEmails: [],
      searchUser: "",
      newUser: { id: 0, name: "", email: "" },
      error: false,
      traducciones: [
        {
          name: "inviteUsers",
          es: "INVITAR USUARIO AL PROYECTO",
          en: "INVITE USER TO PROJECT",
        },
        {
          name: "searchUser",
          es: "Buscar usuario existente o añadir email",
          en: "Search existing user or add email",
        },
        {
          name: "invite",
          es: "Invitar",
          en: "Invite",
        },
        {
          name: "noUsers",
          es: "No hay usuarios",
          en: "There are no users",
        },
        {
          name: "notValidEmail",
          es: "Por favor, introduce un email válido",
          en: "Please, enter a valid email",
        },
      ],
    };
  },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/getUsersBySpaceNotInProject/${this.space.id}/${this.project_id}`
      )
      .then((res) => {
        const resUsers = res.data.users;
        this.users = resUsers;
        this.usersToAdd = this.getUsers(resUsers);
      });
  },
  methods: {
    addUser(e) {
      e.preventDefault();
      const emailRegex =
        /^[a-zA-Z0-9._-]+([+][a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(this.searchUser)) {
        this.error = true;
        return;
      }
      if (!this.usersInvited.find((u) => u.email === this.searchUser)) {
        this.selectUser({ id: 0, name: "", email: this.searchUser });
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    userSeted(id) {
      return this.usersSelected.includes(id);
    },
    selectUser(user) {
      if (user.id === 0) {
        if (!this.usersInvited.find((u) => u.email === user.email)) {
          this.usersInvited.push(user);
          this.usersInvitedEmails.push(user.email);
        } else this.usersInvited = this.usersInvited.filter((u) => u !== user);
        this.filterUsers("");
      } else {
        if (this.userSeted(user.id)) {
          this.usersSelected = this.usersSelected.filter((u) => u !== user.id);
          this.usersToAdd.push(this.users.find((u) => u.id === user.id));
          this.usersAdded = this.usersAdded.filter((u) => u.id !== user.id);
        } else {
          this.usersSelected.push(user.id);
          this.usersSelectedEmails.push(user.email);
          this.usersToAdd = this.usersToAdd.filter((u) => u.id !== user.id);
          this.usersAdded.push(this.users.find((u) => u.id === user.id));
        }
      }
      document
        .querySelector(".itinerary-users-to-select-container")
        .scrollTo(0, 0);
    },
    invite() {
      if (this.project_id === null) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/newProject/getUsersInvited`, {
            users: this.usersSelected,
            invited: this.usersInvited,
            role: this.addingUsers,
          })
          .then((res) => {
            this.$emit("addProjectUsers", res.data);
            this.$emit("closeModal");
          });
      } else {
        axios.post(`${process.env.VUE_APP_API_URL}/addUsersToProject`, {
          users: this.usersSelected,
          invited: this.usersInvited,
          project_id: this.project_id,
          role: this.addingUsers,
          lang: this.lang,
          space_id: this.space.id,
          usersEmails: this.usersSelectedEmails,
          usersInvitedEmails: this.usersInvitedEmails,
        });
        // wait 1 second to update the users list
        setTimeout(() => {
          this.$emit("addUsers");
          this.$emit("closeModal");
        }, 1000);
      }
    },
    getUsers(users) {
      return users.filter((u) => !this.usersSelected.includes(u.id));
    },
    getUsersSelected(users) {
      return users.filter((u) => this.usersSelected.includes(u.id));
    },
    filterUsers(filter) {
      this.error = false;
      this.searchUser = filter;
      if (filter === "") this.usersToAdd = this.getUsers(this.users);
      else {
        this.newUser = { id: 0, name: "", email: filter };
        this.usersToAdd = this.getUsers(
          this.users.filter(
            (u) =>
              u.name.toLowerCase().includes(filter.toLowerCase()) ||
              u.email.toLowerCase().includes(filter.toLowerCase())
          )
        );
      }
    },
  },
  mixins: [translationMixin],
};
</script>
