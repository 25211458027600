<style lang="scss">
@import "../../../src/assets/css/smartPrinting.css";
</style>
<template>
  <div class="options-popup">
    <div class="options-content">
      <p>¿Qué elementos desea incluir en el documento?</p>
      <form
        :action="`${this.url}/SmartPrinting/template/${this.project_id}`"
        method="GET"
        target="_blank"
      >
        <div v-for="(data, i) in form" :key="i">
          <input
            type="checkbox"
            :id="data.id"
            :name="data.name"
            :checked="data.checked"
          />
          <label :for="data.id">{{ data.name }}</label>
        </div>
        <div class="sp-btns">
          <button type="submit" value="Descargar">Descargar</button>
          <button type="button" @click="$emit('close')">Cerrar</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Form from "./Data/SmartPrinting.json";
export default {
  components: {},
  props: ["project_id"],
  data() {
    return {
      form: Form,
      url: process.env.VUE_APP_API_STORAGE,
    };
  },
};
</script>
