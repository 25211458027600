<style>
@import "../../src/assets/css/cdti.css";
@import "../../src/assets/css/solution/solution.css";
</style>

<script setup>
import Parts from "../components/solution/SolutionComponent.vue";
</script>

<template>
  <Parts :space="spaces[0]" :lang="lang" :role="role" :cdti="true" />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
};
</script>
