<template>
  <div style="white-space: nowrap">
    <div
      class="itinerary-eachRow row-itinerary table-header-itinerary border-1px no-selection-itinerary"
      :id="id"
      @mouseover="passmouse()"
      @mouseout="outmouse()"
    >
      <div
        class="title-table-theme"
        style="overflow: hidden; text-overflow: ellipsis"
        @click="openSolutions"
      >
        <div
          v-if="notification"
          :id="'notification' + id"
          class="notification-itinerary"
        ></div>
        <div style="display: inline-flex; align-items: center">
          <div :id="'name_station' + id">{{ name }}</div>
          <div style="display: inline-block">
            <img
              :id="'imagen_flecha' + id"
              style="padding-left: 12px"
              :src="openSolution ? '/img/itinerario/arrow_down.svg' : '/img/itinerario/arrow_right.svg'"
            />
          </div>
        </div>
        <br />
        <div>
          <p
            :id="'description_station' + id"
            class="text-description-itinerary box-description-itinerary"
          >
            {{ description }}
          </p>
        </div>
      </div>
      <div class="text-enseña-datos-tabla-itinerary info-title-theme-table">
        <span
          :id="'resources_station' + id"
          @click="getResourcesStation(station_id)"
          data-bs-toggle="modal"
          data-bs-target="#recursosStationModal"
          >{{ resources }} {{ langFilter("recursos") }}</span
        >
      </div>
      <div class="text-enseña-datos-tabla-itinerary info-title-theme-table">
        <span :id="'apart_station' + id" class="">{{ percentage }}%</span>
      </div>
    </div>
	<div
	v-show="openSolution"
	style="margin-left: 10%">
    <itinerary-aparttable
      v-for="(solution, solutionIndex) in solutions_id"
      ref="itinerarySolution"
      :changeView="changeView"
      :key="solutionIndex"
      :id="
        'table_apart_' +
        solutions_id[solutionIndex].solution_id +
        '_' +
        station_id
      "
      :class="'apart-class-' + id + ' toHide'"
      :solution_id="solutions_id[solutionIndex].solution_id"
      :resources="solutions_id[solutionIndex].resources"
      :name="solutions_id[solutionIndex].name"
      :is_completed="solutions_id[solutionIndex].solution_status"
      :notification="
        user_role == 'Emprendedor'
          ? solutions_id[solutionIndex].interaction_tutor === 1 ||
            solutions_id[solutionIndex].ready_correct_tutor === 1
            ? true
            : false
          : solutions_id[solutionIndex].interaction_entrepreneur === 1 ||
            solutions_id[solutionIndex].ready_correct_entrepreneur === 1
          ? true
          : false
      "
      :filter="filters"
      :project_id="project_id"
      :station_id="station_id"
      :user_role="user_role"
      :lang="lang"
    ></itinerary-aparttable>
	</div>
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  props: [
    "station_id",
    "id",
    "resources",
    "name",
    "description",
    "state",
    "state_Cascade",
    "cascade",
    "project_id",
    "filter",
    "user_role",
    "lang",
    "percentage",
    "changeView",
  ],
  name: "ItineraryThemeTableComponent",
  data() {
    return {
      web: "",
      solutions_id: [],
      cascade_project: false,
      filters: this.filter,
      is_visible: "",
      filter_station: "",
	  openSolution: false,
	  notification: false,
      traducciones: [
        {
          name: "recursos",
          es: "recursos",
          en: "resources",
        },
        {
          name: "apartados",
          es: "Apartados",
          en: "Parts",
        },
      ],
    };
  },
  created() {
	this.onCreated_Stations();
  },
  methods: {
    getArrow() {
      if (!this.cascade_project) {
        return "/img/itinerario/arrow_right.svg";
      } else {
        return "/img/itinerario/arrow_right_gray.svg";
      }
    },
    filterResource() {
      this.filter = this.filters;
      this.$nextTick(() => {
        this.$refs.itinerarySolution.forEach((element) =>
          element.onCreated_Solution()
        );
      });
    },
    getResourcesStation(station) {
      this.$parent.getResourcesStation(station);
    },
    getResources(solution) {
      this.$parent.getResources(solution);
    },
    passmouse() {
      if (!this.cascade_project) {
        $("#" + this.id)
          .addClass("pass-mouse-in-div-itinerary")
          .removeClass("border-1px");
        $("#" + this.id).attr("style", "cursor: pointer");
      }
    },
    outmouse() {
      if (!this.cascade_project) {
        $("#" + this.id)
          .removeClass("pass-mouse-in-div-itinerary")
          .addClass("border-1px");
      }
    },
    openSolutions() {
      if (!this.cascade_project) {
		if(this.solutions_id.length == 0){
        axios
        .post(`${process.env.VUE_APP_API_URL}/itinerary/getSolution`, {
          project_id: this.project_id,
          station_id: this.station_id,
        })
		.then((response) => {
          this.solutions_id = response.data;
          this.solutions_id.forEach(function (element) {
            if (element.countSol !== 0) {
              if (element.answers / element.countSol == 1) {
                element.solution_status = true;
              } else {
                element.solution_status = false;
              }
            } else {
              element.solution_status = true;
            }
          });
          this.$nextTick(() => {
            this.$refs.itinerarySolution.forEach((element) =>
              element.onCreated_Solution()
            );
          });
        });
	}
		this.openSolution = !this.openSolution;
      }
    },
    hideEverySolution() {
      this.is_visible = 0;
      $(".toHide").hide();
      $("#imagen_flecha" + this.id).attr(
        "src",
        "/img/itinerario/arrow_right.svg"
      );
    },
    onCreated_Stations() {
		axios.post(`${process.env.VUE_APP_API_URL}/itinerary/setNotificationStation`,
			{
				project_id: this.project_id,
				station_id: this.station_id,
			}).then(response => {
				let not = '';
				if(this.user_role === 'Emprendedor')
					not = response.data.filter((element) => element.interaction_tutor == 1 || element.ready_correct_tutor == 1);
				else
					not = response.data.filter((element) => element.interaction_entrepreneur == 1 || element.ready_correct_entrepreneur == 1);
				if(not.length > 0){
					this.notification = true;
				}
			});
      let resource = this.state_Cascade.split("/");
      if (resource[2] != 0 && this.cascade && resource[0] != resource[1]) {
        this.cascade_project = true;
      }
      if (this.cascade_project) {
        $("#name_station" + this.id).addClass("color-gray-itinerary");
        $("#description_station" + this.id).removeClass(
          "text-description-itinerary"
        );
        $("#description_station" + this.id).addClass(
          "color-gray-description-itinerary"
        );
        $("#resources_station" + this.id).addClass("color-gray-itinerary");
        $("#apart_station" + this.id).addClass("color-gray-itinerary");
      }
      this.web = window.location.pathname;
      this.filter_station = this.filter;
      this.solutions_id = "";
    },
},
  mixins: [translationMixin],
};
</script>
