<template>
  <div
    id="tongue_container"
    class="sidebar-option-content tongue-container"
    @mouseover="hoverTongue()"
    @mouseout="unhoverTongue()"
  >
    <div id="tongue_bell" style="cursor: pointer">
      <img
        id="img_bell"
        :src="
          $parent.showNotificationsModal
            ? '/img/sidebar/bell.svg'
            : '/img/sidebar/bell_black.svg'
        "
        :class="{
          'tongue-bell-image': $parent.showNotificationsModal,
          'tongue-bell-image-false': !$parent.showNotificationsModal,
        }"
        @click="
          $parent.showNotificationsModal = !$parent.showNotificationsModal
        "
      />
    </div>
    <div
      id="tongue_modal"
      style="cursor: pointer"
      @click="$parent.showTongueModal = !$parent.showTongueModal"
    >
      <div>
        <img
          id="modal_img_tongue"
          :src="getAvatar(avatar)"
          class="tongue-avatar-image"
        />
      </div>
      <img
        id="modal_img_tongue"
        :src="require('@/assets/img/sidebar/tongue/arrow_down_tongue.svg')"
        class="tongue-arrow-image"
      />
    </div>
    <div
      v-if="$parent.showNotificationsModal === false && newNotifications > 0"
      class="tongue-notification"
    ></div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  props: ["newNotifications", "lang"],
  data() {
    return {
      bell: false,
      avatar: "/img/general/loading.gif",
      traducciones: [
        {
          name: "my_profile",
          es: "Mi perfil",
          en: "My profile",
        },
        {
          name: "log_out",
          es: "Cerrar sesión",
          en: "Log out",
        },
      ],
    };
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/general/getAvatarByUser`)
      .then((response) => {
        this.avatar = response.data.replace("https://www.", "https://");
      })
      .catch(() => {
        this.avatar = "/img/login_register/Avatar4.png";
      });
  },
  methods: {
    getAvatar(avatar) {
      if (avatar != null) {
        if (avatar.includes("http")) {
          return avatar;
        }
        return process.env.VUE_APP_API_STORAGE + avatar;
      } else {
        return "/img/login_register/Avatar4.png";
      }
    },
    changeBell() {
      $("#tongue_bell").toggleClass("tongue-background-notification");
      if (this.$parent.showNotificationsModal) {
        $("#img_bell").attr("src", "/img/sidebar/tongue/bell.svg");
      } else {
        $("#img_bell").attr("src", "/img/sidebar/tongue/bell_black.svg");
      }
    },
    hoverTongue() {
      $("#tongue_container").css("opacity", "1");
    },
    unhoverTongue() {
      $("#tongue_container").css("opacity", "0.5");
    },
  },
  mixins: [translationMixin],
};
</script>
