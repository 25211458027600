<template>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 40px;
      height: 100vh;
    "
  >
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 25px;
      "
    >
      <h2>
        Actualmente nos encontramos en mantenimiento, por favor vuelve a
        intentarlo mas tarde
      </h2>
      <h2>We are currently under maintenance. Please check back later.</h2>
    </div>
    <img :src="require('@/assets/img/sidebar/acc_logo.png')" />
  </div>
</template>

<script>
export default {
  name: "MaintenanceView",
};
</script>
