<template>
  <div class="padding-1">
    <trackModal :lang="lang" :track="trackSelected" :categories="categories" />
    <div class="row track-header">
      <div class="col-1"></div>
      <div id="categories-tracks" style="z-index: 2" class="col-7 padding-0">
        <!-- <trackCategory
          @catSelected="onCategorySelected"
          :categories="categories"
          :lang="lang"
          :isSearch="isSearch"
        /> -->
      </div>
      <div id="search-tracks" class="col-4">
        <search
          @searchQuery="onSearchQuery"
          :role="role"
          :lang="lang"
          :isAdmin="isAdmin"
          estilos=""
        />
      </div>
    </div>
    <div>
      <div v-show="isSearch == 0">
        <div id="no-tracks" class="hide">
          <div class="no-result-track align-center">
            <span class="tracks-title no-result-track-txt"
              >{{ langFilter("no_tracks") }}
            </span>
          </div>
        </div>
        <trackBanner
          @deleteTrack="deleteTrack($event)"
          @trackClick="onTrackClick($event)"
          :tracks="userTracks"
          :lang="lang"
          :title="'tusTracks'"
          :type="'1'"
          :catSelected="catSelected"
          :isAdmin="isAdmin"
          :space="spaces[0]"
        />
        <trackBanner
          @deleteTrack="deleteTrack($event)"
          @trackClick="onTrackClick($event)"
          :tracks="bestTracks"
          :lang="lang"
          :title="'best'"
          :type="'2'"
          :catSelected="catSelected"
          :isAdmin="isAdmin"
          :space="spaces[0]"
        />
        <trackBanner
          @deleteTrack="deleteTrack($event)"
          @trackClick="onTrackClick($event)"
          :tracks="allTracks"
          :lang="lang"
          :title="'catalogo'"
          :type="'3'"
          :catSelected="catSelected"
          :isAdmin="isAdmin"
          :space="spaces[0]"
        />
      </div>
      <trackSearch
        @trackClick="onTrackClick($event)"
        v-show="isSearch == 1"
        :lang="lang"
        :query="searchQuery"
        :allTracks="allTracks"
      />
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import generalMethodsMixin from "../../mixins/generalMethodsMixin.js";
import search from "../general/SearchComponent.vue";
import trackBanner from "./TrackBannerComponent.vue";
// import trackCategory from "./TrackCategoryComponent.vue";
import trackSearch from "./TrackSearchComponent.vue";
import trackModal from "./TrackModalComponent.vue";
import $ from "jquery";
import axios from "axios";

export default {
  components: {
    search,
    trackBanner,
    // trackCategory,
    trackSearch,
    trackModal,
  },

  props: ["role", "lang", "view", "spaces", "isAdmin"],

  data() {
    return {
      allTracks: [],
      bestTracks: [],
      userTracks: [],
      searchQuery: "",
      isSearch: 0,
      catSelected: 0,
      trackSelected: "",
      categories: [],
      selectorModal: 0,
      // traduccion
      traducciones: [
        {
          name: "error",
          es: "Error, por favor contacta con support@acceleralia.com.",
          en: "Error, please contact support@acceleralia.com.",
        },
        {
          name: "no_tracks",
          es: "No hay plantillas disponibles.",
          en: "No templates available.",
        },
      ],
    };
  },
  created() {
    this.getAllTracksCategories();
    this.getAllTracks();
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getTracksByUser`)
      .then((response) => {
        this.userTracks = response.data;
      });
  },
  mounted() {
    $(".carga").hide();
    $("#banner-main-container").show();
  },
  methods: {
    deleteTrack(track) {
      this.allTracks = this.allTracks.filter((t) => t.id != track);
      this.bestTracks = this.bestTracks.filter((t) => t.id != track);
      this.userTracks = this.userTracks.filter((t) => t.id != track);
    },
    onTrackClick(value) {
      this.trackSelected = value;
      this.selectorModal = 0;
      $("#select-modal-track").val(0);
    },
    onSearchQuery(value) {
      this.searchQuery = value;
      if (this.searchQuery != "") {
        this.isSearch = 1;
      } else {
        this.isSearch = 0;
      }
    },
    onCategorySelected(value) {
      this.catSelected = value;
    },
  },
  mixins: [translationMixin, generalMethodsMixin],
};
</script>
