<style>
@import "../../src/assets/css/projects/myProjects.css";
@import "../../src/assets/css/program/program.css";
</style>

<script setup>
import Programs from "../components/program/ProgramComponent.vue";
</script>

<template>
  <Programs ref="programs" :space="spaces[0]" :lang="lang" :create="role" />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
  data() {
    return {
      //Traducción
      traducciones: [
        {
          name: "Program",
          es: "Programa",
          en: "Program",
        },
        {
          name: "Project",
          es: "Proyecto",
          en: "Project",
        },
        {
          name: "Entrepreneur",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "Tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "Mentor",
          es: "Mentor",
          en: "Mentor",
        },
        {
          name: "Quote",
          es: "Citar",
          en: "Quote",
        },
      ],
    };
  },

  methods: {},
};
</script>
