    <style lang="css">
      @import "../../../src/assets/css/components/modal.css";
    </style>
<template>
  <div>
   
   

    <!-- Modal -->
    <div
      class="modal fade"
      :id="modal_id"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-background">
        <div class="modal-dialog" role="document" style="max-width:935px;width:935px;">
          <div class="modal-container" style="max-width:935px;width:935px;height:607px;margin-top:0%;">
            <modal-head :lang="lang" :headText="headText"></modal-head>

            <div class="modal-inputs">
              <div v-for="(input, index) in inputs" :key="index">
                <modal-inputs-1
                  v-if="input.id == 1"
                  :lang="lang"
                  :values="input.inputs"
                ></modal-inputs-1>

                <modal-inputs-2
                  v-if="input.id == 2"
                  :lang="lang"
                  :values="input.inputs"
                ></modal-inputs-2>

                <modal-inputs-3
                  v-if="input.id == 3"
                  :lang="lang"
                  :values="input.inputs"
                ></modal-inputs-3>
              </div>

              <modal-footer
                :lang="lang"
                :button="button"
                :button_id="button_id"
              ></modal-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  props: ["lang", "modal_id", "inputs", "headText", "button", "button_id"],

  data() {
    return {
      //Traducción
      traducciones: [],
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  mixins: [translationMixin],
};
</script>
