<template>
    <div
        :class="[
            { 'solution-answer-map-opened': mapOpened },
            ,
            'solution-div-footer',
        ]"
    >
        <button
            @click="sendCorrection"
            :disabled="!edited"
            :class="[
                { 'solution-btn-disabled': !edited },
                { 'solution-btn-enabled': edited },
                'solution-send-corrections',
            ]"
        >
            {{ langFilter("edited") }}
        </button>
        <br />
        <button @click="validateSolution" class="solution-validate-btn">
            {{ langFilter("validateSolutions") }}
        </button>
    </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
    props: ["lang", "mapOpened", "edited"],
    data() {
        return {
            traducciones: [
                {
                    name: "edited",
                    es: "Enviar Correcciones",
                    en: "Send Corrections",
                },
                {
                    name: "validateSolutions",
                    es: "Validar Soluciones",
                    en: "Validate Solutions",
                },
            ],
        };
    },
    methods: {
        sendCorrection() {
            this.$emit("sendCorrection");
        },
        validateSolution() {
            this.$emit("validateSolution", 4);
        },
    },
    mixins: [translationMixin],
};
</script>
