<template>
  <div class="select-track-container">
    <div
      v-if="isAdmin"
      @click="showhover(track.id)"
      v-outside="hidehover"
      class="option-button-tracks"
    >
      <img
        class="option-img-tracks"
        :src="require('@/assets/img/itinerario/options.svg')"
      />
    </div>
    <div v-show="deleteHover == track.id" class="box-project-tracks">
      <p class="box-track-option" @click="deleteTrack(track.id)">
        <img
          class="box-project-myprojects-image"
          :src="require('@/assets/img/projects/delete.svg')"
        />
        {{ langFilter("delTrack") }}
      </p>
    </div>
    <div
      :style="`background-image: url('${getTrackHeader(track.img)}'); `"
      data-bs-toggle="modal"
      data-bs-target="#trackModal"
      @mouseover="showDownBtn(track.id)"
      @mouseout="hideDownBtn(track.id)"
      :class="[
        {
          'search-track-div': this.searched,
        },
        'select-track-div',
        'banner-track-img',
      ]"
    >
      <div class="track-name-div">
        <span :class="`track-name track-name-${track.id}-${type}`">{{
          track.name
        }}</span>
      </div>
      <img
        :class="`down-button track-down-btn-${track.id}-${type}`"
        :src="require('@/assets/img/track/down-btn.png')"
        alt="down-btn"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
import $ from "jquery";

export default {
  props: ["track", "type", "searched", "lang", "isAdmin", "space"],

  data() {
    return {
      deleteHover: 0,
      // traduccion
      traducciones: [
        {
          name: "delTrack",
          es: "Eliminar",
          en: "Delete",
        },
      ],
    };
  },
  created() {
    if (this.track.img == "" || this.track.img == null) {
      this.track.img = "default.jpg";
    }
  },
  methods: {
    getTrackHeader(track) {
      return `${process.env.VUE_APP_API_STORAGE}/img/track_headers/${track}`;
    },
    showDownBtn(id) {
      $(`.track-down-btn-${id}-${this.type}`).show();
      $(`.track-name-${id}-${this.type}`).css("bottom", "27px");
    },
    hideDownBtn(id) {
      $(`.track-down-btn-${id}-${this.type}`).hide();
      $(`.track-name-${id}-${this.type}`).css("bottom", "8px");
    },
    showhover(id) {
      this.deleteHover == id ? (this.deleteHover = 0) : (this.deleteHover = id);
    },
    hidehover() {
      this.deleteHover = 0;
    },
    deleteTrack(id) {
      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/track/deleteTrack/${id}/${this.space.id}`
        )
        .then(() => {
          this.$emit("deleteTrack", id);
        });
    },
  },
  mixins: [translationMixin],
};
</script>
