<style>
@import "../../src/assets/css/recursos.css";
</style>
<template>
  <div>
    <div class="modal fade" id="recursosModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content modal-recursos center-modal">
          <!-- Nuevo recurso -->
          <span
            v-if="new_rec == 1 && lockedTrack == 0"
            class="new-rec"
            data-bs-toggle="modal"
            data-bs-target="#recursosModal"
            @click="$parent.modalResourcesOpen(parentChapter, parentPart)"
            >{{ langFilter("newRec") }}</span
          >
          <div class="filter-resources">
            <div class="filter-div">
              <span class="filter-title">{{ langFilter("filtrar") }}</span>
              <div class="div-all-rec">
                <div
                  @click="backSelDiv(filterRes.id)"
                  :id="'div-fil-' + filterRes.id"
                  v-for="(filterRes, filterResIndex) in filtersSel"
                  :key="filterResIndex"
                  class="row no-margin-row div-filter-name"
                >
                  <div class="col-2">
                    <input
                      @click="selFilter()"
                      :id="'cat-fil-' + filterRes.id"
                      class="res-check"
                      type="checkbox"
                    />
                  </div>
                  <div @click="checkBoxSel(filterRes.id)" class="col-10">
                    <label v-if="filterRes.id != 0" class="fil-nm">{{
                      filterRes.name
                    }}</label>
                    <label v-else class="fil-nm">{{
                      langFilter("noCat")
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
            <img
              class="vertical-lg"
              :src="require('@/assets/img/recursos/vertical_lg.png')"
            />
          </div>
          <div class="resources-content">
            <div class="row no-margin-row">
              <div class="col-5 align-right padding-0">
                <span
                  @click="changeSel(0)"
                  v-if="selection == 0"
                  class="res-opc res-opc-selected"
                  >{{ langFilter("recursos") }}</span
                >
                <span @click="changeSel(0)" v-else class="res-opc">{{
                  langFilter("recursos")
                }}</span>
              </div>
              <div class="col-1 align-center">
                <img :src="require('@/assets/img/recursos/vertical.png')" />
              </div>
              <div class="col-6 align-left padding-0">
                <span
                  @click="changeSel(1)"
                  v-if="selection == 1"
                  class="res-opc res-opc-selected"
                  >{{ langFilter("plantillas") }}</span
                >
                <span @click="changeSel(1)" v-else class="res-opc">{{
                  langFilter("plantillas")
                }}</span>
              </div>
              <div class="col-12 height-resources">
                <div class="no-rec" v-if="resourcesSel.length == 0">
                  {{ noHayMsg }}
                </div>
                <div
                  v-else
                  v-for="(category, indexCategory) in categorySelected()"
                  :key="indexCategory"
                >
                  <div v-if="resourceWithCategory(category.id)">
                    <img
                      v-if="indexCategory != 0"
                      class="horizontal-bar"
                      :src="
                        require('@/assets/img/recursos/horizontal_line.svg')
                      "
                    />

                    <div class="col-12 cat-tit-div">
                      <!-- <img v-if="(resourcesIndex != 0) && (filterFil() != resources.category_order)  " class="horizontal-bar" src="/img/recursos/horizontal_line.svg"> -->
                      <span class="cat-tit">{{ category.name }}</span>
                      <span class="cat-tit"></span>
                    </div>
                    <!-- <draggable v-model="resourcesSel" draggable=".resource"  :options="{ animation:500, handle:'.dragg-me-resource',ghostClass: 'ghost',chosenClass: 'chosen', group:'resources'+indexCategory}" > -->

                    <div
                      v-for="(resources, resourcesIndex) in resourcesSelFilter(
                        category.id
                      )"
                      :key="resourcesIndex"
                      class="col-12 padding-0 resource"
                      @mouseover="
                        resourcesHover(
                          1,
                          resourcesIndex,
                          checkFileType(resources.link),
                          indexCategory
                        )
                      "
                      @mouseout="resourcesHover(0, resources.id, indexCategory)"
                    >
                      <div class="row no-margin-row">
                        <div
                          :id="
                            'left-res-' + resourcesIndex + 'c' + indexCategory
                          "
                          class="col-1 padding-0 align-center resources-height left-box-res"
                        >
                          <img
                            :value="checkFileType(resources.link)"
                            class="img-rec"
                            :id="
                              'recursos_file_' +
                              resourcesIndex +
                              'c' +
                              indexCategory
                            "
                            v-if="checkFileType(resources.link) == 1"
                          />
                          <img
                            :value="checkFileType(resources.link)"
                            class="img-rec"
                            :id="
                              'recursos_file_' +
                              resourcesIndex +
                              'c' +
                              indexCategory
                            "
                            v-else-if="checkFileType(resources.link) == 2"
                            :src="require('@/assets/img/recursos/video.svg')"
                          />
                          <img
                            :value="checkFileType(resources.link)"
                            class="img-rec"
                            :id="
                              'recursos_file_' +
                              resourcesIndex +
                              'c' +
                              indexCategory
                            "
                            v-else
                            :src="require('@/assets/img/recursos/link.svg')"
                          />
                        </div>
                        <div
                          :id="
                            'center-res-' + resourcesIndex + 'c' + indexCategory
                          "
                          class="col-9 resources-height cent-box-res"
                        >
                          <span
                            class="res-nm"
                            :id="
                              'res_nm_' + resourcesIndex + 'c' + indexCategory
                            "
                            >{{ resources.name }}</span
                          >
                        </div>
                        <div
                          :id="
                            'right-res-' + resourcesIndex + 'c' + indexCategory
                          "
                          class="col-2 padding-0 resources-height right-box-res"
                        >
                          <div
                            class="pointer"
                            v-if="checkFileType(resources.link) != 3"
                          >
                            <img
                              :id="
                                'arrow_' + resourcesIndex + 'c' + indexCategory
                              "
                              class="'down-x float-left arrow-dw"
                              src="/img/recursos/download.svg"
                              style="margin-top: 4px"
                              v-if="new_rec != 1"
                            />

                            <span
                              v-if="new_rec == 1"
                              class="newProject-CloseIconContainer"
                              style="margin-top: 5px"
                              ><img
                                :src="
                                  require('@/assets/img/projects/modal_X.png')
                                "
                                class="newProject-DragIcon"
                                @click="
                                  $parent.minusResource(
                                    parentChapter,
                                    parentPart,
                                    resourcesIndex,
                                    resources.index,
                                    resources.selection,
                                    selection
                                  )
                                "
                            /></span>
                            <!-- <span v-if="new_rec==1" class="newProject-DragIconContainer" style="margin-top:5px;"><img src="/img/projects/drag_icon.png" class="newProject-DragIcon dragg-me-resource"></span> -->

                            <span
                              :class="
                                'down-x download-tit margin-tit down-' +
                                resourcesIndex
                              "
                              v-if="new_rec != 1"
                              @click="openResource(resources.link)"
                              >{{ langFilter("descargar") }}</span
                            >
                          </div>
                          <div class="pointer" v-else>
                            <span
                              v-if="new_rec == 1 && lockedTrack == 0"
                              class="newProject-CloseIconContainer"
                              style="margin-top: 5px"
                              ><img
                                :src="
                                  require('@/assets/img/projects/modal_X.png')
                                "
                                class="newProject-DragIcon"
                                @click="
                                  $parent.minusResource(
                                    parentChapter,
                                    parentPart,
                                    resourcesIndex,
                                    resources.index,
                                    resources.selection,
                                    selection
                                  )
                                "
                            /></span>
                            <!-- <span v-if="new_rec==1 && lockedTrack==0" class="newProject-DragIconContainer" style="margin-top:5px;"><img src="/img/projects/drag_icon.png" class="newProject-DragIcon dragg-me-resource"></span> -->
                            <span
                              v-if="new_rec != 1"
                              :class="
                                'down-x download-tit down-' + resourcesIndex
                              "
                              @click="openResource(resources.link)"
                              >{{ langFilter("link") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- </draggable>   -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  props: ["new_rec", "newResource"],
  data() {
    return {
      web: "acceleralia",
      token: "",
      lockedTrack: 0,
      parentChapter: 0,
      parentPart: 0,
      selection: 0,
      resourcesSel: [],
      resourcesCat: [],
      resourcesPlant: [],
      filtersRes: [],
      filtersTemp: [],
      filtersSel: [],
      filterSeted: 0,
      filtersChecked: [],
      changeSelec: 0,
      lang: "en",
      noHayMsg: "No hay recursos asociados",

      //Traducción
      traducciones: [
        {
          name: "filtrar",
          es: "Filtrar por:",
          en: "Filter by:",
        },
        {
          name: "recursos",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "plantillas",
          es: "Plantillas",
          en: "Templates",
        },
        {
          name: "descargar",
          es: "Descargar",
          en: "Download",
        },
        {
          name: "link",
          es: "Abrir enlace",
          en: "Open link",
        },
        {
          name: "newRec",
          es: "+Añadir nuevo recurso",
          en: "+Add new resource",
        },
        {
          name: "noCat",
          es: "Sin categoría",
          en: "No category",
        },
        {
          name: "noRec",
          es: "No hay recursos asociados",
          en: "There are not resources associated",
        },
        {
          name: "noTemp",
          es: "No hay plantillas asociadas",
          en: "There are not templates associated",
        },
      ],
    };
  },
  created() {
    this.lang = this.$parent.lang;

    if (this.new_rec == 0) {
      //var solution_id = ids[4];
      //this.getResources(solution_id);
    } else {
      //Setear el idioma
      this.lang = this.$parent.lang;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/general/getResourceCategoryProject`
        )
        .then((response) => {
          this.filtersRes = response.data;
          this.filtersSel = this.filtersRes;
        });

      this.resourcesPlant = 0;

      this.resourcesSel = this.resourcesCat;
      axios
        .post(`${process.env.VUE_APP_API_URL}/general/getTemplateCategory`)
        .then((response) => {
          this.filtersTemp = response.data;
        });
    }
  },

  methods: {
    resourcesSelFilter(category) {
      if (this.selection == 0) {
        return this.resourcesSel.filter((resource) => {
          if (resource.cat_id == category && resource.template == 0) {
            return resource;
          }
        });
      } else {
        return this.resourcesPlant.filter((resource) => {
          if (resource.cat_id == category && resource.template == 1) {
            return resource;
          }
        });
      }
    },
    categorySelected() {
      if (this.filtersChecked.length == 0) {
        return this.filtersSel;
      } else {
        return this.filtersSel.filter((filter) => {
          var paso = this.filtersChecked.findIndex((obj) => {
            return obj == filter.id;
          });

          if (paso !== -1) {
            return filter;
          }
        });
      }
    },
    resourceWithCategory(id) {
      var paso = this.resourcesSel.findIndex((resource) => {
        return resource.cat_id == id;
      });

      if (paso !== -1) {
        return true;
      } else {
        return false;
      }
    },

    getResources(solution_id) {
      this.selection = 0;
      axios
        .post(`${process.env.VUE_APP_API_URL}/general/getResourceCategory`, {
          solution_id: solution_id,
        })
        .then((response) => {
          this.filtersRes = response.data;
          this.filtersSel = this.filtersRes;
        });
      axios
        .post(`${process.env.VUE_APP_API_URL}/general/getTemplateCategory`, {
          solution_id: solution_id,
          lang: this.lang,
        })
        .then((response) => {
          this.resourcesPlant = response.data;
          this.filtersTemp = response.data;
        });

      axios
        .post(`${process.env.VUE_APP_API_URL}/general/getResourcesBySolution`, {
          solution_id: solution_id,
        })
        .then((response) => {
          this.resourcesCat = response.data;
          this.resourcesSel = this.resourcesCat;
        });

      axios
        .post(`${process.env.VUE_APP_API_URL}/general/getTemplatesBySolution`, {
          solution_id: solution_id,
        })
        .then((response) => {
          this.resourcesPlant = response.data;
        });
    },
    changeSel(sel) {
      this.selection = sel;
      if (sel == 0) {
        this.resourcesSel = this.resourcesCat;
        this.filtersSel = this.filtersRes;
        this.changeSelec = 1;
        this.noHayMsg = this.langFilter("noRec");
      } else {
        this.resourcesSel = this.resourcesPlant;
        this.filtersSel = this.filtersTemp;
        this.changeSelec = 0;
        this.noHayMsg = this.langFilter("noTemp");
      }
    },

    checkBoxSel(id) {
      if ($("#cat-fil-" + id).is(":checked")) {
        $("#cat-fil-" + id).prop("checked", false);
        $("#div-fil-" + id).removeClass("div-checked");
      } else {
        $("#cat-fil-" + id).prop("checked", true);
        $("#div-fil-" + id).addClass("div-checked");
      }
      this.selFilter(id);
    },

    backSelDiv(id) {
      if ($("#cat-fil-" + id).is(":checked")) {
        $("#div-fil-" + id).addClass("div-checked");
      } else {
        $("#div-fil-" + id).removeClass("div-checked");
      }
    },

    selFilter() {
      let count = 0;
      let check = "";
      let filterChecked = [];

      $(".res-check").each(function () {
        if ($(this).prop("checked") == true) {
          count += 1;
          check = this.id.split("-");
          filterChecked.push(parseInt(check[2]));
        }
      });

      if (count != 0) {
        this.filterSeted = 1;
      } else {
        this.filterSeted = 0;
      }

      this.filtersChecked = filterChecked;
    },

    resourcesHover(hover, id, type, category) {
      //Quitar todos los hovers
      $(".left-box-res").removeClass("left-hover");
      $(".cent-box-res").removeClass("center-hover");
      $(".right-box-res").removeClass("right-hover");
      $(".res-nm").removeClass("nm-hover");
      $(".down-x").removeClass("nm-hover");
      $(".arrow-dw").attr("src", "/img/recursos/download.svg");

      $(".img-rec").each(function (index, value) {
        switch ($(value).attr("value")) {
          case "1":
            $(this).attr("src", "/img/recursos/file.svg");
            break;

          case "2":
            $(this).attr("src", "/img/recursos/video.svg");
            break;

          case "3":
            $(this).attr("src", "/img/recursos/link.svg");
            break;
        }
      });

      //Asignar el hover que toca
      if (hover == 1) {
        $("#left-res-" + id + "c" + category).addClass("left-hover");
        $("#center-res-" + id + "c" + category).addClass("center-hover");
        $("#right-res-" + id + "c" + category).addClass("right-hover");
        $("#res_nm_" + id + "c" + category).addClass("nm-hover");
        $(".down-" + id + "c" + category).addClass("nm-hover");

        switch (type) {
          case 1:
            $("#recursos_file_" + id + "c" + category).attr(
              "src",
              "/img/recursos/file_hover.svg"
            );
            $("#arrow_" + id + "c" + category).attr(
              "src",
              "/img/recursos/download_hover.svg"
            );
            break;

          case 2:
            $("#recursos_file_" + id + "c" + category).attr(
              "src",
              "/img/recursos/video_hover.svg"
            );
            $("#arrow_" + id + "c" + category).attr(
              "src",
              "/img/recursos/download_hover.svg"
            );
            break;

          case 3:
            $("#recursos_file_" + id + "c" + category).attr(
              "src",
              "/img/recursos/link_hover.svg"
            );
            break;
        }
      }
    },

    checkFileType(file) {
      let video_doc = file.split("//");
      if (video_doc[1] != undefined) {
        video_doc = video_doc[1].split(".");
        if (
          video_doc[1] == "youtube" ||
          video_doc[1] == "youtu" ||
          video_doc[0] == "youtube" ||
          video_doc[0] == "youtu"
        ) {
          return 3;
        } else {
          //último valor del array para saber la extensión
          video_doc = video_doc[video_doc.length - 1];
          if (
            video_doc == "mp4" ||
            video_doc == "avi" ||
            video_doc == "mkv" ||
            video_doc == "flv" ||
            video_doc == "mov"
          ) {
            return 2;
          } else {
            return 1;
          }
        }
      } else {
        return 3;
      }
    },

    openResource(link) {
      if (link.includes("resources/")) {
        window.open(process.env.VUE_APP_API_STORAGE + "/" + link, "_blank");
      } else {
        if (link.includes("http")) {
          window.open(link, "_blank");
        } else {
          window.open("https://" + link, "_blank");
        }
      }
    },
    newProjectFiles(chapter, part, files, preResources, customer, lockedTrack) {
      this.lockedTrack = lockedTrack;
      this.parentChapter = chapter;
      this.parentPart = part;
      this.resourcesCat.splice(0);
      this.resourcesPlant = [];

      var resourcesCat = files.map((item) => ({
        cat_id: item.category,
        category_order: 1,
        space_id: customer,
        template: item.template,
        link: item.url,
        name: item.title,
        resource_category: this.getCategoryId(item.category),
        resource_order: 0,
        solution_id: 0,
        file: item.file,
        selection: 0,
      }));

      // this.resourcesCat= files;
      var index = 0;

      resourcesCat.forEach((element) => {
        if (element.template == 1) {
          element.index = index;

          this.resourcesPlant.push(element);
        } else {
          element.index = index;
          this.resourcesCat.push(element);
        }
        index = index + 1;
      });

      resourcesCat = preResources.map((item) => ({
        cat_id: item.resource_category,
        category_order: 1,
        space_id: item.space_id,
        template: item.template,
        link: item.link,
        name: item.name,
        resource_category: this.getCategoryId(item.resource_category),
        resource_order: item.resource_order,
        solution_id: 0,
        file: item.file,
        selection: 1,
      }));

      index = 0;

      resourcesCat.forEach((element) => {
        if (element.template == 1) {
          element.index = index;

          this.resourcesPlant.push(element);
        } else {
          element.index = index;

          this.resourcesCat.push(element);
        }
        index = index + 1;
      });

      this.filtersTemp = this.filtersSel;
    },
    getCategoryId(item) {
      var trueCategory = this.filtersSel.find((category) => {
        return category.id == item;
      });
      if (trueCategory.length) {
        return trueCategory.name;
      } else {
        return 0;
      }
    },
    filterFil() {
      let min = 99999999999;
      let order = 0;
      $.each(
        this.filtersChecked,
        function (key, value) {
          order = this.resourcesSel.find((x) => x.id === value).category_order;
          if (order < min) {
            min = order;
          }
        }.bind(this)
      );
      return min;
    },
  },
  mixins: [translationMixin],
};
</script>

<style>
.chosen {
  background-color: #ffffff;
  border: 2px solid#301889 !important;
  box-sizing: border-box;
  box-shadow: -1px 2px 4px 2px rgba(48, 24, 137, 0.2);
  border-radius: 12px;
}
.ghost {
  opacity: 1;
  border: 2px solid#301889;
  box-sizing: border-box;
  box-shadow: -1px 2px 4px 2px rgba(48, 24, 137, 0.2);
}
.no-margin-row {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
