<template>
  <div v-if="showAll" class="container-track-it">
    <div v-if="itinerary[0] != undefined">
      <div
        class="template-body-modal"
        v-for="(stat, statIndex) in itinerary"
        :key="statIndex"
      >
        <div
          @click="openCloseSol(stat[0].station_id)"
          class="track-it-div-template-chapter"
        >
          <img
            :id="`arrow-it-${stat[0].station_id}`"
            class="arrow-modal-template"
            :src="require('@/assets/img/track/template-arrow-right-modal.svg')"
            alt="arrow"
          />
          <span class="tema-modal-track"
            >{{ langFilter("chapter") }} {{ statIndex + 1 }}.
          </span>
          <b>{{ stat[0].station }}</b>
        </div>
        <div
          :class="`hide track-it-apt-div it-stations-from-${stat[0].station_id}`"
          v-for="(sol, solIndex) in itinerary[statIndex]"
          :key="solIndex"
        >
          <div
            class="track-it-apt-div-hover-template"
            @click="openCloseQuest(sol.solution_id)"
          >
            <img
              :id="`arrow-sol-${sol.solution_id}`"
              class="arrow-modal-template"
              :src="
                require('@/assets/img/track/template-arrow-right-modal.svg')
              "
              alt="arrow"
              v-if="questions[sol.solution_id] !== undefined"
            />
            <img
              class="arrow-modal-template"
              src="/img/track/template-arrow-right-grey-modal.svg"
              alt="arrow"
              v-else
            />
            <span class="tema-modal-track"
              >{{ langFilter("part") }} {{ solIndex + 1 }}.
            </span>
            <b>{{ sol.solution }}</b>
          </div>
          <div
            :class="`hide track-it-div-template template-it-quest-div it-solutions-from-${sol.solution_id}`"
            v-for="(quest, questIndex) in questions[sol.solution_id]"
            :key="questIndex"
          >
            <span class="tema-modal-track">
              {{ langFilter("question") }}
              {{ questIndex + 1 }}.
            </span>
            <b>{{ quest.question }}</b>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-left: 44px" class="data-track-name" v-else>
      {{ langFilter("no_itinerary") }}
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  props: ["lang", "trackStatSol", "track", "itinerary", "questions"],
  data() {
    return {
      selShow: [],
      itineraries: [],
      showAll: false,
      // traduccion
      traducciones: [
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
        {
          name: "select",
          es: "Seleccionar Track",
          en: "Select Track",
        },
        {
          name: "no_itinerary",
          es: "No hay plantillas",
          en: "No templates",
        },
        {
          name: "chapter",
          es: "Tema",
          en: "Chapter",
        },
        {
          name: "part",
          es: "Apartado",
          en: "Part",
        },
      ],
    };
  },
  created() {
    this.itineraries = this.trackStatSol.filter(
      (item) => item.track_id == this.track.id
    );
  },
  methods: {
    //abre o cierra las estaciones
    openCloseSol(id) {
      if ($(".it-stations-from-" + id).is(":visible")) {
        $(".it-stations-from-" + id).hide();
        $("#arrow-it-" + id).attr(
          "src",
          "/img/track/template-arrow-right-modal.svg"
        );
      } else {
        $(".it-stations-from-" + id).show();
        $("#arrow-it-" + id).attr(
          "src",
          "/img/track/template-arrow-down-modal.svg"
        );
      }
    },

    //abre o cierra las preguntas
    openCloseQuest(id) {
      if ($(".it-solutions-from-" + id).is(":visible")) {
        $(".it-solutions-from-" + id).hide();
        if (this.questions[id] !== undefined)
          $("#arrow-sol-" + id).attr(
            "src",
            "/img/track/template-arrow-right-modal.svg"
          );
      } else {
        $(".it-solutions-from-" + id).show();
        if (this.questions[id] !== undefined)
          $("#arrow-sol-" + id).attr(
            "src",
            "/img/track/template-arrow-down-modal.svg"
          );
      }
    },
  },
  watch: {
    itinerary: function () {
      this.showAll = false;
      setTimeout(() => {
        this.showAll = true;
      }, 100);
    },
  },
  mixins: [translationMixin],
};
</script>
