<template>
  <div class="container itinerary-users-container">
    <div class="row itinerary-users-container-row">
      <div class="col-1">
        <img
          class="itinerary-user-img"
          :src="getAvatar(user.avatar)"
          alt="user"
        />
      </div>
      <div class="col-4">{{ langFilter(user.name) }} {{ user.lastname }}</div>
      <div class="col-4">{{ user.email }}</div>
      <div
        :class="`col-xl-2 col-1 ${
          editing ? 'itinerary-editing-users' : 'itinerary-user-type-container'
        }`"
      >
        <span
          :class="`itinerary-user-type ${user.id === 0 ? 'sent-purple' : ''}`"
        >
          {{ langFilter(user.id !== 0 ? user.type : "sent") }}
        </span>
        <img
          v-if="editing && user.id !== 0"
          @click="removeUser()"
          :src="require(`@/assets/img/newitinerary/x.svg`)"
          alt="delete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  emits: ["addUser", "removeUser"],
  props: ["lang", "editing", "title", "type", "user", "i"],
  data() {
    return {
      traducciones: [
        {
          name: "member",
          es: "Miembro",
          en: "Member",
        },
        {
          name: "tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "sent",
          es: "Invitación enviada",
          en: "Invitation sent",
        },
        {
          name: "pending",
          es: "Pendiente",
          en: "Pending",
        },
      ],
    };
  },
  methods: {
    removeUser() {
      this.$emit("removeUser", { type: this.type, index: this.i });
    },
    getAvatar(avatar) {
      if (avatar.includes("http"))
        return avatar.replace("https://www.", "https://");
      return `${process.env.VUE_APP_API_STORAGE}${avatar}`;
    },
  },
  mixins: [translationMixin],
};
</script>
