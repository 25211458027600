<script setup>
import Forum from "../components/forum/Forum2Component.vue";
</script>

<template>
  <div v-if="user_id != '' && space_id != '' && ruta != ''">
    <Forum
      :user_id="user_id"
      :space_id="space_id"
      :ruta="ruta"
      :space="spaces[0]"
      :lang="lang"
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user_id: "",
      space_id: "",
      ruta: "",
    };
  },
  created() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/forum-index`)
      .then((response) => {
        this.user_id = response.data.user_id;
        this.space_id = response.data.space_id;
        const page = window.location.pathname;
        const pagename = page.split("/");

        if (window.location.pathname == "/forum") {
          this.ruta = 1;
        } else if (
          window.location.pathname.includes("/forum/") &&
          pagename[2]
        ) {
          this.ruta = 2;
        } else if (
          window.location.pathname.includes("/forum-thread/") &&
          pagename[2]
        ) {
          this.ruta = 3;
        } else if (window.location.pathname == "/bulletin") {
          this.ruta = 4;
        } else if (
          window.location.pathname.includes("/bulletin/") &&
          pagename[2]
        ) {
          this.ruta = 5;
        }
      })
      .catch(() => {});
  },
};
</script>

<style>
@import "../../src/assets/css/forum/forum.css";
</style>
