<template>
  <div>
    <admin-search
      :roles="roles"
      :space="space"
      :allUsers="allUsers"
      :lang="lang"
    />
    <div v-if="initialLoad">
      <img
        class="loading-ap"
        :src="require('@/assets/img/general/carga.gif')"
      />
    </div>
    <div v-else-if="users.length === 0" class="no-users-ap">
      {{ langFilter("no-users") }}
    </div>
    <div v-else class="padding-margin-0 container-users-ap">
      <div class="row">
        <div class="col header-users-ap">
          <div class="location-name">
            <span class="names-ap">
              {{ langFilter("name") }}
            </span>
            <img
              @click="sortUsers('name')"
              class="arrow-up-ap"
              :src="require('@/assets/img/admin_panel/arrow-up.svg')"
            />
            <img
              @click="sortUsers('name')"
              class="arrow-down-ap"
              :src="require('@/assets/img/admin_panel/arrow-down.svg')"
            />
          </div>
        </div>
        <div class="col header-users-ap">
          <div class="location-name">
            <span class="names-ap"> Email </span>
            <img
              @click="sortUsers('email')"
              class="arrow-up-ap arrow-right-ap"
              :src="require('@/assets/img/admin_panel/arrow-up.svg')"
            />
            <img
              @click="sortUsers('email')"
              class="arrow-down-ap arrow-right-ap"
              :src="require('@/assets/img/admin_panel/arrow-down.svg')"
            />
          </div>
        </div>

        <div class="col header-users-ap">
          <div class="location-name" style="display: -webkit-inline-box">
            <span class="names-ap">
              {{ langFilter("role") }}
            </span>
            <img
              @click="sortUsers('role')"
              class="arrow-up-ap arrow-right-ap"
              :src="require('@/assets/img/admin_panel/arrow-up.svg')"
            />
            <img
              @click="sortUsers('role')"
              class="arrow-down-ap arrow-right-ap"
              :src="require('@/assets/img/admin_panel/arrow-down.svg')"
            />
            <div style="position: relative">
              <img
                @mouseover="showInfo = true"
                @mouseout="showInfo = false"
                class="info-ap"
                :src="require('@/assets/img/admin_panel/info.svg')"
                alt="info"
              />
              <div v-if="showInfo" class="info-div-ap">
                <div class="info-modal-arrow-ap" />
                <div class="info-modal-ap">
                  {{ langFilter("infoMsg") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasDeactivatedUsers" class="col-2 header-users-ap">
          <div class="col header-users-ap">
            <div class="location-name">
              <span class="names-ap">
                {{ langFilter("deactivated_date") }}
              </span>
              <img
                @click="sortUsers('deactivated_at')"
                class="arrow-up-ap"
                :src="require('@/assets/img/admin_panel/arrow-up.svg')"
              />
              <img
                @click="sortUsers('deactivated_at')"
                class="arrow-down-ap"
                :src="require('@/assets/img/admin_panel/arrow-down.svg')"
              />
            </div>
          </div>
        </div>

        <div class="col-1 header-users-ap"></div>
      </div>

      <div
        v-for="(user, userIndex) in users"
        :key="userIndex"
        :class="{
          'background-users-ap': userIndex % 2 === 0,
        }"
        class="row tbl-users-name-ap"
      >
        <div class="col">
          <table>
            <td>
              <img class="img-user-ap" :src="getAvatar(user.avatar)" />
            </td>
            <td class="name-tbl-ap">{{ user.name }}</td>
          </table>
        </div>
        <div class="col email-role-tbl-ap">{{ user.email }}</div>
        <div class="col email-role-tbl-ap">
          <select
            @change="onChangeRole($event, user.id)"
            class="select-change-role-ap"
          >
            <option
              v-for="(role, roleIndex) in roles"
              :key="roleIndex"
              :value="role.id"
              :selected="user.role === role.id"
            >
              {{ langFilter(role.name) }}
            </option>
          </select>
        </div>

        <div v-if="hasDeactivatedUsers" class="col-2 email-role-tbl-ap">
          {{ formatDate(user.deactivated_at) }}
        </div>

        <div class="col-1 email-role-tbl-ap" style="position: relative">
          <button
            v-outside="closeDelete"
            @click="
              deleteUser === userIndex
                ? (deleteUser = '')
                : (deleteUser = userIndex)
            "
            class="btn-user-ap"
          >
            <img
              :src="require('@/assets/img/admin_panel/points.svg')"
              class="btn-user-ap-img"
              alt="options"
            />
          </button>
          <button
            v-if="deleteUser === userIndex"
            @click="openModalConfirmationBack(user)"
            class="delete-user-btn-ap"
          >
            <!-- @click="deleteUserFunction(user.id)" -->
            {{ langFilter("delete") }}
          </button>
        </div>
      </div>
    </div>
    <confirmation :lang="lang" ref="modalConfirmation" />
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import moment from "moment";
import AdminSearch from "./AdminPanelSearchComponent.vue";
import Confirmation from "../ConfirmationComponent.vue";
import { Modal } from "bootstrap";
import axios from "axios";
export default {
  components: { Confirmation, AdminSearch },
  props: ["space", "lang"],
  data() {
    return {
      users: [],
      allUsers: [],
      roles: [],
      changeInput: "",
      deleteUser: "",
      initialLoad: true,
      showInfo: false,
      userToDelete: 0,
      loged_user: 0,
      sorted: "name",
      traducciones: [
        {
          name: "users",
          es: "Usuarios",
          en: "Users",
        },
        {
          name: "name",
          es: "Nombre",
          en: "Name",
        },
        {
          name: "role",
          es: "Rol",
          en: "Role",
        },
        {
          name: "admin",
          es: "Admin",
          en: "Admin",
        },
        {
          name: "member",
          es: "Miembro",
          en: "Member",
        },
        {
          name: "deactivated_date",
          es: "Fecha de baja",
          en: "Deactivation date",
        },
        {
          name: "delete",
          es: "Eliminar",
          en: "Delete",
        },
        {
          name: "no-users",
          es: "No hay usuarios",
          en: "There are no users",
        },
        {
          name: "infoMsg",
          es: "El rol determinará las interacciones permitidas a los usuarios relativas a los permisos configurados",
          en: "The role determines the interactions allowed to the users related to the configured permissions",
        },
        {
          name: "fromSpace",
          es: "del espacio",
          en: "from space",
        },
      ],
    };
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/adminpanel/getUsers`, {
        space_id: this.space.id,
      })
      .then((response) => {
        this.users = response.data;
        this.allUsers = response.data;
      });
    axios
      .post(`${process.env.VUE_APP_API_URL}/adminpanel/getRoles`, {
        space_id: this.space.id,
      })
      .then((response) => {
        this.roles = response.data;
        this.initialLoad = false;
      });
    axios
      .get(`${process.env.VUE_APP_API_URL}/general/getLogedUser`)
      .then((response) => {
        this.loged_user = response.data;
      });
  },
  computed: {
    hasDeactivatedUsers() {
      return this.users.some((user) => user.deactivated_at !== null);
    },
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format("DD-MM-YY") : "";
    },
    closeDelete(el) {
      if (
        el.target.className !== "btn-user-ap-img" &&
        el.target.className !== "btn-user-ap"
      ) {
        this.deleteUser = "";
      }
    },
    openModalConfirmationBack(user) {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.userToDelete = user.id;
      this.$refs.modalConfirmation.confirmationMessage =
        "Are you sure you want to remove Name Surname?";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "yes, delete";
      this.$refs.modalConfirmation.variable =
        user.name + " " + this.langFilter("fromSpace") + "?";
      myModal.toggle();
    },
    onChangeRole(event, id) {
      const role = parseInt(event.target.value);
      axios
        .patch(`${process.env.VUE_APP_API_URL}/adminpanel/updateUserRole`, {
          user_id: id,
          space_id: this.space.id,
          role: role,
        })
        .then(() => {
          if (this.loged_user === id) {
            window.location.reload();
          }
          const i = this.users.findIndex((o) => o.id === id);
          this.users[i].role = role;
        });
    },
    confirmTrackContinue() {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      axios
        .delete(`${process.env.VUE_APP_API_URL}/adminpanel/deleteUser`, {
          params: {
            user_id: this.userToDelete,
            space_id: this.space.id,
          },
        })
        .then(() => {
          if (this.loged_user === this.userToDelete) {
            window.location.replace(`${process.env.VUE_APP_FRONTEND_URL}/home`);
          }
          myModal.toggle();
          this.deleteUser = "";
          this.allUsers = this.allUsers.filter(
            (o) => o.id !== this.userToDelete
          );
        });
    },
    confirmTrackCancel() {
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.toggle();
    },
    sortUsers(type) {
      if (this.sorted == type) {
        const nonNullUsers = this.users.filter((user) => user[type] !== null);
        nonNullUsers.reverse();
        const nullUsers = this.users.filter((user) => user[type] === null);
        this.users = [...nonNullUsers, ...nullUsers];
      } else {
        this.sorted = type;
        if (type !== "role") {
          if (type === "deactivated_at") {
            this.users.sort((a, b) => {
              if (a[type] === null && b[type] !== null) {
                return 1;
              }
              if (a[type] !== null && b[type] === null) {
                return -1;
              }
              return new Date(a[type]) - new Date(b[type]);
            });
          } else {
            this.users.sort((a, b) => {
              if (a[type].toLowerCase() < b[type].toLowerCase()) {
                return -1;
              }
              if (a[type].toLowerCase() > b[type].toLowerCase()) {
                return 1;
              }
              return 0;
            });
          }
        } else {
          this.users.sort((a, b) => {
            if (a[type] < b[type]) {
              return -1;
            }
            if (a[type] > b[type]) {
              return 1;
            }
            return 0;
          });
        }
      }
    },
    getAvatar(avatar) {
      if (avatar != undefined && avatar != "") {
        const av = avatar.replace("https://www.", "https://");
        if (av.includes("/img/login_register") || av.includes("https://")) {
          return av;
        } else {
          return process.env.VUE_APP_API_STORAGE + av;
        }
      } else {
        return process.env.VUE_APP_API_STORAGE + "/img/users/avatar4.png";
      }
    },
  },
  mixins: [translationMixin],
};
</script>
