<template>
  <div class="col-md-4 col-sm-5 justify-right">
    <button v-if="editing" @click="addQuestion(i)" class="itinerary-add-btn">
      +
    </button>
    <div>{{ langFilter("question") }} {{ i + 1 }}.</div>
  </div>
  <div class="col-md-6 col-sm-5">
    <div class="itinerary-question-content">
      <input
        v-if="editing"
        @input="editName(i, $event.target.value)"
        :class="`itinerary-input-theme ${
          showError && question.name === '' ? 'itinerary-user-error-input' : ''
        }`"
        :value="question.name"
        type="text"
      />
      <h5 v-else>{{ question.name }}</h5>
    </div>
  </div>
  <div class="col-2 itinerary-questions-options">
    <template v-if="editing">
      <img
        @click="openEditQuestion(i)"
        :src="require(`@/assets/img/newitinerary/edit.svg`)"
        alt="edit"
      />
      <img
        @click="removeQuestion(i)"
        :src="require(`@/assets/img/newitinerary/x.svg`)"
        alt="delete"
      />
    </template>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  emits: ["addQuestion", "removeQuestion", "editQuestionName", "editQuestion"],
  props: ["lang", "i", "editing", "question", "showError"],
  data() {
    return {
      traducciones: [
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
      ],
    };
  },
  methods: {
    editName(i, name) {
      this.$emit("editQuestionName", { i, name });
    },
    addQuestion(i) {
      this.$emit("addQuestion", i);
    },
    removeQuestion(i) {
      this.$emit("removeQuestion", i);
    },
    openEditQuestion(i) {
      this.$emit("editQuestion", i);
    },
    editQuestion(question) {
      this.$emit("editQuestion", question);
    },
  },
  mixins: [translationMixin],
};
</script>
