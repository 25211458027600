<style>
@import "../../src/assets/css/projects/newProject.css";
@import "../../src/assets/css/projects/newProjectV2.css";
@import "../../src/assets/css/home/home.css";
</style>

<script setup>
import NewProjectV2 from "../components/projects/NewProjectsV2Component.vue";
</script>

<template>
  <NewProjectV2 :space="spaces[0]" :role="role" :lang="lang" :user_id="user_id" />
</template>

<script>
export default {
  props: ["spaces", "role", "lang", "user_id"],
};
</script>
