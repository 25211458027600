<template>
  <div class="container">
    <template v-if="formSent">
      <MessageSentComponent
        :lang="lang"
        type="feedback"
        @return-to-form="handleReturnToForm"
      />
    </template>
    <template v-else>
      <p>
        {{ langFilter("textFeedback") }}
        <br />
        {{ langFilter("textFeedback2") }}
        <br />
      </p>
      <p>
        {{ langFilter("textFeedback3") }}
        <a href="mailto:feedback@acceleralia.com">feedback@acceleralia.com</a>
      </p>
      <p>
        {{ langFilter("formOption") }}
      </p>
      <FormSupport
        @submit="handleFormSubmit"
        :lang="lang"
        :email="email"
        :name="name"
      />
    </template>
  </div>
</template>

<script>
import FormSupport from "./FormSupportComponent.vue";
import MessageSentComponent from "./MessageSentComponent.vue";
import translationMixin from "@/mixins/translationMixin";
import axios from "axios";

export default {
  components: {
    FormSupport,
    MessageSentComponent,
  },
  props: ["lang", "email", "name"],
  data() {
    return {
      formSent: false,
      traducciones: [
        {
          name: "textFeedback",
          es: "Tu opinión es muy importante para nosotros!",
          en: "Your opinion is very important to us!",
        },
        {
          name: "textFeedback2",
          es: "Si tienes alguna sugerencia, funcionalidad que necesites o idea de mejora, nos encantaría escucharla.",
          en: " If you have any suggestions, needed functionality, or improvement ideas, we would love to hear from you.",
        },
        {
          name: "textFeedback3",
          es: "Por favor, envíanos tus comentarios a:",
          en: "Please send us your feedback to:",
        },
        {
          name: "formOption",
          es: "Otra opción que te proponemos es que completes el formulario de contacto:",
          en: "Another option we suggest is to complete the contact form:",
        },
      ],
    };
  },
  methods: {
    handleFormSubmit({ message }) {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/customer-support-mail/send-email`,
          {
            message: message,
            email: this.email,
            name: this.name,
            type: "feedback",
          }
        )
        .then(() => {
          console.log("Email enviado con exito");
          this.formSent = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleReturnToForm() {
      this.formSent = false;
    },
  },
  mixins: [translationMixin],
};
</script>
