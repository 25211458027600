<style lang="css">
@import "../../../src/assets/css/forum/forum.css?v=1";
@import "../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
</style>
<template>
  <div>
    <div class="no-selection-itinerary" id="header" style="padding-left: 4%">
      <td nowrap>
        <img
          class="logo-header"
          :src="require('@/assets/img/sidebar/hovers/icon-forum.svg')"
        />
        <h1
          style="padding-bottom: 4px; cursor: pointer"
          class="css-titulo-header margin-title-header"
          @click="query_posts(true)"
        >
          {{ langFilter("Forum") }}
        </h1>
      </td>
      <div style="margin-bottom: 70px"></div>
    </div>
    <div class="forum-container">
      <!-- TOOLS/FILTERS -->
      <div class="forum-tools-container">
        <div class="forum-guide" v-if="view < 4 && view > 0">
          <p>
            <span
              @click="query_posts(true)"
              :class="view == 1 ? 'bulletin-active' : ''"
              >{{ langFilter("Forum") }}</span
            ><span v-if="view > 1 && view < 4"> > </span
            ><span
              v-if="view > 1 && view < 4"
              @click="return_subposts()"
              :class="view == 2 ? 'bulletin-active' : ''"
              >{{ name_of_post_selected }}</span
            ><span v-if="view > 2 && view < 4"> > </span
            ><span
              v-if="view > 2 && view < 4"
              :class="view == 3 ? 'bulletin-active' : ''"
              >{{ normal_posts[0].title }}</span
            >
          </p>
        </div>
        <!-- <div class="forum-guide" v-if="view==3"><p><span @click="return_subposts()">{{langFilter('Subtopic')}}:</span> {{posts[0].title}}</p></div> -->
        <div class="forum-guide" v-if="view == 4 || view == 5">
          <p>
            <span @click="query_posts(true)">{{ langFilter("Forum") }}</span>
            |
            <span class="bulletin-active" @click="query_bulletin()">{{
              langFilter("Bulletin board")
            }}</span>
          </p>
        </div>

        <div class="forum-tools">
          <div class="tool-search" v-if="view !== 5">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image-search"
            >
              <circle cx="8.5" cy="8" r="7" stroke="#301889" stroke-width="2" />
              <line
                x1="13.9142"
                y1="14"
                x2="18.5"
                y2="18.5858"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>

            <input
              class="tool-search-input"
              type="text"
              @focus="search_bar(true)"
              :placeholder="langFilter('Search')"
              @blur="search_bar(false)"
              v-model="searchPost"
            />
          </div>

          <div
            id="post-modal-button"
            v-if="view == 1"
            :class="'tool-add ' + (addPost ? 'tool-active' : '')"
            @click="addNewPostTool()"
          >
            <svg
              v-if="addPost"
              width="17"
              height="17"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image"
            >
              <line
                x1="8.5"
                y1="1"
                x2="8.5"
                y2="15"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="15.5"
                y1="8"
                x2="1.5"
                y2="8"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <svg
              v-if="!addPost"
              width="17"
              height="17"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image"
            >
              <line
                x1="8.5"
                y1="1"
                x2="8.5"
                y2="15"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="15.5"
                y1="8"
                x2="1.5"
                y2="8"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div
            id="subpost-modal-button"
            v-if="view == 2"
            :class="'tool-add ' + (addPost ? 'tool-active' : '')"
            data-bs-toggle="modal"
            data-bs-target="#component-modal"
          >
            <svg
              v-if="addPost"
              width="17"
              height="17"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image"
            >
              <line
                x1="8.5"
                y1="1"
                x2="8.5"
                y2="15"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="15.5"
                y1="8"
                x2="1.5"
                y2="8"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <svg
              v-if="!addPost"
              width="17"
              height="17"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image"
            >
              <line
                x1="8.5"
                y1="1"
                x2="8.5"
                y2="15"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="15.5"
                y1="8"
                x2="1.5"
                y2="8"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div
            id="bulletin-modal-button"
            v-if="view == 4 || view == 5"
            :class="'tool-add ' + (addPost ? 'tool-active' : '')"
            data-bs-toggle="modal"
            data-bs-target="#bulletin-modal"
          >
            <svg
              v-if="addPost"
              width="17"
              height="17"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image"
            >
              <line
                x1="8.5"
                y1="1"
                x2="8.5"
                y2="15"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="15.5"
                y1="8"
                x2="1.5"
                y2="8"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <svg
              v-if="!addPost"
              width="17"
              height="17"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image"
            >
              <line
                x1="8.5"
                y1="1"
                x2="8.5"
                y2="15"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="15.5"
                y1="8"
                x2="1.5"
                y2="8"
                stroke="#301889"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div
            :class="'tool-fav ' + (filterFav ? 'tool-active' : '')"
            @click="(filterFav = !filterFav), active_filter_fav()"
          >
            <svg
              v-if="!filterFav"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image"
            >
              <path
                class="fill-none"
                d="M9.85684 1.45792L11.2844 5.87683C11.5678 6.75411 12.3846 7.34856 13.3065 7.34856H17.9365C18.2993 7.34856 18.4507 7.81232 18.1578 8.02634L14.3997 10.7724C13.6592 11.3135 13.3494 12.2687 13.6314 13.1414L15.0648 17.5786C15.1766 17.9244 14.7802 18.2111 14.4867 17.9966L10.7537 15.2689C10.007 14.7232 8.99303 14.7232 8.24627 15.2689L4.51326 17.9966C4.21978 18.2111 3.82343 17.9244 3.93517 17.5786L5.36865 13.1414C5.6506 12.2687 5.3408 11.3135 4.60028 10.7724L0.842217 8.02634C0.549328 7.81232 0.700713 7.34856 1.06346 7.34856H5.69348C6.6154 7.34856 7.43216 6.75411 7.71557 5.87683L9.14316 1.45792C9.25503 1.11163 9.74497 1.11163 9.85684 1.45792Z"
                stroke="#301889"
                stroke-width="1.25"
              />
            </svg>
            <svg
              v-if="filterFav"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tool-image"
            >
              <path
                class="fill-none"
                d="M8.54843 1.44547C8.84676 0.522032 10.1532 0.522032 10.4516 1.44547L11.8792 5.86438C12.0792 6.48363 12.6558 6.90325 13.3065 6.90325H17.9365C18.9039 6.90325 19.3076 8.13995 18.5265 8.71066L14.7685 11.4567C14.2457 11.8387 14.0271 12.5129 14.2261 13.129L15.6596 17.5661C15.9575 18.4885 14.9006 19.2528 14.118 18.681L10.385 15.9532C9.85786 15.568 9.14214 15.568 8.61502 15.9532L4.882 18.681C4.09941 19.2528 3.04247 18.4884 3.34043 17.5661L4.77391 13.129C4.97294 12.5129 4.75426 11.8387 4.23153 11.4567L0.473474 8.71066C-0.307563 8.13995 0.0961282 6.90325 1.06346 6.90325H5.69348C6.34425 6.90325 6.92078 6.48363 7.12084 5.86438L8.54843 1.44547Z"
                fill="white"
              />
            </svg>
          </div>

          <div class="forum-list-programs-dropdown" v-if="view == 4">
            <div
              class="forum-FilterButton"
              style="width: 63px"
              id="myProjects-filter"
              @click="openPopUp"
            >
              <div :class="'tool-filter ' + (addPost ? 'tool-active' : '')">
                <p>{{ langFilter("Filter") }}</p>
              </div>
            </div>

            <div class="forum-list-programs-dropdown-content">
              <input
                v-model="searchProjectProgram"
                class="forum-list-programs-title"
                :placeholder="langFilter('Search')"
              />
              <div class="forum-dropdown-container">
                <div class="forum-dropdown-content">
                  <div class="forum-dropdown-title">
                    <p>{{ langFilter("Programs") }}</p>
                  </div>
                  <!-- <p class="myProjects-list-programs-title">{{langFilter('Search program')}}</p> -->
                  <ul>
                    <li
                      v-for="(program, indexProgram) in search_programs"
                      :key="indexProgram"
                    >
                      <label>
                        <span>{{ program.name }}</span>
                        <input
                          type="checkbox"
                          name="programChecked"
                          v-model="selectedProgramsList"
                          :value="program"
                        />
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="forum-dropdown-content">
                  <div class="forum-dropdown-title">
                    <p>{{ langFilter("Projects") }}</p>
                  </div>
                  <!-- <p class="myProjects-list-programs-title">{{langFilter('Search program')}}</p> -->
                  <ul>
                    <li
                      v-for="(project, indexProject) in search_projects"
                      :key="indexProject"
                    >
                      <label>
                        <span>{{ project.name }}</span>
                        <input
                          type="checkbox"
                          name="projectChecked"
                          v-model="selectedProjectsList"
                          :value="project"
                        />
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="forum-list-programs-dropdown-content-container"
              @click="clickOutsideDiv($event)"
            ></div>
          </div>
        </div>
      </div>
      <div class="forum-content-container">
        <!-- MAIN CARD -->
        <div v-if="view == 1">
          <div class="forum-card-header">
            <p>{{ langFilter("Interactions") }}</p>
          </div>
          <div class="forum-card go-bulletin" @click="query_bulletin()">
            <div class="forum-card-text">
              <h1>{{ langFilter("Bulletin board") }}</h1>
              <p>
                {{
                  langFilter(
                    "Important information related to monitoring sections within your space"
                  )
                }}
              </p>
            </div>
            <div class="forum-card-posts">
              <div class="forum-card-center">
                <h1>{{ number_bulletin }}</h1>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1H2C1.44772 1 1 1.44772 1 2V9.045C1 9.57243 1.42757 10 1.955 10C2.47417 10 2.89824 10.4148 2.90976 10.9338L2.95837 13.124C2.96973 13.6362 3.65238 13.8019 3.89736 13.352L5.43846 10.5218C5.61359 10.2002 5.9505 10 6.31671 10H13C13.5523 10 14 9.55229 14 9V2C14 1.44772 13.5523 1 13 1Z"
                    stroke="#ACA3D0"
                  />
                </svg>
                <!-- <img src="/img/forum/Posts_icon.png"> -->
              </div>
            </div>
          </div>
        </div>
        <!-- POST CARDS HEADER -->

        <div
          class="forum-post-card-header"
          v-if="posts.length !== 0 && view !== 3"
        >
          <p class="published-by">{{ langFilter("Published by") }}</p>
          <p class="topic">
            <span v-if="view == 1">{{ langFilter("Topic") }}</span
            ><span v-if="view == 2">{{
              langFilter("Conversation thread")
            }}</span>
          </p>
          <p class="last-message" v-if="view == 2">
            {{ langFilter("Last message") }}
          </p>
          <p class="subtopic">
            <span v-if="view == 1">{{ langFilter("Subtopic") }}</span
            ><span v-if="view == 2">{{ langFilter("Answers") }}</span>
          </p>
          <p class="fav">{{ langFilter("Fav.") }}</p>
          <p class="delete"></p>
        </div>
        <!-- ADD POST FORM -->
        <div class="forum-card forum-card-form" v-if="addPost == true">
          <div class="published-by">
            <h1>{{ langFilter("I") }}</h1>
            <p>{{ langFilter(my_role_name) }}</p>
          </div>
          <div class="topic">
            <input
              id="new-post-title"
              type="text"
              v-model="newPost.title"
              :placeholder="langFilter('Write the title here...')"
            />
            <input
              id="new-post-description"
              type="text"
              maxlength="95"
              v-model="newPost.description"
              :placeholder="langFilter('Write a short description here...')"
            />
            <label
              >{{ langFilter("Characters") }}
              {{ newPost.description.length }}/95</label
            >
          </div>
          <div class="options">
            <div class="forum-button-confirm" @click="add_new_post()">
              <p>{{ langFilter("Save") }}</p>
            </div>

            <p class="forum-button-cancel" @click="addNewPostTool()">
              {{ langFilter("Cancel") }}
            </p>
          </div>
        </div>
        <!-- POST CARDS -->
        <div v-if="view == 1">
          <forum-post
            v-for="(post, index) in search_post.slice(pagination1, pagination2)"
            :key="index"
            :post="post"
            :lang="lang"
          ></forum-post>
        </div>
        <!-- SUBPOST CARDS -->
        <div v-if="view == 2">
          <forum-subpost
            v-for="(post, index) in search_post.slice(pagination1, pagination2)"
            :key="index"
            :post="post"
            :lang="lang"
          ></forum-subpost>
        </div>
        <!-- THREAD CARDS -->
        <div v-if="view == 3">
          <forum-thread
            v-for="(post, index) in search_post.slice(pagination1, pagination2)"
            :key="index"
            :index="index"
            :post="post"
            :lang="lang"
          ></forum-thread>
        </div>
        <div v-if="view == 4">
          <forum-bulletin
            v-for="(post, index) in search_post.slice(pagination1, pagination2)"
            :key="index"
            :index="index"
            :post="post"
            :lang="lang"
          ></forum-bulletin>
        </div>
        <div v-if="view == 5">
          <forum-selected-bulletin
            :lang="lang"
            :post="selectedBulletin"
          ></forum-selected-bulletin>
          <div class="bulletin-guide-container">
            <p
              v-if="indexSelectedBulletin < search_post.length - 1"
              @click="nextBulletin()"
            >
              {{ langFilter("Next") }} >
            </p>
            <span v-if="indexSelectedBulletin < search_post.length - 1"></span>
            <p v-if="indexSelectedBulletin > 0" @click="previousBulletin">
              {{ langFilter("Previous") }}
            </p>
          </div>
        </div>

        <div v-if="view == 0">
          <img
            class="carga"
            style="position: absolute; left: calc(50% - 75px)"
            :src="require('@/assets/img/general/carga.gif')"
          />
        </div>

        <modal-window
          :lang="lang"
          :modal_id="'component-modal'"
          :headText="{
            title: langFilter('Create new subtopic'),
            info: langFilter('(A new conversation thread will be generated)'),
          }"
          :button="langFilter('Publish')"
          :button_id="1"
          :inputs="[
            {
              id: 1,
              inputs: [
                {
                  name: 'subpost-title',
                  number: '1',
                  title: langFilter('Subtopic’s title') + '*',
                  info: '',
                  type: 'input',
                  placeholder: langFilter('Write a title for this subtopic...'),
                  options: [],
                },
              ],
            },
            {
              id: 2,
              inputs: [
                {
                  name: 'subpost-program',
                  number: '2',
                  title: langFilter('Linked Program') + '*',
                  info: '(Optional)',
                  type: 'search_select',
                  placeholder: langFilter('Select a linked program...'),
                  options: programs,
                },
                {
                  name: 'subpost-project',
                  number: '3',
                  title: langFilter('Linked project'),
                  info: '(Optional)',
                  type: 'search_select',
                  placeholder: langFilter('Select a linked project...'),
                  options: search_projects_by_program_subpost(),
                },
              ],
            },
            {
              id: 3,
              inputs: [
                {
                  name: 'subpost-message',
                  number: '4',
                  title: langFilter('Message') + '*',
                  info: '',
                  type: 'textarea',
                  placeholder: '',
                  options: [],
                },
              ],
            },
          ]"
        >
        </modal-window>
        <modal-window
          :lang="lang"
          :modal_id="'bulletin-modal'"
          :headText="{
            title: langFilter('Publish to the bulletin board'),
            info: '',
          }"
          :button="langFilter('Publish')"
          :button_id="2"
          :inputs="[
            {
              id: 1,
              inputs: [
                {
                  name: 'bulletin-title',
                  number: '1',
                  title: langFilter('Publication’s title'),
                  info: '',
                  type: 'input',
                  placeholder: langFilter(
                    'Write a title for this publication...'
                  ),
                  options: [],
                },
              ],
            },
            {
              id: 2,
              inputs: [
                {
                  name: 'bulletin-program',
                  number: '2',
                  title: langFilter('Linked Program'),
                  info: '(Optional)',
                  type: 'search_select',
                  placeholder: langFilter('Select a linked program...'),
                  options: programs,
                },
                {
                  name: 'bulletin-project',
                  number: '3',
                  title: langFilter('Linked project'),
                  info: '(Optional)',
                  type: 'search_select',
                  placeholder: langFilter('Select a linked project...'),
                  options: search_projects_by_program_bulletin(),
                },
              ],
            },
            {
              id: 3,
              inputs: [
                {
                  name: 'bulletin-message',
                  number: '4',
                  title: langFilter('Message'),
                  info: '',
                  type: 'textarea',
                  placeholder: '',
                  options: [],
                },
              ],
            },
          ]"
        >
        </modal-window>

        <div
          v-if="search_post.length == 0 && view !== 0"
          class="forum-no-results"
        >
          <p>{{ langFilter("No results found.") }}</p>
        </div>
        <div id="response-div" class="forum-card thread" v-show="view == 3">
          <div class="published-by thread-card">
            <img :src="getAvatar(my_avatar)" />
            <h1>{{ langFilter("I") }}</h1>
            <p>{{ langFilter(my_role_name) }}</p>
          </div>
          <div class="topic-thread-card" style="max-width: 190px">
            <p class="quote-p" v-if="quoteMessage">
              {{ langFilter("Quote") }}:
            </p>
            <div
              class="thread-quote-message"
              v-if="quoteMessage"
              v-html="quoteMessage.description"
            ></div>
            <svg
              class="thread-quote-cancel"
              v-if="quoteMessage"
              @click="quoteMessage = ''"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.3248 16.6752C15.9342 17.0658 15.3011 17.0658 14.9105 16.6752L9.05882 10.8235L3.14828 16.7341C2.75776 17.1246 2.12459 17.1246 1.73407 16.7341L1.23652 16.2365C0.845994 15.846 0.845995 15.2128 1.23652 14.8223L7.14706 8.91177L1.32476 3.08946C0.934231 2.69894 0.93423 2.06577 1.32475 1.67525L1.67525 1.32475C2.06577 0.934229 2.69894 0.934229 3.08946 1.32475L8.91177 7.14706L14.8223 1.23652C15.2128 0.845995 15.846 0.845995 16.2365 1.23652L16.7341 1.73407C17.1246 2.12459 17.1246 2.75776 16.7341 3.14828L10.8235 9.05882L16.6752 14.9105C17.0658 15.3011 17.0658 15.9342 16.6752 16.3248L16.3248 16.6752Z"
                fill="#B7AED6"
              />
            </svg>
            <div class="froala-thread-container" style="margin-bottom: 53px">
              <textarea id="new-thread-message"></textarea>
            </div>
            <div
              :class="message_filled ? 'quote-button' : 'quote-button-disabled'"
              @click="message_filled ? add_thread_message() : ''"
              @mouseenter="diabledButtonMessage()"
              :style="
                'padding:0px;right:48px;' +
                (message_filled ? 'cursor: pointer;' : 'cursor:default;')
              "
            >
              <p style="width: 100%; text-align: center">
                {{ langFilter("Publish") }}
              </p>
            </div>
          </div>
        </div>
        <pagination2
          ref="pagination"
          :lang="lang"
          :length="search_post.length"
          :max="maxPosts"
        ></pagination2>
      </div>
      <confirmation :lang="lang" ref="modalConfirmation"></confirmation>
      <alertMessage :lang="lang" ref="alerts"></alertMessage>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import FroalaEditor from "../../../node_modules/froala-editor/js/froala_editor.pkgd.min.js";
import { Modal } from "bootstrap";

import $ from "jquery";
import axios from "axios";

export default {
  props: ["lang", "user_id", "space_id", "ruta", "space"],

  data() {
    return {
      view: 0,
      url_post: 0,
      subpost_clicked_id: 0,
      number_bulletin: 0,
      selectedProgramsList: [],
      selectedProjectsList: [],
      selectedBulletin: {},
      indexSelectedBulletin: "",
      quoteMessage: "",
      programs: [],
      projects: [],
      current_post: 0,
      current_subpost: 0,

      // tools
      searchPost: "",
      searchProjectProgram: "",
      filterFav: false,
      addPost: false,
      // pagination
      maxPosts: 50,
      pagination1: 0,
      pagination2: 50,
      // new post
      newPost: {
        title: "",
        description: "",
      },
      // posts
      posts: [],
      normal_posts: [
        {
          title: "",
        },
      ],
      favourite_posts: [],

      like_threads: [],
      like_subpost: "",

      my_avatar: "",
      my_role_name: "",
      name_of_post_selected: "",
      delete_post: {},
      // Alertas
      errors: [],
      succesful: [],
      confirmModal: 0,
      selected_program_id_bulletin: "",
      selected_program_id_subpost: "",

      message_filled: false,

      //Traducción
      traducciones: [
        {
          name: "Forum",
          es: "Foro",
          en: "Forum",
        },
        {
          name: "Interactions",
          es: "Interacciones",
          en: "Interactions",
        },
        {
          name: "Published by",
          es: "Publicado por",
          en: "Published by",
        },
        {
          name: "Topic",
          es: "Tema",
          en: "Topic",
        },
        {
          name: "Conversation thread",
          es: "Hilo de conversación",
          en: "Conversation thread",
        },
        {
          name: "Last message",
          es: "Última interacción",
          en: "Last interaction",
        },
        {
          name: "Answers",
          es: "Respuestas",
          en: "Answers",
        },
        {
          name: "Subtopic",
          es: "Subtemas",
          en: "Subtopic",
        },
        {
          name: "Fav.",
          es: "Fav.",
          en: "Fav.",
        },
        {
          name: "Characters",
          es: "Caracteres",
          en: "Characters",
        },
        {
          name: "Write the title here...",
          es: "Escribe el título aquí...",
          en: "Write the title here...",
        },
        {
          name: "Write a short description here...",
          es: "Escribe una breve descripción aquí...",
          en: "Write a short description here...",
        },
        {
          name: "Cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "Save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "Forum",
          es: "Foro",
          en: "Forum",
        },
        {
          name: "Subtopic of topic of forum",
          es: "Subtemas del tema",
          en: "Subtopic of topic",
        },
        {
          name: "Subtopic",
          es: "Subtema",
          en: "Subtopic",
        },
        {
          name: "Bulletin board",
          es: "Tablón de anuncios",
          en: "Bulletin board",
        },
        {
          name: "Filter",
          es: "Filtro",
          en: "Filter",
        },
        {
          name: "Programs",
          es: "Programas",
          en: "Programs",
        },
        {
          name: "Projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "Projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "I",
          es: "Yo",
          en: "Me",
        },
        {
          name: "Next",
          es: "Siguiente",
          en: "Next",
        },
        {
          name: "Previous",
          es: "<  Anterior",
          en: "<  Previous",
        },
        {
          name: "Publish",
          es: "Publicar",
          en: "Publish",
        },
        {
          name: "Create new subtopic",
          es: "Crear nuevo subtema",
          en: "Create new subtopic",
        },
        {
          name: "(A new conversation thread will be generated)",
          es: "(Se generará un nuevo hilo de conversación)",
          en: "(A new conversation thread will be generated)",
        },
        {
          name: "Subtopic’s title",
          es: "Titulo del subtema",
          en: "Subtopic’s title",
        },
        {
          name: "Write a title for this subtopic...",
          es: "Escribe un titulo para este subtema",
          en: "Write a title for this subtopic...",
        },
        {
          name: "Linked Program",
          es: "Programa vinculado",
          en: "Linked Program",
        },
        {
          name: "Linked project",
          es: "Proyecto vinculado",
          en: "Linked project",
        },
        {
          name: "Select a linked program...",
          es: "Selecciona el programa vinculado...",
          en: "Select a linked program...",
        },
        {
          name: "Select a linked project...",
          es: "Selecciona el proyecto vinculado...",
          en: "Select a linked project...",
        },
        {
          name: "Message",
          es: "Mensaje",
          en: "Message",
        },
        {
          name: "Publish to the bulletin board",
          es: "Publicar en el tablón de anuncios",
          en: "Publish to the bulletin board",
        },
        {
          name: "Publication’s title",
          es: "Titulo del anuncio",
          en: "Publication’s title",
        },
        {
          name: "Write a title for this publication...",
          es: "Escribe un titulo para este anuncio...",
          en: "Write a title for this publication...",
        },
        {
          name: "No results found.",
          es: "No se ha encontrado ningún resultado.",
          en: "No results found.",
        },
        {
          name: "Quote",
          es: "Cita",
          en: "Quote",
        },
        {
          name: "Search",
          es: "Buscar",
          en: "Search",
        },
        {
          name: "Important information related to monitoring sections within your space",
          es: "Información importante relativa al seguimiento de cualquiera de las secciones de tu espacio",
          en: "Important information related to monitoring sections within your space",
        },
        {
          name: "Entrepreneur",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "Tutor",
          es: "Tutor",
          en: "Tutor",
        },
      ],
    };
  },
  created() {
    this.view = this.ruta;
    setTimeout(
      function () {
        this.initFroala();
      }.bind(this),
      1000
    );
    setTimeout(
      function () {
        this.popStateReload();
      }.bind(this),
      1
    );
  },
  watch: {
    // whenever question changes, this function will run
    view() {
      $("#subpost-title").removeClass("Forum-InputValidation");
      $("#bulletin-title").removeClass("Forum-InputValidation");

      $(".fr-box.fr-basic.fr-top").removeClass("Forum-InputValidation");
    },
  },
  computed: {
    search_post() {
      return this.posts.filter((post) => {
        if (
          this.selectedProgramsList.length == 0 &&
          this.selectedProjectsList.length == 0
        ) {
          this.$nextTick(() => {
            this.$refs.pagination.paginationFirst();
          });
          return (
            post.title.toLowerCase().includes(this.searchPost.toLowerCase()) ||
            post.description
              .toLowerCase()
              .includes(this.searchPost.toLowerCase())
          );
        } else {
          this.$nextTick(() => {
            this.$refs.pagination.paginationFirst();
          });
          return (
            (post.title.toLowerCase().includes(this.searchPost.toLowerCase()) &&
              this.checkProjectProgram(post)) ||
            (post.description
              .toLowerCase()
              .includes(this.searchPost.toLowerCase()) &&
              this.checkProjectProgram(post))
          );
        }
      });
    },
    search_projects() {
      return this.projects.filter((project) => {
        return project.name
          .toLowerCase()
          .includes(this.searchProjectProgram.toLowerCase());
      });
    },
    search_programs() {
      return this.programs.filter((program) => {
        return program.name
          .toLowerCase()
          .includes(this.searchProjectProgram.toLowerCase());
      });
    },
  },
  methods: {
    getAvatar(avatar) {
      avatar = avatar.replace("https://www/.", "https://");
      if (!avatar.includes("/img/login_register")) {
        return process.env.VUE_APP_API_STORAGE + avatar;
      } else {
        return avatar;
      }
    },
    popStateReload() {
      let page = window.location.pathname;
      let pagename = page.split("/");
      this.url_post = pagename[2];

      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-customer-space`, {
          post_id: this.url_post,
          user_id: this.user_id,
          space_id: this.space_id,
          view: this.view,
        })
        .then((response) => {
          if (response.data == false) {
            window.location = "/unauthorized";
          }
        })
        .catch(() => {
          // console.log("error");
        });

      if (this.view == 1) {
        this.query_posts(false);
      } else if (this.view == 2) {
        this.addPost = false;
        this.pagination1 = 0;
        this.pagination2 = 50;
        this.$nextTick(() => {
          this.$refs.pagination.paginationFirst();
        });
        let page = window.location.pathname;
        let pagename = page.split("/");

        this.url_post = pagename[2];
        this.current_post = this.url_post;

        axios
          .post(`${process.env.VUE_APP_API_URL}/forum-subposts`, {
            post_id: this.url_post,
            user_id: this.user_id,
          })
          .then((response) => {
            this.subpost_clicked_id = this.url_post;

            this.normal_posts = response.data.posts;
            this.favourite_posts = response.data.favourite;
            this.active_filter_fav();
            this.get_name_of_topic();
          })
          .catch(() => {
            // console.log("error");
          });
      } else if (this.view == 3) {
        this.view = 0;
        this.addPost = false;
        this.pagination1 = 0;
        this.pagination2 = 50;
        this.$nextTick(() => {
          this.$refs.pagination.paginationFirst();
        });
        let page = window.location.pathname;
        let pagename = page.split("/");

        this.url_post = pagename[2];
        this.current_subpost = this.url_post;

        var post = {};
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/forum-get-subpost-thread-messages`,
            {
              post_id: this.url_post,
              user_id: this.user_id,
            }
          )
          .then((response) => {
            console.log(response);
            post = response.data.posts;
            this.subpost_clicked_id = post.main_id;
            this.current_post = post.main_id;

            this.current_post = post.main_id;

            this.get_name_of_topic();
          })
          .catch(() => {
            // console.log("error");
          });

        axios
          .post(`${process.env.VUE_APP_API_URL}/forum-thread-messages`, {
            post_id: this.url_post,
            user_id: this.user_id,
          })
          .then((response) => {
            console.log(response);
            this.view = 3;
            this.like_subpost = response.data.subpost_like;
            this.like_threads = response.data.thread_like;

            this.normal_posts = response.data.posts;
            this.favourite_posts = response.data.favourite;
            this.normal_posts.unshift(post);
            if (this.is_fav(post)) {
              this.favourite_posts.unshift(post);
            }
            this.active_filter_fav();
          })
          .catch(() => {
            // console.log("error");
          });
      } else if (this.view == 4) {
        this.view = 0;
        this.addPost = false;
        this.pagination1 = 0;
        this.pagination2 = 50;
        this.$nextTick(() => {
          this.$refs.pagination.paginationFirst();
        });

        axios
          .post(`${process.env.VUE_APP_API_URL}/forum-bulletin`, {
            space_id: this.space_id,
            user_id: this.user_id,
          })
          .then((response) => {
            this.normal_posts = response.data.posts;
            this.favourite_posts = response.data.favourite;

            this.active_filter_fav();
            this.view = 4;
          })
          .catch(() => {
            // console.log("error");
          });
      } else if (this.view == 5) {
        this.view = 0;
        this.addPost = false;
        this.pagination1 = 0;
        this.pagination2 = 50;
        let page = window.location.pathname;
        let pagename = page.split("/");

        this.url_post = pagename[2];
        this.$nextTick(() => {
          this.$refs.pagination.paginationFirst();
        });

        axios
          .post(`${process.env.VUE_APP_API_URL}/forum-bulletin`, {
            space_id: this.space_id,
            user_id: this.user_id,
          })
          .then((response) => {
            this.normal_posts = response.data.posts;
            this.favourite_posts = response.data.favourite;

            var index = this.normal_posts.findIndex((post) => {
              return post.post_id == this.url_post;
            });

            if (index !== -1) {
              this.selectedBulletin = this.normal_posts[index];
              this.indexSelectedBulletin = index;

              this.$nextTick(() => {
                this.$refs.pagination.paginationFirst();
              });
            }
            console.log(this.selectedBulletin);

            this.active_filter_fav();

            this.view = 5;
          })
          .catch(() => {
            // console.log("error");
          });
      }
      this.get_programs_projects();

      this.get_my_role();
    },
    return_bulletin() {
      window.location.href = window.location.origin + "/bulletin/";
    },
    initFroala() {
      let _token = $('meta[name="csrf-token"]').attr("content");
      let url = process.env.VUE_APP_API_URL;

      new FroalaEditor("#subpost-message", {
        placeholderText: "",
        fontFamilyDefaultSelection: "Poppins",
        height: 139,
        attribution: false,
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
            ],
          },
          moreParagraph: {
            buttons: ["outdent", "indent"],
          },
          moreRich: {
            buttons: ["insertLink", "insertImage", "insertFile"],
          },
          moreMisc: {
            buttons: ["undo", "redo"],
            align: "right",
            buttonsVisible: 2,
          },
        },
        pluginsEnabled: [
          "image",
          "link",
          "file",
          "linkText",
          "colors",
          "fontSize",
          "lineHeight",
          "specialCharactersSets",
          "quickInsert",
          "linkAlwaysBlank",
          "wordPaste",
        ],
        linkAlwaysBlank: true,
        wordPasteModal: false,
        quickInsertButtons: ["image", "link", "file"],
        key: process.env.VUE_APP_FROALA_KEY,

        //img
        imageDefaultDisplay: "inline",
        imageUploadMethod: "POST",
        imageUploadParams: {
          _token: _token,
        },
        // imageMaxSize: 25 * 1024 * 1024,
        imageAllowedTypes: ["jpeg", "jpg", "png"],
        imageUploadURL: url + "/froala/upload/img",
        //file
        fileUploadMethod: "POST",
        fileUploadParams: {
          _token: _token,
        },
        fileUploadURL: url + "/froala/upload/files",
        //video
        videoUploadMethod: "POST",
        videoUploadParams: {
          _token: _token,
        },
        videoUploadURL: url + "/froala/upload/videos",

        events: {
          "image.removed": function () {
            // img_del = [$("#img_del").val()];
            // img_del.push($img.attr("src"));
            // $("#img_del").val(img_del);
          },
        },
      });
      new FroalaEditor("#bulletin-message", {
        placeholderText: "",
        fontFamilyDefaultSelection: "Poppins",
        height: 139,
        attribution: false,
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
            ],
          },
          moreParagraph: {
            buttons: ["outdent", "indent"],
          },
          moreRich: {
            buttons: ["insertLink", "insertImage", "insertFile"],
          },
          moreMisc: {
            buttons: ["undo", "redo"],
            align: "right",
            buttonsVisible: 2,
          },
        },
        pluginsEnabled: [
          "image",
          "link",
          "file",
          "linkText",
          "colors",
          "fontSize",
          "lineHeight",
          "specialCharactersSets",
          "quickInsert",
          "linkAlwaysBlank",
          "wordPaste",
        ],
        linkAlwaysBlank: true,
        wordPasteModal: false,
        quickInsertButtons: ["image", "link", "file"],
        key: process.env.VUE_APP_FROALA_KEY,

        //img
        imageDefaultDisplay: "inline",
        imageUploadMethod: "POST",
        imageUploadParams: {
          _token: _token,
        },
        // imageMaxSize: 25 * 1024 * 1024,
        imageAllowedTypes: ["jpeg", "jpg", "png"],
        imageUploadURL: url + "/froala/upload/img",
        //file
        fileUploadMethod: "POST",
        fileUploadParams: {
          _token: _token,
        },
        fileUploadURL: url + "/froala/upload/files",
        //video
        videoUploadMethod: "POST",
        videoUploadParams: {
          _token: _token,
        },
        videoUploadURL: url + "/froala/upload/videos",

        events: {
          "image.removed": function () {
            // img_del = [$("#img_del").val()];
            // img_del.push($img.attr("src"));
            // $("#img_del").val(img_del);
          },
        },
      });
      var style = window.getComputedStyle(
        document.getElementById("new-thread-message")
      );

      if (style.display !== "none") {
        new FroalaEditor("#new-thread-message", {
          placeholderText: "hola",
          fontFamilyDefaultSelection: "Poppins",
          heightMin: 101,
          heightMax: 500,
          attribution: false,
          pluginsEnabled: [
            "image",
            "link",
            "file",
            "linkText",
            "colors",
            "fontSize",
            "lineHeight",
            "specialCharactersSets",
            "quickInsert",
            "linkAlwaysBlank",
            "wordPaste",
          ],
          linkAlwaysBlank: true,
          wordPasteModal: false,
          quickInsertButtons: ["image", "link", "file"],
          key: process.env.VUE_APP_FROALA_KEY,

          //img
          imageUploadMethod: "POST",
          imageUploadParams: {
            _token: _token,
          },
          // imageMaxSize: 25 * 1024 * 1024,
          imageAllowedTypes: ["jpeg", "jpg", "png"],
          imageUploadURL: url + "/froala/upload/img",
          //file
          fileUploadMethod: "POST",
          fileUploadParams: {
            _token: _token,
          },
          fileUploadURL: url + "/froala/upload/files",
          //video
          videoUploadMethod: "POST",
          videoUploadParams: {
            _token: _token,
          },
          videoUploadURL: url + "/froala/upload/videos",

          events: {
            "image.removed": function () {
              //   img_del = [$("#img_del").val()];
              //   img_del.push($img.attr("src"));
              //   $("#img_del").val(img_del);
            },
          },
        });
      }
      this.$parent.loading = false;
    },
    get_name_of_topic() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-get-name-of-post`, {
          post_id: this.current_post,
        })
        .then((response) => {
          this.name_of_post_selected = response.data.title;
        })
        .catch(() => {
          // console.log("error");
        });
    },
    diabledButtonMessage() {
      var message = $("#new-thread-message").val();
      let EmptyP = "<p><br></p>";
      let OpenP = "<p>";
      let CloseP = "</p>";
      let Br = "<br>";
      let WhiteSpace = " ";
      let Replace = "";
      let Quotemarks = "<blockquote>";
      let CQuotemarks = "</blockquote>";
      let li = "<li>";
      let ol = "<ol>";
      let ul = "<ul>";
      let b = "<strong>";
      let em = "<em>";
      let s = "<s>";
      let u = "<u>";
      let closeli = "</li>";
      let closeol = "</ol>";
      let closeul = "</ul>";
      let closeb = "</strong>";
      let closeem = "</em>";
      let closes = "</s>";
      let closeu = "</u>";

      message = message.split(EmptyP).join(Replace);

      //variable with message to validate without tags
      var validation = message.split(OpenP).join(Replace);

      validation = validation.split(CloseP).join(Replace);
      validation = validation.split(Br).join(Replace);
      validation = validation.split(WhiteSpace).join(Replace);
      validation = validation.split(Quotemarks).join(Replace);
      validation = validation.split(CQuotemarks).join(Replace);
      validation = validation.split(li).join(Replace);
      validation = validation.split(ol).join(Replace);
      validation = validation.split(ul).join(Replace);
      validation = validation.split(b).join(Replace);
      validation = validation.split(em).join(Replace);
      validation = validation.split(closeli).join(Replace);
      validation = validation.split(closeol).join(Replace);
      validation = validation.split(closeul).join(Replace);
      validation = validation.split(closeb).join(Replace);
      validation = validation.split(closeem).join(Replace);
      validation = validation.split(s).join(Replace);
      validation = validation.split(closes).join(Replace);
      validation = validation.split(u).join(Replace);
      validation = validation.split(closeu).join(Replace);
      validation = validation.replaceAll(/\t+/g, "");
      validation = validation.replaceAll(" ", "");
      validation = validation.replaceAll("&nbsp;", "");

      if (validation == "") {
        this.message_filled = false;
      } else {
        this.message_filled = true;
      }
    },

    search_projects_by_program_bulletin() {
      if (this.selected_program_id_bulletin !== "") {
        return this.projects.filter((project) => {
          return (
            project.name
              .toLowerCase()
              .includes(this.searchProjectProgram.toLowerCase()) &&
            project.program_id == this.selected_program_id_bulletin
          );
        });
      } else {
        return this.projects.filter((project) => {
          return project.name
            .toLowerCase()
            .includes(this.searchProjectProgram.toLowerCase());
        });
      }
    },
    search_projects_by_program_subpost() {
      if (this.selected_program_id_subpost !== "") {
        return this.projects.filter((project) => {
          return (
            project.name
              .toLowerCase()
              .includes(this.searchProjectProgram.toLowerCase()) &&
            project.program_id == this.selected_program_id_subpost
          );
        });
      } else {
        return this.projects.filter((project) => {
          return project.name
            .toLowerCase()
            .includes(this.searchProjectProgram.toLowerCase());
        });
      }
    },
    return_posts() {
      window.location.href = window.location.origin + "/forum/";
    },
    return_subposts() {
      if (this.normal_posts[0].main_id) {
        history.pushState(null, null, "/forum/" + this.normal_posts[0].main_id);
        history.replaceState(
          null,
          null,
          "/forum/" + this.normal_posts[0].main_id
        );
        this.current_post = this.normal_posts[0].main_id;
      } else {
        history.pushState(null, null, "/forum/" + this.subpost_clicked_id);

        history.replaceState(null, null, "/forum/" + this.subpost_clicked_id);
        this.current_post = this.subpost_clicked_id;
      }
      // window.location.href=window.location.origin+'/forum/'+this.normal_posts[0].main_id;
      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-subposts`, {
          post_id: this.subpost_clicked_id,
          user_id: this.user_id,
        })
        .then((response) => {
          this.normal_posts = response.data.posts;
          this.favourite_posts = response.data.favourite;
          this.active_filter_fav();
          this.view = 2;
        })
        .catch(() => {
          // console.log("error");
        });
    },
    checkProjectProgram(post) {
      if (
        this.selectedProgramsList.length !== 0 &&
        this.selectedProjectsList.length !== 0
      ) {
        return (
          this.selectedProgramsList.filter((e) => e.name === post.program_name)
            .length > 0 &&
          this.selectedProjectsList.filter((e) => e.name === post.project_name)
            .length > 0
        );
      } else if (this.selectedProgramsList.length !== 0) {
        return (
          this.selectedProgramsList.filter((e) => e.name === post.program_name)
            .length > 0
        );
      } else if (this.selectedProjectsList.length !== 0) {
        return (
          this.selectedProjectsList.filter((e) => e.name === post.project_name)
            .length > 0
        );
      }
    },
    get_my_role() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/general/get-role-name`)
        .then((response) => {
          this.my_role_name = response.data.role_name;
          this.$parent.loading = false;
        })
        .catch(() => {
          // console.log("error");
        });
      axios
        .post(`${process.env.VUE_APP_API_URL}/get-my-avatar`)
        .then((response) => {
          this.my_avatar = response.data.avatar.replace(
            "https://www.",
            "https://"
          );

          if (!this.my_avatar.includes("/img/login_register")) {
            this.my_avatar = process.env.VUE_APP_API_STORAGE + this.my_avatar;
          }

          this.$parent.loading = false;
        })
        .catch(() => {
          // console.log("error");
        });
    },
    nextBulletin() {
      this.indexSelectedBulletin = this.indexSelectedBulletin + 1;
      this.selectedBulletin = this.posts[this.indexSelectedBulletin];
      history.pushState(
        null,
        null,
        "/bulletin/" + this.posts[this.indexSelectedBulletin].post_id
      );

      history.replaceState(
        null,
        null,
        "/bulletin/" + this.posts[this.indexSelectedBulletin].post_id
      );
    },
    previousBulletin() {
      this.indexSelectedBulletin = this.indexSelectedBulletin - 1;
      this.selectedBulletin = this.posts[this.indexSelectedBulletin];
      history.pushState(
        null,
        null,
        "/bulletin/" + this.posts[this.indexSelectedBulletin].post_id
      );

      history.replaceState(
        null,
        null,
        "/bulletin/" + this.posts[this.indexSelectedBulletin].post_id
      );
    },
    seeCompleteBulletin(post) {
      this.selectedBulletin = post;
      this.indexSelectedBulletin = this.posts.indexOf(post);
      // window.location.href=window.location.origin+'/bulletin/'+this.indexSelectedBulletin;
      history.pushState(null, null, "/bulletin/" + post.post_id);

      history.replaceState(null, null, "/bulletin/" + post.post_id);
      this.view = 5;
      this.addPost = false;
      this.pagination1 = 0;
      this.pagination2 = 50;
      this.$nextTick(() => {
        this.$refs.pagination.paginationFirst();
      });
    },
    add_thread_message() {
      const message = $("#new-thread-message").val();

      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-add-thread-message`, {
          user_id: this.user_id,
          message: message,
          quote: this.quoteMessage.post_id,
          subpost_id: this.normal_posts[0].post_id,
        })
        .then((response) => {
          var new_post = response.data;
          new_post.avatar = this.my_avatar;
          new_post.user_first_name = this.langFilter("I");
          new_post.user_last_name = "";
          new_post.role = this.my_role_name;
          new_post.post_id = new_post.id;
          new_post.count_subpost = 0;
          new_post.title = new_post.message;
          new_post.description = new_post.message;
          new_post.likes = 0;
          new_post.active = 1;

          this.quoteMessage = "";

          $(".fr-element.fr-view.fr-element-scroll-visible").empty();

          new_post.description = new_post.message;

          this.normal_posts.push(new_post);
        })
        .catch(() => {
          // console.log("error");
        });

      $("#new-thread-message").val(null);
    },
    get_programs_projects() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-program-project`, {
          space_id: this.space_id,
        })
        .then((response) => {
          this.programs = response.data.programs;
          this.projects = response.data.projects;
        })
        .catch(() => {
          // console.log("error");
        });
    },

    tool_active(el) {
      $(el.target).addClass("active-tool");
    },
    tool_inactive(el) {
      $(el.target).removeClass("active-tool");
    },
    is_fav(post) {
      return this.favourite_posts.find((fav) => {
        return post.post_id === fav.post_id;
      });
    },
    add_new_post() {
      var can = true;
      if (this.newPost.title.length == 0) {
        can = false;
        $("#new-post-title").addClass("Forum-InputValidation");
      } else {
        $("#new-post-title").removeClass("Forum-InputValidation");
      }
      if (this.newPost.description.length == 0) {
        can = false;
        $("#new-post-description").addClass("Forum-InputValidation");
      } else {
        $("#new-post-description").removeClass("Forum-InputValidation");
      }
      if (can) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/forum-add-post`, {
            space_id: this.space_id,
            user_id: this.user_id,
            title: this.newPost.title,
            description: this.newPost.description,
          })
          .then((response) => {
            var new_post = response.data;
            new_post.user_first_name = this.langFilter("I");
            new_post.user_last_name = "";
            new_post.role = this.my_role_name;
            new_post.post_id = new_post.id;
            new_post.count_subpost = 0;

            this.normal_posts.unshift(new_post);
            this.addNewPostTool();
          })
          .catch(() => {
            // console.log("error");
          });
      } else {
        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";
        this.openErrorsAlert();
      }
    },
    query_posts(async) {
      if (async) {
        history.pushState(null, null, "/forum");
      }
      history.replaceState(null, null, "/forum");

      this.view = 0;
      this.addPost = false;
      this.pagination1 = 0;
      this.pagination2 = 50;
      this.selectedProgramsList = [];
      this.selectedProjectsList = [];

      this.$nextTick(() => {
        this.$refs.pagination.paginationFirst();
      });

      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-posts`, {
          space_id: this.space_id,
          user_id: this.user_id,
        })
        .then((response) => {
          console.log(response.data);

          this.normal_posts = response.data.posts;
          this.favourite_posts = response.data.favourite;

          this.active_filter_fav();
          this.count_bulletin();

          this.view = 1;

          $("#carga").hide();
          $("#app").show();
        })
        .catch(() => {
          // console.log("error");
        });
    },

    query_bulletin() {
      history.pushState(null, null, "/bulletin");

      history.replaceState(null, null, "/bulletin");

      this.view = 0;
      this.addPost = false;
      this.pagination1 = 0;
      this.pagination2 = 50;
      this.$nextTick(() => {
        this.$refs.pagination.paginationFirst();
      });

      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-bulletin`, {
          space_id: this.space_id,
          user_id: this.user_id,
        })
        .then((response) => {
          this.normal_posts = response.data.posts;
          this.favourite_posts = response.data.favourite;

          this.active_filter_fav();
          this.view = 4;
        })
        .catch(() => {
          // console.log("error");
        });
    },
    count_bulletin() {
      this.view = 0;
      this.addPost = false;
      this.pagination1 = 0;
      this.pagination2 = 50;
      this.$nextTick(() => {
        this.$refs.pagination.paginationFirst();
      });

      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-bulletin`, {
          space_id: this.space_id,
          user_id: this.user_id,
        })
        .then((response) => {
          this.number_bulletin = response.data.posts.length;
        })
        .catch(() => {
          // console.log("error");
        });
    },
    add_post_fav(post) {
      this.favourite_posts.push(post);
      if (this.view == 3) {
        this.favourite_posts.sort((a, b) =>
          a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0
        );
      } else if (this.view == 4 || this.view == 5) {
        this.favourite_posts.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );
      } else {
        this.favourite_posts.sort((a, b) =>
          a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
        );
      }
      if (this.view == 1) {
        axios.post(`${process.env.VUE_APP_API_URL}/forum-add-post-fav`, {
          post_id: post.post_id,
          user_id: this.user_id,
        });
      } else if (this.view == 2) {
        axios.post(`${process.env.VUE_APP_API_URL}/forum-add-subpost-fav`, {
          post_id: post.post_id,
          user_id: this.user_id,
        });
      } else if (this.view == 3) {
        axios.post(
          `${process.env.VUE_APP_API_URL}/forum-add-thread-message-fav`,
          {
            post_id: post.post_id,
            user_id: this.user_id,
          }
        );
      } else if (this.view == 4) {
        axios.post(`${process.env.VUE_APP_API_URL}/forum-add-bulletin-fav`, {
          post_id: post.post_id,
          user_id: this.user_id,
        });
      }
    },
    remove_post_fav(post) {
      var index = this.favourite_posts.findIndex((favourite) => {
        return favourite.post_id === post.post_id;
      });
      if (index !== -1) this.favourite_posts.splice(index, 1);
      if (this.view == 1) {
        axios.post(`${process.env.VUE_APP_API_URL}/forum-remove-post-fav`, {
          post_id: post.post_id,
          user_id: this.user_id,
        });
      } else if (this.view == 2) {
        axios.post(`${process.env.VUE_APP_API_URL}/forum-remove-subpost-fav`, {
          post_id: post.post_id,
          user_id: this.user_id,
        });
      } else if (this.view == 3) {
        axios.post(
          `${process.env.VUE_APP_API_URL}/forum-remove-thread-message-fav`,
          {
            post_id: post.post_id,
            user_id: this.user_id,
          }
        );
      } else if (this.view == 4) {
        axios.post(`${process.env.VUE_APP_API_URL}/forum-remove-bulletin-fav`, {
          post_id: post.post_id,
          user_id: this.user_id,
        });
      }
    },
    search_bar(show) {
      if (show) {
        $(".tool-search").addClass("search-big");
      } else {
        $(".tool-search").removeClass("search-big");
      }
    },
    addNewPostTool() {
      this.addPost = !this.addPost;
      this.newPost = {
        title: "",
        description: "",
      };
    },
    active_filter_fav() {
      this.$nextTick(() => {
        this.$refs.pagination.paginationFirst();
      });
      if (this.filterFav) {
        this.posts = this.favourite_posts;
      } else {
        this.posts = this.normal_posts;
      }
    },
    submit_modal(id) {
      var myModal = Modal.getInstance(
        document.getElementById("component-modal")
      );
      if (id == 1) {
        this.view = 0;
        const title = $("#subpost-title").val();
        const program = $("#subpost-program-value").val();
        const project = $("#subpost-project-value").val();
        let message = $("#subpost-message").val();

        let EmptyP = "<p><br></p>";
        let OpenP = "<p>";
        let CloseP = "</p>";
        let Br = "<br>";
        let WhiteSpace = " ";
        let Replace = "";
        let Quotemarks = "<blockquote>";
        let CQuotemarks = "</blockquote>";
        let li = "<li>";
        let ol = "<ol>";
        let ul = "<ul>";
        let b = "<strong>";
        let em = "<em>";
        let s = "<s>";
        let u = "<u>";
        let closeli = "</li>";
        let closeol = "</ol>";
        let closeul = "</ul>";
        let closeb = "</strong>";
        let closeem = "</em>";
        let closes = "</s>";
        let closeu = "</u>";

        message = message.split(EmptyP).join(Replace);

        //variable with message to validate without tags
        var validation = message.split(OpenP).join(Replace);

        validation = validation.split(CloseP).join(Replace);
        validation = validation.split(Br).join(Replace);
        validation = validation.split(WhiteSpace).join(Replace);
        validation = validation.split(Quotemarks).join(Replace);
        validation = validation.split(CQuotemarks).join(Replace);
        validation = validation.split(li).join(Replace);
        validation = validation.split(ol).join(Replace);
        validation = validation.split(ul).join(Replace);
        validation = validation.split(b).join(Replace);
        validation = validation.split(em).join(Replace);
        validation = validation.split(closeli).join(Replace);
        validation = validation.split(closeol).join(Replace);
        validation = validation.split(closeul).join(Replace);
        validation = validation.split(closeb).join(Replace);
        validation = validation.split(closeem).join(Replace);
        validation = validation.split(s).join(Replace);
        validation = validation.split(closes).join(Replace);
        validation = validation.split(u).join(Replace);
        validation = validation.split(closeu).join(Replace);
        validation = validation.replaceAll(/\t+/g, "");
        validation = validation.replaceAll(" ", "");
        validation = validation.replaceAll("&nbsp;", "");

        if (title != "" && validation != "") {
          axios
            .post(`${process.env.VUE_APP_API_URL}/forum-add-subpost`, {
              title: title,
              user_id: this.user_id,
              program: program,
              project: project,
              message: message,
              post_id: this.current_post,
            })
            .then((response) => {
              var new_post = response.data;
              new_post.user_first_name = this.langFilter("I");
              new_post.user_last_name = "";
              new_post.role = this.my_role_name;
              new_post.post_id = new_post.id;
              new_post.count_subpost = 0;
              new_post.likes = 0;

              var complete_project = this.projects.findIndex(
                (x) => x.value == project
              );
              if (complete_project == -1) {
                new_post.project_name = null;
              } else {
                new_post.project_name = this.projects[complete_project].name;
              }
              var complete_program = this.programs.findIndex(
                (x) => x.value == program
              );

              if (complete_program == -1) {
                new_post.program_name = null;
              } else {
                new_post.program_name = this.programs[complete_program].name;
              }

              $(".fr-element.fr-view.fr-element-scroll-visible").empty();
              this.normal_posts.unshift(new_post);
              $("#subpost-title").val("");
              $("#subpost-program").val("");
              $("#subpost-project").val("");
              $("#subpost-program-value").val("");
              $("#subpost-project-value").val("");
              $("#subpost-message").val("");
              this.selected_program_id_subpost = "";
              myModal.toggle();
              this.view = 2;
            });
        } else {
          if (title == "") {
            $("#subpost-title").addClass("Forum-InputValidation");
          } else {
            $("#subpost-title").removeClass("Forum-InputValidation");
          }
          if (validation == "") {
            $(".fr-box.fr-basic.fr-top").addClass("Forum-InputValidation");
          } else {
            $(".fr-box.fr-basic.fr-top").removeClass("Forum-InputValidation");
          }
          this.$refs.alerts.title = "¡Incompleted fields!";
          this.$refs.alerts.text = "To continue you must fill all the fields.";
          this.openErrorsAlert();
        }
      } else if (id == 2) {
        const title = $("#bulletin-title").val();
        const program = $("#bulletin-program-value").val();
        const project = $("#bulletin-project-value").val();
        let message = $("#bulletin-message").val();

        let EmptyP = "<p><br></p>";
        let OpenP = "<p>";
        let CloseP = "</p>";
        let Br = "<br>";
        let WhiteSpace = " ";
        let Replace = "";
        let Quotemarks = "<blockquote>";
        let CQuotemarks = "</blockquote>";
        let li = "<li>";
        let ol = "<ol>";
        let ul = "<ul>";
        let b = "<strong>";
        let em = "<em>";
        let s = "<s>";
        let u = "<u>";
        let closeli = "</li>";
        let closeol = "</ol>";
        let closeul = "</ul>";
        let closeb = "</strong>";
        let closeem = "</em>";
        let closes = "</s>";
        let closeu = "</u>";

        message = message.split(EmptyP).join(Replace);

        //variable with message to validate without tags
        validation = message.split(OpenP).join(Replace);

        validation = validation.split(CloseP).join(Replace);
        validation = validation.split(Br).join(Replace);
        validation = validation.split(WhiteSpace).join(Replace);
        validation = validation.split(Quotemarks).join(Replace);
        validation = validation.split(CQuotemarks).join(Replace);
        validation = validation.split(li).join(Replace);
        validation = validation.split(ol).join(Replace);
        validation = validation.split(ul).join(Replace);
        validation = validation.split(b).join(Replace);
        validation = validation.split(em).join(Replace);
        validation = validation.split(closeli).join(Replace);
        validation = validation.split(closeol).join(Replace);
        validation = validation.split(closeul).join(Replace);
        validation = validation.split(closeb).join(Replace);
        validation = validation.split(closeem).join(Replace);
        validation = validation.split(s).join(Replace);
        validation = validation.split(closes).join(Replace);
        validation = validation.split(u).join(Replace);
        validation = validation.split(closeu).join(Replace);
        validation = validation.replaceAll(/\t+/g, "");
        validation = validation.replaceAll(" ", "");
        validation = validation.replaceAll("&nbsp;", "");

        if (title != "" && validation != "") {
          axios
            .post(`${process.env.VUE_APP_API_URL}/forum-add-bulletin`, {
              title: title,
              user_id: this.user_id,
              program: program,
              project: project,
              message: message,
              space_id: this.space_id,
            })
            .then((response) => {
              var new_post = response.data;
              new_post.user_first_name = this.langFilter("I");
              new_post.user_last_name = "";
              new_post.role = this.my_role_name;
              new_post.post_id = new_post.id;

              var complete_project = this.projects.findIndex(
                (x) => x.value == project
              );
              if (complete_project == -1) {
                new_post.project_name = null;
              } else {
                new_post.project_name = this.projects[complete_project].name;
              }
              var complete_program = this.programs.findIndex(
                (x) => x.value == program
              );
              if (complete_program == -1) {
                new_post.program_name = null;
              } else {
                new_post.program_name = this.programs[complete_program].name;
              }

              new_post.date = new_post.created_at;

              $(".fr-element.fr-view.fr-element-scroll-visible").empty();
              this.normal_posts.unshift(new_post);
              $("#bulletin-title").val("");
              $("#bulletin-program").val("");
              $("#bulletin-project").val("");
              $("#bulletin-program-value").val("");
              $("#bulletin-project-value").val("");
              $("#bulletin-message").val("");
              this.selected_program_id_bulletin = "";
              var myModal2 = Modal.getInstance(
                document.getElementById("bulletin-modal")
              );
              myModal2.toggle();
            })
            .catch(() => {
              // console.log("error");
            });
        } else {
          if (title == "") {
            $("#bulletin-title").addClass("Forum-InputValidation");
          } else {
            $("#bulletin-title").removeClass("Forum-InputValidation");
          }
          if (validation == "") {
            $(".fr-box.fr-basic.fr-top").addClass("Forum-InputValidation");
          } else {
            $(".fr-box.fr-basic.fr-top").removeClass("Forum-InputValidation");
          }
          this.$refs.alerts.title = "¡Incompleted fields!";
          this.$refs.alerts.text = "To continue you must fill all the fields.";
          this.openErrorsAlert();
        }
      }
    },
    openPopUp: function (el) {
      if ($(el.currentTarget).next().is(":visible")) {
        $(el.currentTarget).next().hide();
        $(el.currentTarget).next().next().hide();
      } else {
        $(el.currentTarget).next().next().show();
        $(el.currentTarget).next().show();
      }
    },
    clickOutsideDiv(el) {
      $(el.currentTarget).hide();
      $(el.currentTarget).prev().hide();
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
          this.$refs.alerts.succesful = false;
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.$refs.alerts.succesful = false;
      this.errors = [];
    },
    openModalConfirmation() {
      this.$refs.modalConfirmation.confirmationMessage =
        "Are you sure you want to delete this topic?";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Si, Eliminar";
      setTimeout(() => {
        this.$refs.modalConfirmation.openModal();
      }, 100);
    },
    confirmTrackContinue() {
      event.preventDefault();
      var myModal = Modal.getInstance(
        document.getElementById("modalConfirmation")
      );

      switch (this.confirmModal) {
        case 0:
          axios
            .post(`${process.env.VUE_APP_API_URL}/forum-delete-post`, {
              post_id: this.delete_post.post_id,
              user_id: this.user_id,
            })
            .then(() => {
              var index_posts = this.normal_posts.findIndex((value) => {
                return value.post_id === this.delete_post.post_id;
              });
              if (index_posts !== -1) this.normal_posts.splice(index_posts, 1);
              var index_fav_posts = this.favourite_posts.findIndex((value) => {
                return value.post_id === this.delete_post.post_id;
              });
              if (index_fav_posts !== -1)
                this.favourite_posts.splice(index_fav_posts, 1);
            })
            .catch(() => {
              // console.log("error");
            });

          break;
        case 1:
          this.view = 0;
          axios
            .post(`${process.env.VUE_APP_API_URL}/forum-delete-subpost`, {
              post_id: this.delete_post.post_id,
              user_id: this.user_id,
            })
            .then(() => {
              var index_posts = this.normal_posts.findIndex((value) => {
                return value.post_id === this.delete_post.post_id;
              });
              if (index_posts !== -1) this.normal_posts.splice(index_posts, 1);
              var index_fav_posts = this.favourite_posts.findIndex((value) => {
                return value.post_id === this.delete_post.post_id;
              });
              if (index_fav_posts !== -1)
                this.favourite_posts.splice(index_fav_posts, 1);
              this.view = 2;
            })
            .catch(() => {
              // console.log("error");
            });

          break;
        case 2:
          axios
            .post(
              `${process.env.VUE_APP_API_URL}/forum-delete-thread-message`,
              {
                post_id: this.delete_post.post_id,
                user_id: this.user_id,
              }
            )
            .then(() => {
              var index_posts = this.normal_posts.findIndex((value) => {
                return value == this.delete_post;
              });
              if (index_posts !== -1) this.normal_posts[index_posts].active = 0;
              var index_fav_posts = this.favourite_posts.findIndex((value) => {
                return value == this.delete_post;
              });
              if (index_fav_posts !== -1)
                this.favourite_posts[index_fav_posts].active = 0;
            })
            .catch(() => {
              // console.log("error");
            });

          break;
        case 3:
          axios
            .post(`${process.env.VUE_APP_API_URL}/forum-delete-bulletin`, {
              post_id: this.delete_post.post_id,
              user_id: this.user_id,
            })
            .then(() => {
              var index_posts = this.normal_posts.findIndex((value) => {
                return value.post_id === this.delete_post.post_id;
              });
              if (index_posts !== -1) this.normal_posts.splice(index_posts, 1);
              var index_fav_posts = this.favourite_posts.findIndex((value) => {
                return value.post_id === this.delete_post.post_id;
              });
              if (index_fav_posts !== -1)
                this.favourite_posts.splice(index_fav_posts, 1);

              history.pushState(
                null,
                null,
                "/bulletin/" + this.posts[this.indexSelectedBulletin].post_id
              );

              history.replaceState(
                null,
                null,
                "/bulletin/" + this.posts[this.indexSelectedBulletin].post_id
              );
              this.view = 4;
            })
            .catch(() => {
              // console.log("error");
            });

          break;
      }
      myModal.toggle();
    },
    confirmTrackCancel() {
      event.preventDefault();
      var myModal = Modal.getInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
    },
    confirmTrackNoOptions() {
      event.preventDefault();
      var myModal = Modal.getInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
    },

    selectedValueModal(id, option) {
      switch (id) {
        case "bulletin-program":
          this.selected_program_id_bulletin = option.value;
          $("#bulletin-program").val(option.name);
          $("#bulletin-program-value").val(option.value);
          $("#bulletin-project").val("");
          $("#bulletin-project-value").val("");

          break;
        case "bulletin-project":
          $("#bulletin-project").val(option.name);
          $("#bulletin-project-value").val(option.value);

          break;
        case "subpost-program":
          this.selected_program_id_subpost = option.value;

          $("#subpost-program").val(option.name);
          $("#subpost-program-value").val(option.value);
          $("#subpost-project").val("");
          $("#subpost-project-value").val("");

          break;
        case "subpost-project":
          $("#subpost-project").val(option.name);
          $("#subpost-project-value").val(option.value);

          break;
      }
    },
  },
  mixins: [translationMixin],
};
</script>
