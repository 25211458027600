<template>
  <div :id="`banner-div-${type}`" class="track-banner-container">
    <div v-if="title == 'home'" class="tracks-title">
      <router-link to="/tracks">
        <span class="pointer">{{ langFilter(title) }}</span>
      </router-link>
    </div>
    <div v-else class="tracks-title">{{ langFilter(title) }}</div>
    <div class="row flex-nowrap">
      <div class="col">
        <img
          @click="scrollLeft()"
          class="back-arrow"
          :src="require('@/assets/img/track/back-arrow.svg')"
          alt="back arrow"
        />
      </div>
      <div class="col-10 padding-margin-0">
        <div class="general-container-tracks">
          <div
            :id="'container-tracks-' + type"
            class="row container-tracks flex-nowrap"
          >
            <div v-if="loading" class="align-center relative">
              <img
                class="home-load tracks-load"
                :src="require('@/assets/img/general/carga.gif')"
              />
            </div>
            <div
              v-show="!loading"
              v-for="(track, tracksIndex) in tracks"
              class="width-track"
              :key="tracksIndex"
            >
              <div @click="onTrackClick(track)" class="col margin-track">
                <tracks
                  @deleteTrack="deleteTrack($event)"
                  :id="`track-${track.id}`"
                  :track="track"
                  :type="type"
                  :searched="false"
                  :lang="lang"
                  :isAdmin="isAdmin"
                  :space="space"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <img
          @click="scrollRight()"
          class="next-arrow"
          :src="require('@/assets/img/track/next-arrow.svg')"
          alt="next arrow"
        />
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import tracks from "./TrackComponent.vue";
import $ from "jquery";

export default {
  props: ["lang", "title", "type", "tracks", "catSelected", "isAdmin", "space"],
  components: { tracks },
  data() {
    return {
      loading: true,
      // traduccion
      traducciones: [
        {
          name: "tusTracks",
          es: "Tus Plantillas",
          en: "Your Templates",
        },
        {
          name: "best",
          es: "Mejores Plantillas",
          en: "Best Templates",
        },
        {
          name: "catalogo",
          es: "Catálogo",
          en: "Catalog",
        },
        {
          name: "home",
          es: "Catálogo de Plantillas",
          en: "Templates Catalog",
        },
      ],
    };
  },
  mounted() {
    //wait for the tracks to be loaded
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    deleteTrack(id) {
      this.$emit("deleteTrack", id);
    },
    onTrackClick(track) {
      this.$emit("trackClick", track);
    },
    scrollRight() {
      $("#container-tracks-" + this.type).animate({
        scrollLeft: "+=600px",
      });
    },
    scrollLeft() {
      $("#container-tracks-" + this.type).animate({
        scrollLeft: "-=600px",
      });
    },
    getTrackByCategory() {
      this.checkDuplicates = [];
      const track = this.tracks.filter((track) => {
        if (this.catSelected == 0) {
          if (!this.checkDuplicates.includes(track.id)) {
            this.checkDuplicates.push(track.id);
            return track;
          } else {
            if (track.category_id == this.catSelected) {
              if (!this.checkDuplicates.includes(track.id)) {
                this.checkDuplicates.push(track.id);
                return track;
              }
            }
          }
        } else {
          return track.category_id == this.catSelected;
        }
      });
      return track;
    },
  },
  watch: {
    catSelected: function () {
      if (this.catSelected != 0) {
        let tracked = 0;
        $.each(
          this.tracks,
          function (index, track) {
            if (this.catSelected == track.category_id) {
              tracked++;
            }
          }.bind(this)
        );
        if (tracked == 0) {
          $(`#banner-div-${this.type}`).hide();
        } else {
          $(`#banner-div-${this.type}`).show();
        }
      } else {
        $(`#banner-div-${this.type}`).show();
      }

      if (this.type == 3) {
        if (
          $("#banner-div-1").is(":hidden") &&
          $("#banner-div-2").is(":hidden") &&
          $("#banner-div-3").is(":hidden")
        ) {
          $("#no-tracks").show();
        } else {
          $("#no-tracks").hide();
        }
      }
    },
  },
  mixins: [translationMixin],
};
</script>
