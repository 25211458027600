<template>
  <div class="newProject-ProjectDataContainer">
    <label for="name" class="newProject-ProjectDataCategoryTitle">
      1. &nbsp;&nbsp;&nbsp;{{ langFilter("projectName") }}
    </label>
    <textarea
      id="name"
      v-model="localProject.name"
      :class="{ 'newProject-InputValidation': isInvalidInput('name') }"
      class="newProject-projectName-textarea"
      cols="1"
      rows="1"
      @keypress.enter.prevent
    />

    <div class="newProject-ProjectDataCategoryTitle">
      2. &nbsp;&nbsp;&nbsp;{{ langFilter("selectProgram") }}
    </div>
    <div class="new-projectV2-select-search-container">
      <div
        class="newProject-Input placeholder-black input-disabled"
        style="margin-bottom: 79px"
      >
        {{ localProject.programName || "Selecciona el programa asociado..." }}
      </div>
    </div>

    <div class="newProject-ProjectDataCategoryTitle">
      3. &nbsp;&nbsp;&nbsp;{{ langFilter("projectDuration") }}
    </div>
    <div class="row align-center">
      <div class="col-6 align-left">
        <label for="startDate" class="label-new-projV2">
          {{ langFilter("inicioProyecto") }}
        </label>
        <input
          id="startDate"
          v-model="localProject.startDate"
          :class="{ 'newProject-InputValidation': isInvalidInput('startDate') }"
          class="input-new-projV2 date-prog"
          type="date"
        />
      </div>
      <div class="col-6 align-left">
        <label for="endDate" class="label-new-projV2">
          {{ langFilter("finalProyecto") }}
        </label>
        <input
          id="endDate"
          v-model="localProject.endDate"
          :class="{ 'newProject-InputValidation': isInvalidInput('endDate') }"
          class="input-new-projV2 date-prog"
          type="date"
          @change="handleEndDate"
        />
      </div>
    </div>

    <label for="taskManager" class="newProject-ProjectDataCategoryTitle">
      4. &nbsp;&nbsp;&nbsp;{{ langFilter("taskManager") }}
    </label>
    <div
      class="position-relative tooltip-container"
      @mouseover="isVisibleTooltip = true"
      @mouseleave="isVisibleTooltip = false"
    >
      <img
        :src="require('@/assets/img/general/info-alert.svg')"
        class="mb-1 col-2"
        alt="Info Icon"
        style="width: 16px; height: 16px"
      />
      <div v-if="isVisibleTooltip" class="custom-tooltip">
        {{ langFilter("taskManagerTooltip") }}
      </div>
    </div>
    <textarea
      id="taskManager"
      v-model="localProject.taskManager"
      :class="textareaClass"
      cols="1"
      rows="1"
      :placeholder="langFilter('taskManager')"
      @keypress.enter.prevent
    />
    <p v-if="taskManagerError" class="error-message">
      {{ langFilter("validUrl") }}
    </p>
  </div>

  <alertMessage ref="alerts" :lang="lang"></alertMessage>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import translations from "@/components/projects/translate/NewProjectsV2Component.json";

export default {
  mixins: [translationMixin],
  props: ["lang", "project", "taskManagerError"],
  data() {
    return {
      localProject: this.project || {},
      errors: [],
      succesful: [],
      traducciones: [
        ...translations,
        {
          name: "taskManagerTooltip",
          es: "Si usas una herramienta de Project Management o Calendario para organizarte, puedes poner el link aquí",
          en: "If you use a Project Management or Calendar tool to stay organized, you can add the link here",
        },
      ],
      isVisibleTooltip: false,
      missingValues: [],
    };
  },
  computed: {
    textareaClass() {
      return [
        "newProject-projectName-textarea",
        this.taskManagerError ? "newProject-InputValidation" : "",
      ];
    },
  },
  methods: {
    handleEndDate() {
      if (this.localProject.endDate < this.localProject.startDate) {
        this.openErrorsAlert();
        this.localProject.endDate = this.localProject.startDate;
      }
    },
    openErrorsAlert() {
      this.$refs.alerts.title = "¡Incorrect dates!";
      this.$refs.alerts.text =
        "The end date must be greater than the start date.";

      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };

        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      this.errors = [];
    },
    highlightMissingFields() {
      const { name, startDate, endDate } = this.localProject;

      this.missingValues = Object.entries({ name, startDate, endDate })
        .filter(([, value]) => !value?.trim())
        .map(([key]) => key);
    },
    isInvalidInput(fieldReference) {
      return this.missingValues.includes(fieldReference);
    },
  },
};
</script>

<style scoped>
.custom-tooltip {
  line-height: 24px;
  text-align: center;
}

.input-disabled {
  background: #c4c4c469;
  border-color: #c4c4c469;
  color: #2a3b5a;
}

.tooltip-container {
  margin-left: 8px;
}

.error-message {
  bottom: 10px;
  color: #eabec3;
  position: relative;
}
</style>
