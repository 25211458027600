<template>
  <div class="tabs">
    <div
      v-for="(tab, tabIndex) in tabs"
      :key="tabIndex"
      @click="selectTab(tabIndex)"
      :class="`tab ${tabIndex === selectedTab ? 'selected-tab' : ''}`"
    >
      {{ tab }}
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import "./Assets/Css/Tabs.css";

export default {
  props: ["tabs", "selectedTab"],
  methods: {
    selectTab(tabIndex) {
      this.$emit("selectTab", tabIndex);
    },
  },
  mixins: [translationMixin],
};
</script>
