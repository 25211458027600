<template>
  <div v-if="files?.length !== 0" class="file-tab">
    <div v-for="(file, index) in files" :key="index">
      <div class="file" :class="{ 'bg-not-change': !canChange }">
        <div class="file-icon-upload">
          <img
            class="icon-upload"
            src="/img/itinerario/file.svg"
            :alt="getDescription(file)"
          />
          <span class="file-type">{{ getExtension(file)?.toUpperCase() }}</span>
        </div>
        <div class="file-info">
          <span class="filename">{{ file.file_name }}</span>
          <span class="filename">{{ getDescription(file) ?? "" }}</span>

          <div v-if="canConvertFile(file)" class="file-checkbox-container">
            <label class="file-label-content">
              <input
                type="checkbox"
                class="file-checkbox"
                @change="(e) => handleChange(index, e)"
              />
              <span>{{ langFilter("convertToText") }}</span>
            </label>
          </div>
        </div>

        <div v-if="canChange" class="close-btn-container">
          <button class="close-btn" @click="handleRemoveFile(index)">
            &times;
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fileType } from "./model/fileType";
import { useDebugMode } from "@/debug.js";
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  props: ["lang", "files", "canChange"],
  emits: ["removeFile", "handleConvertFlag"],
  data() {
    return {
      localFiles: [...this.files],
      isDebugMode: false,
      traducciones: [
        {
          name: "convertToText",
          es: "Convertir a texto",
          en: "Convert to text",
        },
      ],
    };
  },
  watch: {
    files(newFiles) {
      this.localFiles = [...newFiles];
    },
  },
  created() {
    const { isDebugMode } = useDebugMode();
    this.isDebugMode = isDebugMode;
  },
  methods: {
    handleChange(index, event) {
      this.$emit("handleConvertFlag", {
        index,
        checked: event.target.checked,
      });
    },
    handleRemoveFile(index) {
      this.$emit("removeFile", index);
    },
    getExtension({ file_name }) {
      return fileType.getExtension(file_name);
    },
    getDescription(file) {
      const extension = this.getExtension(file);
      return fileType.getDescription(this.lang, extension);
    },
    canConvertFile(file) {
      const extension = this.getExtension(file);
      const availableExtension = ["pdf", "docx"].includes(extension);
      return this.canChange && this.isDebugMode && availableExtension;
    },
  },
};
</script>

<style scoped>
.file-tab {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.file-icon-upload {
  position: relative;
  width: 40px;
  height: 50px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.file {
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 4px;
  min-height: 82px;
  grid-template-columns: auto 1fr auto;
  max-width: 300px;
  padding: 4px;
  min-width: 200px;
  position: relative;
  width: auto;
}

.icon-upload {
  width: 40px !important;
  /* Ajusta el tamaño del ícono */
  height: auto !important;
  border-radius: 5px;
  /* Redondea la imagen si es necesario */
}

.file-info {
  display: grid;
  gap: 4px;
}

.filename {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  color: #333;
  display: -webkit-box;
  font-size: 13px;
  font-weight: bold;
  line-clamp: 2;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  text-wrap: balance;
}

.file-type {
  position: absolute;
  bottom: 15px;
  /* Ajusta la distancia desde la parte inferior */
  left: 50%;
  transform: translateX(-50%) translateY(10%);
  font-weight: bolder;
  padding: 3px 8px;
  font-weight: bold;
  border-radius: 4px;
  font-size: 12px;
  background-color: gray;
}

.file-checkbox-container {
  .file-label-content {
    align-items: center;
    display: flex;
    font-size: 12px;
    margin-bottom: 0;
    width: 100%;

    .file-checkbox {
      height: 12px;
      top: 0;
      width: 12px;
      margin-right: 4px;
    }
  }
}

.close-btn-container {
  height: 100%;
}

.close-btn {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #888;
  cursor: pointer;
}

.close-btn:hover {
  color: #000;
}

.bg-not-change {
  background-color: #ec6288 !important;
}

.bg-not-change .filename,
.bg-not-change .file-type,
.bg-not-change .icon {
  color: white !important;
}
</style>
