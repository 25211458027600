<template>
  <div class="sol-center-header">
    <div
      :class="[
        { 'solution-closed-carrousel': !showHeader },
        'solutionCarrousel container-solutionCarrousel allign-center-projects',
      ]"
    >
      <carrousel
        ref="carrousel"
        :project_id="project_id"
        :loading="loading"
        :lang="lang"
        :space="space"
        page="solution"
        :showHeader="showHeader"
        :percentage="percentage"
        :role="role"
        @change-project="changeProject($event)"
      />
      <tabs-container
        v-if="showHeader"
        :tabs="getTabs()"
        :selectedTab="selectedTab"
        @select-tab="selectTab($event)"
      />
      <div
        class="solution-close-header div-center"
        @click="showHeader = !showHeader"
      >
        <img
          v-if="showHeader"
          :src="require('@/assets/img/solution/arrow-up.svg')"
          alt="open/close"
          class="solution-arrow-header"
        />
        <img
          v-else
          :src="require('@/assets/img/solution/arrow-down.svg')"
          alt="open/close"
          class="solution-arrow-header"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Carrousel from "../general/CarrouselComponent.vue";
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";

export default {
  components: {
    Carrousel,
  },
  mixins: [translationMixin],
  props: [
    "mapOpened",
    "project_id",
    "solution_id",
    "station_id",
    "loading",
    "lang",
    "percentage",
    "space",
    "role",
    "selectedTab",
  ],
  emits: ["changeProject", "selectTab"],
  data() {
    return {
      projectCarrousel: [],
      selectOpened: false,
      initCarrousel: 0,
      finalCarrousel: 3,
      showHeader: true,
      percentagesSecondaries: [0, 0, 0],
      traducciones: [
        {
          name: "itinerario",
          es: "Itinerario",
          en: "Itinerary",
        },
        {
          name: "assistant",
          es: "Asistente",
          en: "Assistant",
        },
        {
          name: "expert",
          es: "Experto",
          en: "Expert",
        },
        {
          name: "evaluatorAssistant",
          es: "Evaluador",
          en: "Assistant",
        },
      ],
    };
  },
  methods: {
    getTabs() {
      return [
        this.langFilter("itinerario"),
        this.langFilter("expert"),
        this.langFilter("evaluatorAssistant"),
      ];
    },
    selectTab(tabIndex) {
      this.$emit("selectTab", tabIndex);
    },
    loadCarrousel() {
      this.$refs.carrousel.on_created_carrousel();
    },
    changeProject(project_id) {
      this.$parent.loading = true;

      axios
        .get(
          `${process.env.VUE_APP_API_URL}/solution/getFirstStationSolutionByProject`,
          {
            params: {
              project_id,
            },
          },
        )
        .then((response) => {
          const { solution, station } = response?.data || {};

          const solution_id = solution ?? 0;
          const station_id = station ?? 0;

          this.$emit("changeProject", { project_id, station_id, solution_id });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
