<template>

<!-- Esta vista no se usa en la nueva version del foro -->
  <div class="forum-card" @click="get_thread()">
    <div class="published-by">
      <h1>{{ post.user_first_name }}<br />{{ post.user_last_name }}</h1>
      <p>{{ langFilter(post.role) }}</p>
    </div>
    <div class="topic">
      <h1>{{ post.title }}</h1>
      <p v-if="post.program_name !== null">
        {{ langFilter("Program") }}: <span>{{ post.program_name }}</span>
      </p>
      <p v-if="post.project_name !== null">
        {{ langFilter("Project") }}: <span>{{ post.project_name }}</span>
      </p>
      <p
        v-if="post.program_name == null && post.project_name == null"
        style="height: 16px"
      ></p>
    </div>
    <div
      class="last-message"
      v-if="info.user_first_name || info.user_last_name"
    >
      <p v-if="info.user_first_name || info.user_last_name">
        {{ info.user_first_name }} {{ info.user_last_name }}
      </p>
      <label v-if="info.created_at !== null">{{
        date_format(info.created_at)
      }}</label>
    </div>
    <div class="last-message" v-else>
      <p>No hay mensajes</p>
    </div>
    <div class="subtopic">
      <h1>{{ info.count_messages - 1 }}</h1>
      <svg
        width="22"
        height="19"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 1H2C1.44772 1 1 1.44772 1 2V9.045C1 9.57243 1.42757 10 1.955 10C2.47417 10 2.89824 10.4148 2.90976 10.9338L2.95837 13.124C2.96973 13.6362 3.65238 13.8019 3.89736 13.352L5.43846 10.5218C5.61359 10.2002 5.9505 10 6.31671 10H13C13.5523 10 14 9.55229 14 9V2C14 1.44772 13.5523 1 13 1Z"
          stroke="#ACA3D0"
        />
        <path
          d="M14 4H20C20.5523 4 21 4.44772 21 5V12.045C21 12.5724 20.5724 13 20.045 13V13C19.5258 13 19.1018 13.4148 19.0902 13.9338L19.0416 16.124C19.0303 16.6362 18.3476 16.8019 18.1026 16.352L16.5615 13.5218C16.3864 13.2002 16.0495 13 15.6833 13H9C8.44772 13 8 12.5523 8 12V10"
          stroke="#ACA3D0"
        />
      </svg>
    </div>
    <div class="fav">
      <svg
        v-if="$parent.is_fav(post)"
        width="16"
        height="16"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click.stop.prevent="$parent.remove_post_fav(post)"
      >
        <path
          class="filled"
          d="M7.52772 0.881555C7.68337 0.433772 8.31663 0.433775 8.47228 0.881552L9.65039 4.27081C9.85638 4.86342 10.4094 5.26522 11.0367 5.27801L14.6241 5.35111C15.0981 5.36077 15.2938 5.96305 14.916 6.24944L12.0567 8.41723C11.5567 8.79626 11.3455 9.4464 11.5272 10.0469L12.5662 13.4813C12.7035 13.9351 12.1912 14.3073 11.802 14.0365L8.85677 11.9871C8.3418 11.6287 7.6582 11.6287 7.14323 11.987L4.19797 14.0365C3.80884 14.3073 3.29652 13.9351 3.4338 13.4813L4.47285 10.0469C4.65452 9.4464 4.44328 8.79626 3.94333 8.41723L1.08401 6.24945C0.706245 5.96304 0.901938 5.36077 1.3759 5.35111L4.96333 5.27801C5.59058 5.26522 6.14362 4.86342 6.34961 4.27081L7.52772 0.881555Z"
          fill="#301889"
          stroke="#301889"
        />
      </svg>

      <svg
        v-if="!$parent.is_fav(post)"
        width="16"
        height="16"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click.stop.prevent="$parent.add_post_fav(post)"
      >
        <path
          d="M7.52772 0.881555C7.68337 0.433772 8.31663 0.433775 8.47228 0.881552L9.65039 4.27081C9.85638 4.86342 10.4094 5.26522 11.0367 5.27801L14.6241 5.35111C15.0981 5.36077 15.2938 5.96305 14.916 6.24944L12.0567 8.41723C11.5567 8.79626 11.3455 9.4464 11.5272 10.0469L12.5662 13.4813C12.7035 13.9351 12.1912 14.3073 11.802 14.0365L8.85677 11.9871C8.3418 11.6287 7.6582 11.6287 7.14323 11.987L4.19797 14.0365C3.80884 14.3073 3.29652 13.9351 3.4338 13.4813L4.47285 10.0469C4.65452 9.4464 4.44328 8.79626 3.94333 8.41723L1.08401 6.24945C0.706245 5.96304 0.901938 5.36077 1.3759 5.35111L4.96333 5.27801C5.59058 5.26522 6.14362 4.86342 6.34961 4.27081L7.52772 0.881555Z"
          stroke="#ACA3D0"
        />
      </svg>
    </div>
    <div class="delete">
      <span aria-hidden="true" v-if="post.user_id == this.$parent.user_id">
        <svg
          data-bs-toggle="modal"
          data-bs-target="#modalConfirmation"
          width="14"
          height="16"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click.stop.prevent="delete_subpost(post)"
        >
          <path
            d="M1 3.89062H12.5556"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
          <path
            d="M3.89062 2C3.89062 1.44772 4.33834 1 4.89062 1H8.6684C9.22069 1 9.6684 1.44772 9.6684 2V3.88889H3.89062V2Z"
            stroke="#ACA3D0"
          />
          <path
            d="M2.44531 3.89062H11.112V13.0017C11.112 13.554 10.6643 14.0017 10.112 14.0017H3.44531C2.89303 14.0017 2.44531 13.554 2.44531 13.0017V3.89062Z"
            stroke="#ACA3D0"
          />
          <path
            d="M8.22266 6.77734V11.1107"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
          <path
            d="M5.33203 6.77734V11.1107"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
        </svg>
      </span>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";

// import $ from "jquery";
import axios from "axios";

export default {
  props: ["lang", "post"],

  data() {
    return {
      info: {},
      //Traducción
      traducciones: [
        {
          name: "Program",
          es: "Programa",
          en: "Program",
        },
        {
          name: "Project",
          es: "Proyecto",
          en: "Project",
        },
        {
          name: "Entrepreneur",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "Tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "Mentor",
          es: "Mentor",
          en: "Mentor",
        },
      ],
    };
  },
  created() {
    this.getSubpostInfo();
  },
  updated() {},
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    getSubpostInfo() {
     
      axios
          .post(`${process.env.VUE_APP_API_URL}/forum-subpost-info`,{
            post_id: this.post.post_id,
          })
          .then((response) => {
           
            this.info = response.data;
          })
          .catch(() => {
            // console.log("error");
          });
      
    },
    date_format(created_at) {
      var value = Date.parse(created_at);

      const date = new Date(value);

      return (
        "" +
        date.getDate() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getFullYear() +
        ", " +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes()
      );
    },
    get_thread() {
	this.$router.push({ path: "/forum-thread/" + this.post.post_id })
      this.$parent.quoteMessage = "";
      this.$parent.view = 0;
      this.$parent.addPost = false;
      this.$parent.pagination1 = 0;
      this.$parent.pagination2 = 50;
      this.$nextTick(() => {
        this.$parent.$refs.pagination.paginationFirst();
      });

      this.$parent.current_subpost = this.post.post_id;
      
      axios
        .post(`${process.env.VUE_APP_API_URL}/forum-get-name-of-post`, {
          post_id: this.post.post_id,
        })
        .then((response) => {
          this.$parent.name_of_post_selected = response.data.title;
        })
        .catch(() => {
          // console.log("error");
        });
      var post = {};
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/forum-get-subpost-thread-messages`,
            {
              post_id: this.post.post_id,
              user_id: this.$parent.user_id,
            }
          )
          .then((response) => {
            
            post = response.data.posts;
            this.$parent.subpost_clicked_id = post.main_id;
            this.$parent.current_post = post.main_id;

            this.$parent.current_post = post.main_id;

            
          })
          .catch(() => {
            // console.log("error");
          });

      axios
          .post(`${process.env.VUE_APP_API_URL}/forum-thread-messages`,{
            post_id: this.post.post_id,
            user_id: this.$parent.user_id,
          })
          .then((response) => {
          
            this.$parent.like_subpost = response.data.subpost_like;
            this.$parent.like_threads = response.data.thread_like;

            this.$parent.normal_posts = response.data.posts;
            this.$parent.favourite_posts = response.data.favourite;
            // this.$parent.normal_posts.unshift(post);
            // if (this.$parent.is_fav(post)) {
            //   this.$parent.favourite_posts.unshift(post);
            // }
            this.$parent.main_post=post;
            this.$parent.active_filter_fav();
            this.$parent.view = 3;

            })

          .catch(() => {
            // console.log("error");
          });

      
    },
    delete_subpost(post) {
      this.$parent.$refs.modalConfirmation.confirmationMessage =
        "Are you sure you want to delete this subtopic?";
      this.$parent.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$parent.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$parent.$refs.modalConfirmation.confirmationButtonText2 =
        "Si, Eliminar";
      this.$parent.confirmModal=0;
      this.$parent.delete_post = post;
      // this.$parent.openModalConfirmation();
    },
  },
  mixins: [translationMixin],
};
</script>
