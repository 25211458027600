<template>
    <div class="chat-avatar" :class="cssClass" >{{ label }}</div>
</template>
<script>
import translationMixin from '../../mixins/translationMixin.js';

export default {
    props: ['lang', 'bgColor', 'fontColor', 'label', 'cssClass'],
    data() {
        return {}
    },
    created() {},
    methods: {},
    mixins: [translationMixin],
}
</script>
<style scoped>
.chat-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 20px;
    height: 42px;
    width: 42px;
    font-family: 'Poppins-Medium';
    font-size: 18px;
}
</style>