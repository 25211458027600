<template>
  <div class="mb-5 templates-list-categories-dropdown">
    <div class="templates-FilterCategory" :style="{ position: 'relative' }">
      <div @click="openPopUp">
        <div class="templates-FilterCategoryText">
          {{ langFilter("categories") }}
        </div>
        <div class="templates-FilterCategoryIcon">
          <svg
            width="7"
            height="5"
            viewBox="0 0 7 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L3.5 4L4.75 2.5L6 0.999999"
              stroke="#301889"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div
        class="templates-list-categories-dropdown-content"
        :style="{ display: isPopupVisible ? 'block' : 'none' }"
      >
        <ul id="filter-programs-container">
          <li id="category-filter-all">
            <label class="categoryRowAll" @click="selectAllCategories">
              <span
                class="categoryAll m-0"
                v-if="filters.categories.length != listCategories.length"
                >{{ langFilter("select_all") }}</span
              >
              <span class="categoryAll m-0" v-else>
                {{ langFilter("delete_all") }}
              </span>
              <span
                class="category-checkmark"
                id="input-checkmark"
                v-bind:class="
                  filters.categories.length == listCategories.length
                    ? 'category-checkmark-check'
                    : 'category-checkmark'
                "
              >
                <img
                  class="templates-tick_image"
                  :src="require('@/assets/img/projects/tick.svg')"
              /></span>
            </label>
          </li>

          <li
            v-for="(category, categoriesIndex) in listCategories"
            :key="categoriesIndex"
            :id="'category-filter-' + categoriesIndex"
          >
            <label class="categoryRow" @click.stop>
              <span class="categoryName">{{ category.name }}</span>
              <input
                :value="category.id"
                :id="'category_' + category.id"
                type="checkbox"
                class="categoryCheckbox"
                v-model="filters.categories"
              />
              <span
                class="category-checkmark"
                id="input-checkmark"
                v-bind:class="
                  filters.categories.includes(category.id)
                    ? 'category-checkmark-check'
                    : 'category-checkmark'
                "
              >
                <img
                  class="templates-tick_image"
                  :src="require('@/assets/img/projects/tick.svg')"
                />
              </span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="templates-categoryContainer"
    v-for="(category, index) in categoriesContainer"
    :key="index"
    :id="'categoryContainer-' + index"
  >
    <div class="templates-categoryContainer-title">
      {{ category.name }}
    </div>
    <div class="templates-templatesList">
      <div
        v-for="template in filteredTemplatesByCategory(category.id)"
        :key="template.id"
        :id="'template-card-' + template.id"
      >
        <div class="templates-templateCard">
          <div
            class="templates-templateCardImage mb-3"
            :style="{
              backgroundImage: `url('${getTrackHeader(template.img)}')`,
            }"
          ></div>
          <strong> {{ template.name }}</strong>
          <div class="templates-templateCardText">
            <span class="templates-templateCardCreatedBy">
              {{ langFilter("created_by") }}: {{ template.creatorName }}
            </span>

            <span class="templates-templateCardCategory">
              {{ langFilter("category") }}: {{ template.category_name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import translations from "./translate/AssistantTranslate.json";

export default {
  name: "AssistanTemplatesList",
  props: ["listTemplates", "lang"],
  data() {
    return {
      traducciones: translations,
      isPopupVisible: false,
      cargaGif: 0,
      filters: {
        name: "",
        categories: [],
      },
      listCategories: this.generateCategories(this.listTemplates),
    };
  },
  computed: {
    templatesFiltered() {
      return this.listTemplates.filter((template) => {
        const matchesName = this.filters.name
          ? template.name
              .toLowerCase()
              .includes(this.filters.name.toLowerCase())
          : true;
        const matchesCategory = this.filters.categories.length
          ? this.filters.categories.includes(template.category_id)
          : true;
        return matchesName && matchesCategory;
      });
    },

    categoriesContainer() {
      return this.listCategories.filter((category) =>
        this.templatesFiltered.some(
          (template) => template.category_id === category.id
        )
      );
    },
  },
  methods: {
    generateCategories(templates) {
      const uniqueCategories = new Map();

      templates.forEach((template) => {
        const id = template.category_id;
        const name = template.category_name;

        if (!uniqueCategories.has(id)) {
          uniqueCategories.set(id, { id, name });
        }
      });

      return Array.from(uniqueCategories.values());
    },
    selectAllCategories() {
      if (this.filters.categories.length === this.listCategories.length) {
        this.filters.categories = [];
      } else {
        this.filters.categories = this.listCategories.map(
          (category) => category.id
        );
      }
    },
    openPopUp() {
      this.isPopupVisible = !this.isPopupVisible;
      this.filtered = this.isPopupVisible;
    },
    filteredTemplatesByCategory(categoryId) {
      return this.templatesFiltered.filter(
        (template) => template.category_id === categoryId
      );
    },

    getTrackHeader(track) {
      return `${process.env.VUE_APP_API_STORAGE}/img/track_headers/${track}`;
    },
  },
  mixins: [translationMixin],
};
</script>
