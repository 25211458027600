<template>
  <button
    class="itinerary-display-button itinerary-display-button-margin"
    @click="setOpened"
  >
    <img
      :src="
        require(`@/assets/img/newitinerary/${
          opened ? 'arrow-down' : 'arrow'
        }.svg`)
      "
      alt="arrow"
    />
  </button>
</template>

<script>
export default {
  props: ["opened"],
  methods: {
    setOpened() {
      this.$emit("setOpened");
    },
  },
};
</script>
