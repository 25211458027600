<template>
    <div
        class="container-btn-atras back-position"
    >
        <span onclick="window.history.go(-1); return false;" class="atras">
            <img src="/img/general/new_back.svg" alt="Back" />
        </span>
    </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
    props: ["lang"],
    data() {
        return {
            // traduccion
            traducciones: [
                {
                    name: "atras",
                    es: "Atrás",
                    en: "Back",
                },
            ],
        };
    },
    mixins: [translationMixin],
};
</script>
