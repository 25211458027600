<script setup>
import { RouterView } from "vue-router";
import axios from "axios";
import Sidebar from "./components/sidebar/SidebarComponent.vue";
import Cookies from "js-cookie";
</script>

<style>
@import "../src/assets/css/sidebar/sidebar.css";
@import "../src/assets/css/general.css";
@import "../src/assets/css/errors.css";
@import "../src/assets/css/projects/modalResources.css";
@import "../src/assets/css/fonts.css";
@import "../src/assets/css/chatgpt.css";
@import "../src/assets/css/sidebar/right-sidebar.css";
</style>

<template>
  <div v-if="!showSidebar || (lang != '' && spaces != [] && role != '')">
    <Sidebar
      class="sidebar-main-container"
      @changeSpace="changeSpace($event)"
      @reloadHomeNotifications="reloadHomeNotifications()"
      v-if="showSidebar"
      :space="spaces"
      :role="role"
      :lang="lang"
    />
    <div :class="showSidebar ? 'div-general-menu-lateral' : ''">
      <RouterView
        v-if="showData"
        @openAlert="openAlert($event)"
        :homeNotifications="homeNotifications"
        :spaces="spaces"
        :role="role"
        :lang="lang"
      />
      <alertMessage2
        :succesful="succesful"
        :errors="errors"
        :lang="lang"
        ref="alerts2"
      />
    </div>
    {{ currentRoute }}
    <chat-bot v-if="showGPT" :space="spaces[0]" :lang="lang" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSidebar: false,
      spaces: [],
      role: "",
      lang: "",
      showData: true,
      homeNotifications: 0,
      showGPT: false,
    };
  },
  created() {
    setTimeout(() => {
      this.showGPT =
        !this.$route.path.includes("login") &&
        !this.$route.path.includes("register");
    }, 100);
    if (Cookies.get("token")) {
      this.getInitialData();
    } else {
      if (
        window.location.pathname.includes(
          "/autologin/" + process.env.VUE_APP_AUTOLOGIN_KEY
        )
      ) {
        const url = window.location.pathname.split("/");
        axios
          .post(`${process.env.VUE_APP_API_URL}/autologin`, {
            token: url[2],
            id: url[3],
          })
          .then((response) => {
            Cookies.set("token", response.data.token, { expires: 7 });
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + Cookies.get("token");
          })
          .finally(() => {
            window.location.href = "/home";
          });
      } else {
        if (
          !window.location.pathname.includes("/privacy-policy") &&
          !window.location.pathname.includes("/terms-conditions") &&
          !window.location.pathname.includes("/signup/") &&
          !window.location.pathname.includes("/login") &&
          !window.location.pathname.includes("/recover-password")
        ) {
          window.location.href = "/login";
        }
      }
    }
  },
  methods: {
    checkGptAccess() {
      if (this.spaces.length > 0)
        return this.spaces[0].id === 19930 || this.spaces[0].id === 36921;
      return false;
    },
    checkIfShowChat() {
      if (this.spaces.length > 0) {
        if (this.spaces[0].id === 19930 || this.spaces[0].id === 36921) {
          return true;
        }
      }
      return false;
    },
    changeSpace(space) {
      this.spaces = space;
      this.showData = false;
      this.$nextTick(() => {
        this.showData = true;
      });
    },
    reloadHomeNotifications() {
      this.homeNotifications++;
    },
    getInitialData() {
      this.showSidebar = true;
      if (this.spaces.length == 0) {
        axios
          .get(`${process.env.VUE_APP_API_URL}/getInitialData`)
          .then((response) => {
            this.spaces = response.data.space;
            this.role = response.data.role;
            this.lang = response.data.lang;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Cookies.remove("token");
              window.location.href = "/login";
            }
          });
      }
    },
    openAlert(data) {
      this.$refs.alerts2.title = "";
      this.$refs.alerts2.text = data.message;
      this.$refs.alerts2.mySuccesful = false;
      this.$refs.alerts2.purple = data.colour == 2;
      this.$refs.alerts2.openErrorsAlert();
    },
  },
  mounted() {
    window.addEventListener("popstate", this.onBackButtonPressed);
  },
  beforeUnmount() {
    window.removeEventListener("popstate", this.onBackButtonPressed);
  },
  watch: {
    $route(to) {
      document.title = to.name + " - Acceleralia" || "Acceleralia";
    },
  },
};
</script>
