<template>
<!-- Card container -->
  <div class="forum-card" @click="get_sub_posts()">
    <!-- User info -->
    <div class="published-by">
      <h1>{{ post.user_first_name }}<br />{{ post.user_last_name }}</h1>
      <p>{{ langFilter(post.role) }}</p>
    </div>
    <div class="topic">
      <h1>{{ post.title }}</h1>
      <p>{{ post.description }}</p>
    </div>

    <div class="subtopic">
      <!-- Count the number of messages -->
      <h1>{{ post.count_subpost }}</h1>
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 8.5L1 5L8 1.5L15 5L8 8.5Z"
          stroke="#ACA3D0"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          class="fill-none"
          d="M1 8.5L8 12L15 8.5"
          stroke="#ACA3D0"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          class="fill-none"
          d="M1 12L8 15.5L15 12"
          stroke="#ACA3D0"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
<!-- Fav button -->
    <div class="fav">
      <svg
        v-if="$parent.is_fav(post)"
        width="16"
        height="16"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click.stop.prevent="$parent.remove_post_fav(post)"
      >
        <path
          class="filled"
          d="M7.52772 0.881555C7.68337 0.433772 8.31663 0.433775 8.47228 0.881552L9.65039 4.27081C9.85638 4.86342 10.4094 5.26522 11.0367 5.27801L14.6241 5.35111C15.0981 5.36077 15.2938 5.96305 14.916 6.24944L12.0567 8.41723C11.5567 8.79626 11.3455 9.4464 11.5272 10.0469L12.5662 13.4813C12.7035 13.9351 12.1912 14.3073 11.802 14.0365L8.85677 11.9871C8.3418 11.6287 7.6582 11.6287 7.14323 11.987L4.19797 14.0365C3.80884 14.3073 3.29652 13.9351 3.4338 13.4813L4.47285 10.0469C4.65452 9.4464 4.44328 8.79626 3.94333 8.41723L1.08401 6.24945C0.706245 5.96304 0.901938 5.36077 1.3759 5.35111L4.96333 5.27801C5.59058 5.26522 6.14362 4.86342 6.34961 4.27081L7.52772 0.881555Z"
          fill="#301889"
          stroke="#301889"
        />
      </svg>

      <svg
        v-if="!$parent.is_fav(post)"
        width="16"
        height="16"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click.stop.prevent="$parent.add_post_fav(post)"
      >
        <path
          d="M7.52772 0.881555C7.68337 0.433772 8.31663 0.433775 8.47228 0.881552L9.65039 4.27081C9.85638 4.86342 10.4094 5.26522 11.0367 5.27801L14.6241 5.35111C15.0981 5.36077 15.2938 5.96305 14.916 6.24944L12.0567 8.41723C11.5567 8.79626 11.3455 9.4464 11.5272 10.0469L12.5662 13.4813C12.7035 13.9351 12.1912 14.3073 11.802 14.0365L8.85677 11.9871C8.3418 11.6287 7.6582 11.6287 7.14323 11.987L4.19797 14.0365C3.80884 14.3073 3.29652 13.9351 3.4338 13.4813L4.47285 10.0469C4.65452 9.4464 4.44328 8.79626 3.94333 8.41723L1.08401 6.24945C0.706245 5.96304 0.901938 5.36077 1.3759 5.35111L4.96333 5.27801C5.59058 5.26522 6.14362 4.86342 6.34961 4.27081L7.52772 0.881555Z"
          stroke="#ACA3D0"
        />
      </svg>
    </div>
<!-- Delete button -->
    <div class="delete">
      <span aria-hidden="true" v-if="post.user_id == this.$parent.user_id">
        <svg
          data-bs-toggle="modal"
          data-bs-target="#modalConfirmation"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click.stop.prevent="delete_post(post)"
        >
          <path
            d="M1 3.89062H12.5556"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
          <path
            d="M3.89062 2C3.89062 1.44772 4.33834 1 4.89062 1H8.6684C9.22069 1 9.6684 1.44772 9.6684 2V3.88889H3.89062V2Z"
            stroke="#ACA3D0"
          />
          <path
            d="M2.44531 3.89062H11.112V13.0017C11.112 13.554 10.6643 14.0017 10.112 14.0017H3.44531C2.89303 14.0017 2.44531 13.554 2.44531 13.0017V3.89062Z"
            stroke="#ACA3D0"
          />
          <path
            d="M8.22266 6.77734V11.1107"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
          <path
            d="M5.33203 6.77734V11.1107"
            stroke="#ACA3D0"
            stroke-linecap="round"
          />
        </svg>
      </span>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
// import $ from "jquery";
import axios from "axios";

export default {
  props: ["lang", "post"],

  data() {
    return {
      //Traducción
      traducciones: [
        {
          name: "Entrepreneur",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "Tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "Mentor",
          es: "Mentor",
          en: "Mentor",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    // Get thread message messages
    get_sub_posts() {
      // window.location.href=window.location.origin+'/forum/'+this.post.post_id;
		this.$router.push({ path: "/forum/" + this.post.post_id });
      this.$parent.view = 0;
      this.$parent.addPost = false;
      this.$parent.pagination1 = 0;
      this.$parent.pagination2 = 50;
      this.$nextTick(() => {
        this.$parent.$refs.pagination.paginationFirst();
      });

      this.$parent.current_post = this.post.post_id;

      this.$parent.get_name_of_topic();
      
      axios
          .post(`${process.env.VUE_APP_API_URL}/forum-subposts`,{
            post_id: this.post.post_id,
            user_id: this.$parent.user_id,
          })
          .then((response) => {
            this.$parent.subpost_clicked_id = this.post.post_id;

            this.$parent.normal_posts = response.data.posts;
            this.$parent.favourite_posts = response.data.favourite;
            this.$parent.active_filter_fav();
            this.$parent.view = 2;
          })
          .catch(() => {
            // console.log("error");
          });
      
    },
    // Open delete topic modal confirmation
    delete_post(post) {
      this.$parent.confirmModal=0;
      this.$parent.delete_post=post;
      this.$parent.$refs.modalConfirmation.confirmationMessage = "Are you sure you want to delete this topic?";
      this.$parent.$refs.modalConfirmation.confirmationMessage2 = "";

      this.$parent.$refs.modalConfirmation.confirmationButtonText2= "Si, Eliminar";
      // axios
      //     .post(`${process.env.VUE_APP_API_URL}/forum-delete-post`,{
      //       post_id: post.post_id,
      //       user_id: this.$parent.user_id,
      //     })
      //     .then(() => {
      //       var index_posts = this.$parent.normal_posts.findIndex((value) => {
      //         return value.post_id === post.post_id;
      //       });
      //       if (index_posts !== -1)
      //         this.$parent.normal_posts.splice(index_posts, 1);
      //       var index_fav_posts = this.$parent.favourite_posts.findIndex(
      //         (value) => {
      //           return value.post_id === post.post_id;
      //         }
      //       );
      //       if (index_fav_posts !== -1)
      //         this.$parent.favourite_posts.splice(index_fav_posts, 1);
      //     })
      //     .catch(() => {
      //       // console.log("error");
      //     });
      
    },
  },
  mixins: [translationMixin],
};
</script>
