<template>
    <div>
        <div v-if="type!=='select' && type!=='textarea' && type!=='search_select'" >
            <input :name="name" :id="name" :type="type"  :placeholder="placeholder">

        </div>
        <div v-else-if="type=='textarea'" :id="'froala-'+name" >
            <textarea :name="name" :id="name" :placeholder="placeholder"></textarea>

        </div>
        <div v-else-if="type=='select'">
            <select :name="name" :id="name">
                <option value="0">{{placeholder}}</option>
                <option v-for="(option, index) in options" :key="index" :value="option.value">{{option.name}}</option>
            </select>
        </div>
        <div v-else-if="type=='search_select'">
            <div class="select-search-container"  @click="openSelectSearch" v-outside="disableSelectSearch">
                <input class="modal-input-no-hover" type="text" :name="name" :id="name" :placeholder="placeholder" readonly>
                <input v-show="false" type="text" :name="name+'-value'" :id="name+'-value'" :placeholder="placeholder" disabled>
                <div class="select-search" v-show="showSelectSearch">
                    <input type="text" :placeholder="langFilter('Search...')" v-model="searchOptions">
                    <ul>
                        <li  v-for="(option, index) in search_options" :key="index" @click="hideSelectSearch(),$parent.$parent.$parent.selectedValueModal(name,option)" >
                            {{option.name}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import translationMixin from '../../mixins/translationMixin.js';



    export default{
 
    props:['lang','placeholder','name','type','options','function_identifier'],    

   
        data(){
            
            return {
                showSelectSearch:false,
               searchOptions:"",
                //Traducción
                traducciones: [
                   {
                        name:'Search...',
                        es:'Buscar...',
                        en:'Search...'
                    }, 
                ],
                
            
                
                
            }
            
        },
        created(){
            
            
        },
        mounted(){
           
        },
        computed:{
           search_options(){
                return this.options.filter(option => {
                    
                    return (option.name.toLowerCase().includes(this.searchOptions.toLowerCase())  )
                    
                })
           }
        },
        methods:{
            openSelectSearch(el){
                
                if(el.target.tagName=='LI' ){
                    this.showSelectSearch=false;
                }else{
                    this.showSelectSearch=true;

                }
                
            },
            disableSelectSearch(el){
                
                if(el.target.className=='new-project-select-search-container'){
                    
                    this.showSelectSearch=true;

                }else{
                    this.showSelectSearch=false;

                }

            },
            hideSelectSearch(){
                    this.showSelectSearch=false;

            }
           
            

        },
            mixins: [translationMixin], 

            
            

            };

</script>
