<template>
  <div>
    <input v-model="spaceName" placeholder="Space Name" type="text" />
    <br /><br />
    <p>Img</p>
    <input
      type="file"
      @change="getFile"
      :key="fileInputKey"
      accept="image/x-png,image/gif,image/jpeg"
      multiple
    />
    <br /><br />
    <div v-if="addingUsers">
      <user-table-space
        @addUser="addUser($event)"
        @removeUser="removeUser($event)"
      />
    </div>
    <button @click="addingUsers = !addingUsers" class="btn btn-secondary">
      {{ addingUsers ? "Cancel Users" : "Add Users" }}
    </button>

    <br /><br />
    <button @click="createSpace" class="btn btn-primary">Create</button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      spaceName: "",
      files: [],
      users: [],
      fileInputKey: 0,
      addingUsers: false,
    };
  },
  methods: {
    getFile(event) {
      this.file = event.target.files[0];
    },
    createSpace() {
      if (this.spaceName == "") {
        alert("Please enter a space name");
        return;
      }
      if (this.file == null) {
        alert("Please select a file");
        return;
      }
      const formData = new FormData();
      formData.append("space_name", this.spaceName);
      formData.append("file", this.file);
      formData.append("users", JSON.stringify(this.users));

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/manageSpaces-acc/createSpace`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.$emit("msg", "Space created");
            this.spaceName = "";
            this.file = null;
            this.fileInputKey++;
            this.addingUsers = false;
            this.users = [];
          } else {
            this.$emit("msg", "Error creating space");
          }
        })
        .catch(function () {
          this.$emit("msg", "Error creating space");
        });
    },
    addUser(user) {
      this.users = user;
    },
    removeUser(user) {
      this.users = this.users.filter((u) => u.id != user);
    },
  },
};
</script>
