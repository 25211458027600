<style>
@import "../../../src/assets/css/components/modal.css";
@import "../../../src/assets/css/mystyle.css";
</style>

<template>
  <div class="container-track-it">
    <div v-if="itinerary[0] != undefined">
      <div
        class="itinerary-body-modal"
        v-for="(stat, statIndex) in itinerary"
        :key="statIndex"
      >
        <div @click="openCloseSol(stat[0].station_id)" class="track-it-div">
          <span class="tema-modal-track"
            >{{ langFilter("chapter") }} {{ statIndex + 1 }}.</span
          >
          {{ stat[0].station }}
          <img
            :id="`arrow-it-${stat[0].station_id}`"
            class="arrow-modal-track"
            :src="require('@/assets/img/track/arrow-right-modal.svg')"
            alt="arrow"
          />
        </div>
        <div
          :class="`hide track-it-apt-div stations-from-${stat[0].station_id}`"
          v-for="(sol, solIndex) in itinerary[statIndex]"
          :key="solIndex"
        >
          <div
            class="track-it-apt-div-hover"
            @click="openCloseQuest(sol.solution_id)"
          >
            <span class="tema-modal-track"
              >{{ langFilter("part") }} {{ solIndex + 1 }}.</span
            >
            {{ sol.solution }}
            <img
              v-if="questions[sol.solution_id] != undefined"
              :id="`arrow-sol-${sol.solution_id}`"
              class="arrow-modal-track"
              :src="require('@/assets/img/track/arrow-right-modal.svg')"
              alt="arrow"
            />
          </div>
          <div
            :class="`hide track-it-div track-it-quest-div solutions-from-${sol.solution_id}`"
            v-for="(quest, questIndex) in questions[sol.solution_id]"
            :key="questIndex"
          >
            <span class="tema-modal-track">
              {{ langFilter("question") }}
              {{ questIndex + 1 }}.</span
            >
            {{ quest.question }}
          </div>
        </div>

        <hr class="modal-line" />
      </div>
    </div>
    <div style="padding-left: 44px" class="data-track-name" v-else>
      {{ langFilter("no_itinerary") }}
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  props: ["lang", "trackStatSol", "track", "itinerary", "questions"],
  data() {
    return {
      selShow: [],
      itineraries: [],
      // traduccion
      traducciones: [
        {
          name: "question",
          es: "Pregunta",
          en: "Question",
        },
        {
          name: "select",
          es: "Seleccionar Track",
          en: "Select Track",
        },
        {
          name: "no_itinerary",
          es: "No hay plantillas",
          en: "No templates",
        },
        {
          name: "chapter",
          es: "Tema",
          en: "Chapter",
        },
        {
          name: "part",
          es: "Apartado",
          en: "Part",
        },
      ],
    };
  },
  created() {
    this.itineraries = this.trackStatSol.filter(
      (item) => item.track_id == this.track.id
    );
  },
  methods: {
    openCloseSol(id) {
      if ($(".stations-from-" + id).is(":visible")) {
        $(".stations-from-" + id).hide();
        $("#arrow-it-" + id).attr("src", "/img/track/arrow-right-modal.svg");
      } else {
        $(".stations-from-" + id).show();
        $("#arrow-it-" + id).attr("src", "/img/track/arrow-down-modal.svg");
      }
    },
    openCloseQuest(id) {
      if ($(".solutions-from-" + id).is(":visible")) {
        $(".solutions-from-" + id).hide();
        $("#arrow-sol-" + id).attr("src", "/img/track/arrow-right-modal.svg");
      } else {
        $(".solutions-from-" + id).show();
        $("#arrow-sol-" + id).attr("src", "/img/track/arrow-down-modal.svg");
      }
    },
  },
  mixins: [translationMixin],
};
</script>
