<template>
  <div class="adminpanel-permisions-div">
    <div class="row">
      <div
        v-for="(permission, permissionIndex) in permission_names"
        :key="permissionIndex"
        class="col-12 permisions-msg"
      >
        <admin-option
          :name="getOptionName(permissionIndex)"
          :content="permission"
          :permissionIndex="permissionIndex"
          :contentHover="permission.length"
          :lang="lang"
        />
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: ["space", "lang"],
  data() {
    return {
      roles: [],
      permissions: [],
      permission_names: [
        ["view_program", "create_program"],
        ["view_project", "create_project", "add_member_project"],
        ["view_itinerary", "create_itinerary", "template_itinerary"],
        ["create_theme", "create_subtopic"],
      ],
      traducciones: [
        {
          name: "ap-msg",
          es: "Desde el Panel del Admin, puedes otorgar los permisos a los usuarios de tu plataforma.",
          en: "From the Admin Panel, you can grant the permissions to the users of your platform.",
        },
      ],
    };
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/adminpanel/getPermissions`, {
        space: this.space,
      })
      .then((response) => {
        this.permissions = response.data;
      });

    axios
      .post(`${process.env.VUE_APP_API_URL}/adminpanel/getRoles`, {
        space_id: this.space.id,
      })
      .then((response) => {
        this.roles = response.data;
        this.initialLoad = false;
      });
  },
  methods: {
    getOptionName(index) {
      switch (index) {
        case 0:
          return "programs";
        case 1:
          return "projects";
        case 2:
          return "itineraries";
        case 3:
          return "forum";
        default:
          return "";
      }
    },
  },
  mixins: [translationMixin],
};
</script>
