<template>
  <div v-if="isSearch == 0" class="container">
    <div class="row">
      <div
        v-for="(category, categoryIndex) in categories"
        :key="categoryIndex"
        :class="[
          {
            'padding-0': categoryIndex == 1,
          },
          'col-lg-4 col-xl-2 align-center col-cat-track',
        ]"
      >
        <span
          @click="setFilterCat(category.id)"
          :class="[
            {
              catSelected: catSelected == category.id,
            },
            'category-track',
          ]"
          >{{ category.name }}</span
        >
      </div>
      <div class="col-lg-4 col-xl-2 align-center">
        <span
          @click="setFilterCat(0)"
          :class="[{ catSelected: catSelected == 0 }, 'category-track']"
        >
          {{ langFilter("all") }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  props: ["isSearch", "lang", "categories"],

  data() {
    return {
      catSelected: 0,
      // traduccion
      traducciones: [
        {
          name: "error",
          es: "Error, por favor contacta con support@acceleralia.com.",
          en: "Error, please contact support@acceleralia.com.",
        },
        {
          name: "all",
          es: "Todos",
          en: "All",
        },
      ],
    };
  },
  methods: {
    setFilterCat(id) {
      this.catSelected = id;
      this.$emit("catSelected", id);
    },
  },
  mixins: [translationMixin],
};
</script>
