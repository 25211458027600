<template>
  <Error
    title="maintenance-title"
    body="maintenance-body"
    link="link-redirect"
    image='<img class="cohete" id="cohete" src="/img/errors/cohete.svg">'
    vector="/img/errors/vector_158.svg"
    vamos="404-vamos"
    :noButton="true"
  />
</template>

<script>
export default {
  name: "MaintenanceView",
};
</script>

<style scoped>
.maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}
</style>
