<template>
  <div class="permission-main-container">
    <div class="permission-title-container">
      <img
        @click="showOption = true"
        v-if="!showOption"
        src="/img/admin_panel/permission-arrow-right.svg"
        alt="open"
      />
      <span>{{ langFilter(name) }}</span>
      <img
        @click="showOption = false"
        v-if="showOption"
        src="/img/admin_panel/permission-arrow-down.svg"
        alt="close"
      />
    </div>
    <div
      v-show="showOption"
      v-for="(content, contentIndex) in content"
      :key="contentIndex"
      class="permission-option-container"
    >
      <p>
        {{ langFilter(content) }}
      </p>

      <!-- Large button groups (default and split) -->
      <div
        class="btn-group permission-select-container"
        @click.stop="openDropdown"
        v-outside="closeDropdown"
      >
        <button
          class="btn btn-lg multiple-selector-container"
          type="button"
          aria-expanded="false"
          :id="'dropdown-button-' + content"
        >
          <span
            v-for="(role_ids, indexRole_ids) in $parent.permissions[
              this.content[contentIndex]
            ]"
            :key="indexRole_ids"
            class="dropdown-button-text"
            >{{ getRoleNameFromId(role_ids)
            }}<span
              v-if="
                indexRole_ids <
                $parent.permissions[this.content[contentIndex]].length - 1
              "
              class="dropdown-button-text-2"
              >,&nbsp;</span
            ></span
          >
          <img
            class="dropdown-button-text"
            src="/img/admin_panel/permission-arrow-down.svg"
          />
        </button>
        <ul class="dropdown-menu multiple-selector-dropdown">
          <div>
            <input
              type="checkbox"
              style="display: inline-block"
              :value="0"
              v-model="$parent.permissions[this.content[contentIndex]]"
              @click="
                onChangePermission(
                  0,
                  content,
                  getTableName(permissionIndex),
                  contentIndex
                )
              "
            />
            <p style="display: inline-block">
              {{ langFilter("all") }}
            </p>
          </div>
          <div v-for="(role, indexRole) in $parent.roles" :key="indexRole">
            <input
              type="checkbox"
              style="display: inline-block"
              :value="role.id"
              v-model="$parent.permissions[this.content[contentIndex]]"
              @click="
                onChangePermission(
                  role.id,
                  content,
                  getTableName(permissionIndex),
                  contentIndex
                )
              "
            />
            <p style="display: inline-block">
              {{ role.name }}
            </p>
          </div>
        </ul>
      </div>

      <!-- <select
                        class=""
                        @change="
                            onChangePermission(
                                $event,
                                content,
                                getTableName(permissionIndex)
                            )
                        "

                    >
                        <option :value="0">Admin</option>
                        <option
                            :value="1"
                            :selected="$parent.permissions[content] === 1"
                        >
                            {{ langFilter("all") }}
                        </option>
                    </select> -->
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  props: ["permissionIndex", "name", "contentHover", "content", "lang"],
  data() {
    return {
      checkedRoles: [],
      showOption: false,
      optionHover: [],
      traducciones: [
        {
          name: "programs",
          es: "Programas",
          en: "Programs",
        },
        {
          name: "projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "itineraries",
          es: "Itinerarios",
          en: "Itineraries",
        },
        {
          name: "forum",
          es: "Foro",
          en: "Forum",
        },
        {
          name: "all",
          es: "Todos",
          en: "All",
        },
        {
          name: "view_program",
          es: "Ver programas",
          en: "See existing programs",
        },
        {
          name: "create_program",
          es: "Crear programa nuevo",
          en: "Create new program",
        },
        {
          name: "view_project",
          es: "Ver todos proyectos del espacio",
          en: "See all existing projects ",
        },
        {
          name: "create_project",
          es: "Crear proyecto nuevo",
          en: "Create new project",
        },
        {
          name: "add_member_project",
          es: "Añadir miembros a un proyecto existente",
          en: "Add members to an existing project",
        },
        {
          name: "view_itinerary",
          es: "Ver todos los itinerarios disponibles en el espacio",
          en: "See all the itineraries available in space",
        },
        {
          name: "create_itinerary",
          es: "Crear itinerario nuevo",
          en: "Create new itinerary",
        },
        {
          name: "template_itinerary",
          es: "Usar itinerarios como plantillas para crear proyectos",
          en: "Use itineraries as templates to create projects",
        },
        {
          name: "create_theme",
          es: "Crear Tema",
          en: "Create Theme",
        },
        {
          name: "create_subtopic",
          es: "Crear Subtema",
          en: "Create Subtopic",
        },
      ],
    };
  },
  created() {
    for (let i = 0; i < this.contentHover; i++) {
      this.optionHover.push(false);
    }
  },
  computed: {},
  methods: {
    getRoleNameFromId(role_id) {
      if (role_id == 0) {
        return this.langFilter("all");
      }
      var index = this.$parent.roles.findIndex((result) => {
        return result.id == role_id;
      });
      return this.$parent.roles[index].name;
    },
    closeDropdown(el) {
      $(
        el.target.id + " .dropdown-menu.multiple-selector-dropdown"
      ).removeClass("show");
    },
    openDropdown(el) {
      if ($(el.target).hasClass("dropdown-button-text")) {
        if ($(el.target).parent().next().hasClass("show")) {
          $(el.target).parent().next().removeClass("show");
        } else {
          $(".dropdown-menu.multiple-selector-dropdown.show").removeClass(
            "show"
          );

          $(el.target).parent().next().addClass("show");
        }
      } else if ($(el.target).hasClass("dropdown-button-text-2")) {
        if ($(el.target).parent().parent().next().hasClass("show")) {
          $(el.target).parent().parent().next().removeClass("show");
        } else {
          $(".dropdown-menu.multiple-selector-dropdown.show").removeClass(
            "show"
          );

          $(el.target).parent().parent().next().addClass("show");
        }
      } else if (el.target.id.includes("dropdown-button-")) {
        if (
          $("#" + el.target.id)
            .next()
            .hasClass("show")
        ) {
          $("#" + el.target.id)
            .next()
            .removeClass("show");
        } else {
          $(".dropdown-menu.multiple-selector-dropdown.show").removeClass(
            "show"
          );

          $("#" + el.target.id)
            .next()
            .addClass("show");
        }
      }
    },
    onChangePermission(value, name, table, contentIndex) {
      if (
        !Array.isArray(this.$parent.permissions[this.content[contentIndex]])
      ) {
        this.$parent.permissions[this.content[contentIndex]] = [];
      }
      var index_exist = this.$parent.permissions[
        this.content[contentIndex]
      ].findIndex((result) => {
        return result == value;
      });
      if (index_exist !== -1) {
        this.$parent.permissions[this.content[contentIndex]].splice(
          index_exist,
          1
        );
      } else {
        this.$parent.permissions[this.content[contentIndex]].push(value);
      }
      if (this.$parent.permissions[this.content[contentIndex]].length == 0) {
        this.$parent.permissions[this.content[contentIndex]].push(value);
      }

      axios.patch("/adminpanel/updatePermission", {
        space: this.$parent.space,
        permission: this.$parent.permissions[this.content[contentIndex]],
        name: name,
        table: table,
      });
    },
    getTableName(id) {
      switch (id) {
        case 0:
          return "laravel_permission_program";
        case 1:
          return "laravel_permission_project";
        case 2:
          return "laravel_permission_itinerary";
        case 3:
          return "laravel_permission_forum";
      }
    },
  },
  mixins: [translationMixin],
};
</script>
