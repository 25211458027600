<template>
  <div>
    <header-general
      src="/img/feedback/contacto.svg"
      :title="langFilter('contacto')"
    ></header-general>
    <div class="vistaFaqs">
      <barraContact :lang="lang"></barraContact>
      <div class="consulta">
        <div class="textoConsulta" v-html="langFilter('text')"></div>
        <ul v-for="answer in answers" :key="answer.id" class="preguntaConsulta">
          <li
            class="tarjetaPregunta"
            :id="'question' + answer.id"
            v-on:click="dropdown(answer.id)"
          >
            {{ answer.question }}
            <div
              :id="'answer' + answer.id"
              class="respuestaNoDrop"
              v-html="answer.answer"
            ></div>
          </li>
          <div :id="'consultaResuelta' + answer.id" class="consultaResuelta">
            {{ langFilter("questionSolved") }}?
            <div id="botonesConsulta">
              <div id="resueltaNo" v-on:click="redirect('no')">No</div>
              <div id="resueltaSi" v-on:click="redirect('yes')">
                {{ langFilter("yes") }}
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import barraContact from "./BarraContactComponent.vue";
import axios from "axios";

export default {
  components: {
    barraContact,
  },
  props: ["lang", "categoryid"],
  data() {
    return {
      answers: {},
      traducciones: [
        {
          name: "text",
          es: "Ayúdanos a entender más, para ayudarte mejor.<br /><br />Aquí podrás encontrar las consultas más frecuentes referente a los Recursos.",
          en: "Help us understand more, to help you better.<br /><br />Here you can find the most frequent queries regarding Resources.",
        },
        {
          name: "contacto",
          es: "Contacto",
          en: "Contact",
        },
        {
          name: "questionSolved",
          es: "¿Hemos resuelto tu consulta",
          en: "Has the question been answered",
        },
        {
          name: "yes",
          es: "Sí",
          en: "Yes",
        },
        {
          name: "no",
          es: "No",
          en: "No",
        },
      ],
    };
  },
  methods: {
    getAnswers() {
      axios
        .get("/listAnswers", {
          params: {
            lang: this.lang,
          },
        })
        .then((response) => {
          this.answers = response.data.answers.filter(
            (answer) => answer.category_id == this.categoryid
          );
        });
    },
    dropdown(id) {
      let answer = document.getElementById("answer" + id);
      let question = document.getElementById("question" + id);
      let consultaResuelta = document.getElementById("consultaResuelta" + id);
      if (answer.classList.contains("respuestaNoDrop")) {
        answer.classList.remove("respuestaNoDrop");
        answer.classList.add("respuestaDrop");
        question.style.border = "2px solid #301889";
        question.style.color = "#2A3B5A";
        consultaResuelta.style.display = "block";
      } else {
        answer.classList.remove("respuestaDrop");
        answer.classList.add("respuestaNoDrop");
        question.removeAttribute("style");
        consultaResuelta.style.display = "none";
      }
    },
    redirect(answer) {
      if (answer == "yes") {
        window.location.href = "../../feedback/support-great/";
      } else if (answer == "no") {
        window.location.href = "../../customer-support/";
      }
    },
  },
  created() {
    this.getAnswers();
  },
  mixins: [translationMixin],
};
</script>
