<template>
  <div>
    <div class="vistaContacto">
      <div class="contenidoFeedback">
        <div class="content">
          <div class="contactImage">
            <img :src="image" />
          </div>
          <h1 class="contactTitle faqs-h-bold" v-if="feedbackView == 3">
            {{ langFilter("title-estupendo") }}
          </h1>
          <h1 class="contactTitle faqs-h-bold" v-if="feedbackView == 5">
            {{ langFilter("title-sent") }}
          </h1>
          <div class="contactText" v-if="feedbackView == 3">
            <p>
              {{ langFilter("have-questions") }}<br />
              {{ langFilter("go-to-the") }}
              <span class="faqs-redirect" @click="redirect"
                >{{ langFilter("help-center") }}.</span
              >
            </p>
          </div>
          <div class="contactText" v-if="feedbackView == 5">
            <p>
              {{ langFilter("thanks-for-getting") }}<br />
              {{ langFilter("you-will-receive") }}
            </p>
            <p>
              {{ langFilter("other-question") }}<br />
              {{ langFilter("go-to-the") }}
              <span class="faqs-redirect" @click="redirect"
                >{{ langFilter("help-center") }}.</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../mixins/translationMixin.js";
import $ from "jquery";

export default {
  props: ["lang", "image", "feedbackView"],
  data() {
    return {
      traducciones: [
        {
          name: "title-sent",
          es: "¡Mensaje enviado!",
          en: "¡Message sent!",
        },
        {
          name: "title-estupendo",
          es: "¡Estupendo!",
          en: "Wonderful!",
        },
        {
          name: "contacto",
          es: "Contacto",
          en: "Contact",
        },
        {
          name: "have-questions",
          es: "Si tienes alguna otra consulta puedes",
          en: "If you have any other questions you can",
        },
        {
          name: "go-to-the",
          es: "acudir al",
          en: "go to the",
        },
        {
          name: "help-center",
          es: "Centro de ayuda",
          en: "Help center",
        },
        {
          name: "thanks-for-getting",
          es: "Gracias por ponerte en contacto.",
          en: "Thank you for getting in touch.",
        },
        {
          name: "you-will-receive",
          es: "Recibirás una respuesta, en 24hs-48hs, por parte de uno de nuestros expertos.",
          en: "You will receive an answer, in 24hs-48hs, by pair of one of our experts.",
        },
        {
          name: "other-question",
          es: "Si tienes alguna otra consulta puedes",
          en: "If you have any other questions you can",
        },
      ],
    };
  },
  created() {
    $(".myProjects-SecondHeaderContainer").hide();
    $(".carga").hide();
    $(".myProjects-MainContainer").show();
  },
  methods: {

    // no sirve para nada, seguramente se podría borrar, pero estoy demasiado ocupado haciendo esta mierda de documentación como para comprobarlo
    btnclick() {
      if (this.header == "true") {
        window.history.go(-1);
      } else {
        window.location.href = this.langFilter(this.link);
      }
    },

    // redirige a la página de preguntas frecuentes
    redirect() {
      this.$parent.view = 0;
      setTimeout(() => {
        this.$parent.view = 1;
      }, 500);
    },
  },

  mixins: [translationMixin],
};
</script>
