<template>
  <div class="modal-input-row">
    <div
      class="modal-input-col"
      style="max-width: 1px"
      v-for="(value, index) in values"
      :key="index"
    >
      <label class="modal-NumberInput">{{ value.number }}</label
      ><label class="modal-TextInput">{{ value.title }}</label>
      <p class="modal-input-info">{{ value.info }}</p>
      <modal-type-input
        style="margin-top: 28px"
        :lang="lang"
        :name="value.name"
        :type="value.type"
        :placeholder="value.placeholder"
        :options="value.options"
      ></modal-type-input>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";

export default {
  props: ["lang", "values"],

  data() {
    return {
      //Traducción
      traducciones: [],
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  mixins: [translationMixin],
};
</script>
